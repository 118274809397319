@import '~common/styles/variables';

.statistics-link-container {
  a:hover {
    text-decoration: none;
  }

  .statistics-item {
    font: normal 14px/1.25 'Lato', sans-serif;
    margin: 0;
    cursor: pointer;
    padding: 14px 20px;
    display: flex;
    transition: .3s background-color;
    align-items: center;
    border-radius: 0;
    justify-content: space-between;

    &:hover {
      &.accent {
        background-color: $color-muted;
      }

      &.primary {
        background-color: rgba($color-primary, .08);
      }
    }

    .title {
      color: $color-font-dark;
      font-weight: bolder;
      text-transform: capitalize;
    }

    .description {
      color: $soft-dark-text;
      font-size: 12px;
    }

    .value {
      font-weight: bolder;
    }

    &.accent {
      .value {
        color: $color-accent;
      }
    }

    &.primary {
      .value {
        color: $color-primary;
      }
    }
  }

}
