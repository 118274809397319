@import '~common/styles/variables';

.balance-board-candidate-poster {
  &.mobile-balance-board-poster {
    .actions {
      display: flex;

      .btn.outlined {
        max-height: 41px;
      }
    }
  }
}

@media screen and (max-width: $media-sm) {
  .balance-board-candidate-poster {
    display: flex;
    padding: 20px;

    .title {
      order: 2;
      text-align: center;
      line-height: 1.33;
      letter-spacing: .2px;
      margin-bottom: 5px;
    }

    .description {
      order: 2;
      line-height: 1.5;
      letter-spacing: .13px;
      font-size: 16px;
      margin-bottom: 30px;
      padding: 10px 10px 0 10px;
    }

    .poster {
      order: 1;
      margin: 25px 0;
    }

    .actions {
      order: 2;

      .btn {
        margin-right: 8px;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: $media-xs) {
  .balance-board-candidate-poster {
    .actions {
      flex-direction: column;

      .btn {
        display: block;
        min-width: auto;
        width: 278px;
        max-width: 100%;
        margin: 0 auto 12px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}
