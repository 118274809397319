@import '~common/styles/variables';

@media screen and (max-width: $media-xl) {
  .mobile-cv-modal {
    width: 70vw;
  }
}

@media screen and (max-width: $media-lg) {
  .mobile-cv-modal {
    width: 95vw;
  }
}

@media screen and (max-width: $media-md) {
  .mobile-cv-modal {
    width: 100vw;

    .content {
      width: 100%;
      min-width: auto;
      max-width: 100%;
    }
  }
}

@media screen and (max-width: $media-sm) {
  .mobile-cv-modal {
    padding: 0;
  }
}

@media screen and (max-width: $media-xs) {
  .mobile-cv-modal {
    .personal-info-content {
      min-height: auto;
      padding: 20px;

      &.mobile-actions {
        margin-bottom: 0;
        box-shadow: none;
        border-top: 1px solid $color-muted;
      }

      .board-form-field {
        .btn {
          width: 100%;

          &:first-of-type {
            margin: 0 0 12px;
          }
        }
      }

      .cv-actions {
        width: 100%;
        display: flex;

        .btn {
          padding: 11px;
          white-space: nowrap;

          &.medium {
            width: 100%;
            padding: 11px 5px;
          }

          &.grey {
            order: 1;
            max-width: 35%;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
