@import '~common/styles/variables';

.modal-application-back {
  font-size: 0;
}

.mobile-application-modal-title {
  display: none;
}

@media screen and (max-width: $media-xl) {
  .create-application-modal.create-application-modal-mobile {
    width: 70vw;
  }
}
@media screen and (max-width: $media-lg) {
  .create-application-modal.create-application-modal-mobile {
    width: 100vw;

    .card {
      width: 100%;
      min-width: auto;
      max-width: 100%;
    }

    .content {
      width: 100%;
      max-width: 100%;
    }
  }
}

@media screen and (max-width: $media-sm) {
  .create-application-modal.create-application-modal-mobile {
    padding: 0;

    .card {
      min-height: calc(var(--vh, 1vh) * 100);
      padding: 30px 20px;
    }

    .application-back-link {
      padding: 0;
      width: 15px;
      height: 15px;
      font-size: 0;
      top: 8px;
      right: 16px;
      position: absolute;

      .modal-application-back {
        font-size: 24px;
      }
    }

    .mobile-application-modal-title {
      display: block;
      font-size: 24px;
      letter-spacing: .2px;
      color: $color-text-dark;
    }

    .create-application-form {
      padding: 30px 0;
    }

    .mobile-form-submit {
      bottom: 0;
      width: 100vw;
      height: 64px;
      display: flex;
      padding: 0 20px;
      position: fixed;
      margin-left: -20px;
      align-items: center;
      background-color: #fff;
      box-shadow: 0 -1px 0 0 $color-muted;
    }
  }
}

@media screen and (max-width: $media-xs) {
  .create-application-modal.create-application-modal-mobile {
    .mobile-application-form-cancel {
      display: none;
    }

    .mobile-form-submit {
      justify-content: center;
    }

    .card {
      display: flex;
      padding-bottom: 0;
      flex-direction: column;
    }

    .create-application-form {
      display: flex;
      padding-bottom: 0;
      flex-direction: column;

      .form-inputs {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
      }
    }
  }
}
