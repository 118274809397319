@import '~common/styles/variables';

@media screen and (max-width: $media-xl) {
  .modal.delete-account-modal-container {
    .modal-content {
      left: 0;
      transform: translate(-50%, -50%);
    }
  }
}

@media screen and (max-width: $media-sm) {
  .modal.delete-account-modal-container {
    z-index: 10001;
  }
}

@media screen and (max-width: $media-xs) {
  .modal.delete-account-modal-container {
    .modal-content {
      width: 290px;
      min-width: 1px;
    }

    .delete-account-modal-form {
      .delete-actions {
        .primary {
          margin-bottom: 25px;
        }
        
        .btn {
          width: 100%;
          max-width: 280px;
          margin-right: 0;
        }
      }
    }
  }
}
