@import '~common/styles/variables';

.delete-user-description {
  font-size: 20px;
  margin: 3px 0 29px;
  letter-spacing: .16px;
  line-height: 28px;
  color: $color-font-dark;
}

.confirm-delete {
  margin: 0 0 64px;
  letter-spacing: .13px;
  line-height: 24px;
}
