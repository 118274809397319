$color-muted: #e5eefc;
$color-accent: #3b83ff;
$light-color-accent: #7daeff;
$color-primary: #70b53a;
$color-font-main: #7a8cab;
$color-font-dark: #505f79;
$border-light-color: #b2cbf4;
$color-danger: #fd3957;
$bg-color-default: #f4f5f7;
$close-btn-hover-bg: #f8f8f8;
$color-badge: #fd3a57;
$color-text-dark: #3b475a;
$border-color: #e9e9e9;
$soft-dark-text: #bdc9df;

$box-shadow-color: rgba(122, 140, 171, .2);

$box-shadow-mobile: 0 2px 3px 0 rgba(66, 101, 159, .2);

$media-xl: 1400px;
$media-lg: 1200px;
$media-md: 992px;
$media-sm: 767px;
$media-xs: 480px;
