@import '~common/styles/variables';

.navigation-title {
  margin: 0;
  padding: 20px;
  font-size: 1rem;
  line-height: 20px;
  letter-spacing: 0.33px;
  border-bottom: 1px solid $border-light-color;
}
