.empty-placeholder {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  opacity: .7;
  user-select: none;
}

.candidates-list {
  max-height: calc(100% - 10px);
  overflow: auto;
}
