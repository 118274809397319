@import '~common/styles/variables';

$light-gray: #e9e9e9;

.counter-widget {
  height: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;

  .counter-body {
    display: flex;
    justify-content: center;

    .circle-container {
      width: 57%;
      padding-top: 57%;
      position: relative;

      .circle-outer {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 50%;
        border: solid 12px $color-muted;
        padding: 5px;

        .circle-inner {
          border: solid 1px $light-gray;
          border-radius: 50%;
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 20px;

          .counter-value {
            font-size: 2.25rem;
            font-weight: bold;
            color: $color-font-main;
          }

          .counter-units {
            font-size: 1.125rem;
          }
        }
      }
    }
  }

  .counter-name {
    margin-top: 1rem;
    font-weight: bold;
    color: $color-font-dark;
    letter-spacing: 1px;
  }
}
