.vdk-recommendation-container {
  margin-bottom: 20px;
  display: flex;
  padding: 25px;
  justify-content: space-between;

  .vdk-recommendation-info {
    width: calc(100% - 250px);
    padding-right: 20px;

    .title {
      font-size: 20px;
      margin-bottom: 20px;
    }

    .text {
      font-size: 14px;
      text-align: justify;
    }

    .author {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px;
      display: block;
    }
  }

  .vdk-recommendation-photo {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      object-fit: cover;
    }
  }
}