@import '~common/styles/variables';

.failed-payment-container {
  max-width: 700px;
  padding: 16px;
  margin: 0 auto;
  text-align: center;
}

.failed-payment-content {
  padding: 50px;

  & > h2 {
    margin: 0 0 34px;
  }

  & .warning-text {
    color: $color-badge;
    text-transform: uppercase;
  }

  & .payment-description {
    letter-spacing: .4px;
    line-height: 24px;
    max-width: 430px;
    margin: 0 auto;
    padding: 20px 0 38px;
  }

  & .agreement {
    font-size: 14px;
    letter-spacing: .35px;
    line-height: 20px;

    & > a {
      color: $color-accent;
    }
  }
}

.failed-payment-action {
  max-width: 420px;
  margin: 0 auto;

  .payment-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  .btn {
    min-width: 200px;
    white-space: nowrap;
  }
}

.failed-payment-footer > .container {
  min-width: auto;
  padding: 0;

  & > .footer-content {
    padding: 0;
  }
}
