@import '~common/styles/variables';

$table-border: #c1c7cd;

.privacy-policy-container ~ footer {
  .container {
    border-top: 1px solid $soft-dark-text;
  }
}

.privacy-container {
  padding: 100px 0;
  max-width: 680px;
  margin: 0 auto;

  .privacy-title,
  .privacy-caption,
  .privacy-subtitle {
    color: $color-text-dark;
    margin-bottom: 15px;
    font: normal 42px/1.24 'Lato', sans-serif;
  }

  .privacy-subtitle {
    font-size: 35px;
  }

  .privacy-caption {
    font-size: 18px;
    margin: 22px 0;
  }

  .privacy-description {
    color: #505f79;
    letter-spacing: .4px;
    font: normal 18px/1.33 'Lato', sans-serif;

    .privacy-section-title {
      color: $color-text-dark;
      font-weight: 500;
      font-size: 2.3rem;
      margin-bottom: 1.7rem;
    }

    ul {
      margin-left: -20px;

      li {
        line-height: 30px;
      }
    }

    h3 {
      font-weight: bold;
    }

    p {
      line-height: 28px;
      margin-bottom: 33px;

      a {
        color: $color-accent;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .link {
      color: $color-accent;
    }

    .table-cell-with-link {
      display: block;
    }

    .list-value {
      margin: 5px 0;
    }

    .text-bold {
      font-weight: bold;
    }
  }
}
