@import '~common/styles/variables';

@media screen and (max-width: $media-xs) {
  .mobile-table {
    thead {
      display: none;
    }

    td,
    tbody {
      display: block;
    }

    tr {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: flex-start;

      &:not(:last-of-type) {
        border-bottom: 0;
      }
    }

    td {
      border-top: 0;
      padding-bottom: 4px;

      &.skill-name {
        width: 100%;
        line-height: 2;
        letter-spacing: .33px;
        font-size: 16px;
        color: $color-font-dark;

        .title {
          width: calc(100% + 40px);
          left: -20px;
          position: relative;
          padding: 0 20px 8px;
          box-shadow: inset 0 -1px 0 0 $color-muted;
          font-size: 12px;
          letter-spacing: .67px;
        }
      }

      &.skill-progress {
        .progress-container {
          margin-left: 0;
        }
      }

      &.skill-level {
        padding: 11px 0 0;
      }

      &.skill-progress,
      &.skill-level {
        display: block;
      }
    }

    &.mobile-table-list {
      td {
        &.skill-progress {
          order: 0;
          margin-left: 0;

          .progress-container {
            margin-left: 0;
          }
        }

        &.skill-level {
          order: 1;
        }

        &.mobile-skill-required {
          order: 2;
          padding-top: 6px;
        }
      }
    }
  }
}
