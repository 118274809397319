@import '~common/styles/variables';

.auth-form-footer {
  padding: 18px 80px;
  text-align: center;
  border-top: 1px solid $color-muted;

  > * {
    margin: 6px;
  }

  .sign-up-link {
    color: $color-primary;

    &.accent {
      color: $color-accent;
    }
  }
}
