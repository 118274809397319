@import '~common/styles/variables';

.socialmedia-container {
    margin: 20px 0;
    // padding: 35px;
    display: flex;
    flex-direction: initial;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    height: 100%;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 3px 0 rgba(66, 101, 159, 0.2);

    .socialmedia-items {
        width: 33.3%;
        float: left;
        text-align: center;
        color: #fff;
        padding: 1rem;
    }

    .socialmedia-item-linkedin {
        background: #0074b1;
    }

    .socialmedia-item-instagram {
        background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
    }

    .socialmedia-item-facebook {
        background: #4267b2;
    }
}