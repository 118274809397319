@import '~common/styles/variables';

.input-control-search {
  position: relative;

  .form-control {
    &:not([type='checkbox']) {
      &:not([type='radio']) {
        padding-left: 45px;
      }
    }
  }

  .icon-search {
    left: 10px;
    font-size: 24px;
    position: absolute;
    color: $color-muted;
  }
}

.form-label {
  .form-control.mobile-sign-up {
    &:not([type='checkbox']) {
      &:not([type='radio']) {
        padding-right: 43px;
      }
    }
  }

  &.icon {
    &::after {
      right: 10px;
    }
  }

  &:not(.error) .eye-icon > img {
    bottom: 13px;
  }

  &.error .eye-icon > img {
    bottom: 21px;
  }

  &.mobile-password {
    .form-control {
      &:not([type='checkbox']) {
        &:not([type='radio']) {
          padding-right: 75px;
        }
      }
    }

    &.icon {
      &::after {
        right: 43px;
        bottom: 7px;
        top: auto;
      }
    }
  }

  .eye-icon > img {
    top: auto;
  }

  &:not(.mobile-salary-range) .label-error {
    margin-top: 8px;
  }

  &.mobile-salary-range .label-error {
    span {
      bottom: -22px;
    }

    .label-error {
      margin-top: 8px;
    }
  }
}

@media screen and (max-width: $media-md) {
  .mobile-message-input {
    .form-control {
      &:not([type='checkbox']) {
        &:not([type='radio']) {
          height: 40px;
          line-height: 1.25;
          letter-spacing: .33px;
        }
      }
    }
  }
}

@media screen and (max-width: $media-sm) {
  .form-label {
    &.error.mobile-salary-range {
      margin-bottom: 30px;
    }
  }
}

@media screen and (max-width: $media-xs) {
  .form-label {
    &.error.mobile-salary-range {
      margin-bottom: 20px;

      .label-error {
        margin-top: 15px;
      }
    }
  }

  .mobile-nowrap-label {
    white-space: nowrap;
    max-width: calc(50vw - 20px);
  }
}
