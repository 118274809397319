@import '~common/styles/variables';

@media screen and (max-width: $media-md) {
  .infobox-container {
    &::after {
      right: 0;
    }

    &::before {
      left: 0;
    }
  }
}

@media screen and (max-width: $media-sm) {
  .infobox-container {
    overflow: hidden;
    height: auto;
    padding: 60px 30px 55px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 35px;

    &::after {
      right: -9px;
      bottom: -12px;
    }

    &::before {
      left: -54px;
    }

    .title {
      line-height: 1.14;
      letter-spacing: .6px;
    }

    .title-arrow {
      display: none;
    }
  }
}

@media screen and (max-width: $media-xs) {
  .infobox-container {
    margin: 30px -15px 35px;
    border-radius: 0;
  }
}
