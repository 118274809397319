@import '~common/styles/variables';

$search-modal-breakpoint-big: 1660px;
$search-modal-breakpoint-medium: 1160px;

.match-candidates-modal {
  display: flex;
  padding: 1rem 40px;
  height: calc(var(--vh, 1vh) * 100);
  background: $bg-color-default;
  width: 60vw;
  box-shadow: 0 5px 21px 0 rgba(66, 101, 159, .2);
  overflow: hidden;
  cursor: auto;
  flex-direction: column;

  & > .card {
    position: relative;

    &.job-offer-header {
      height: 150px;
      margin-bottom: 10px;
      max-width: 900px;
    }

    &.candidates-list {
      width: 100%;
      height: calc(var(--vh, 1vh) * 100 - 195px);
      max-width: 900px;
    }
  }
}

@media all and (max-width: $search-modal-breakpoint-big) {
  .match-candidates-modal {
    width: 70vw;
  }
}

@media all and (max-width: $search-modal-breakpoint-medium) {
  .match-candidates-modal {
    width: 95vw;
  }
}
