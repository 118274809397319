@import '~common/styles/variables';

.close-profile-modal {
  display: none;
}

@media screen and (max-width: 1700px) {
  .setting-modal-container {
    width: 980px
  }
}

@media screen and (max-width: $media-md) {
  .setting-modal-container {
    display: block;
    width: 100vw;
    background: #fff;
    padding: 0 20px 20px;
    overflow-y: scroll;

    .card {
      box-shadow: none;
      height: auto;
    }
  }

  .close-profile-modal {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    margin: 0;
    height: 14px;
    width: 14px;
    padding: 0;
    color: $color-font-main;
    font-size: 24px;
    line-height: 14px;
  }

  .candidate-profile-modal {
    transform: translateY(-100vh);

    .open.slide {
      transform: translateY(0);
    }

    & .profile-sidebar {
      & .profile-menu-list > li {
        color: $color-font-dark;

        &:hover,
        &.active {
          background: none;
        }
      }
    }
  }
}
