@import '~common/styles/variables';

@media screen and (max-width: $media-xs) {
  .footer-content {
    flex-direction: column-reverse;
    flex-wrap: wrap;
    align-content: flex-start;
    height: auto;
    padding: 25px 20px 20px;

    .copy {
      flex-direction: column-reverse;
      width: 100%;
      font-size: 12px;
      margin: 15px 0 0;
    }
  }

  .agreements {
    width: 100%;

    li {
      width: 100%;
      margin: 0 0 10px 0;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
