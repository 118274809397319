@import '~common/styles/variables';

.match-modal-container {
  .modal-content {
    min-width: 620px;
  }

  .match-modal-form {
    padding: 40px;

    .matching-rate-title {
      font: bold 28px/1 'Lato', sans-serif;
      color: #505f79;
      padding: 0 0 1rem 0;
    }

    .matching-rate-description {
      font: normal 16px/1.6 'Lato', sans-serif;
    }

    .matching-rate-total {
      margin: 50px 0 0;
      font-size: 16px;
    }
  }

  .overflow-modal-content {
    height: 345px;

    .ps__rail-y {
      right: 10px !important;
    }

    .ps__thumb-y {
      background-color: $border-light-color;
    }
  }
}

.match-modal-form-action {
  display: flex;
  padding: 14px 20px;
  border-top: 1px solid $color-muted;
}
