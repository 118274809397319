@import '~common/styles/variables';

.personal-info-form {
  .phone-number-field .form-label {
    width: 250px;
  }

  .form-btn-submit {
    justify-content: flex-start;
  }

  .agreement {
    font-size: 14px;
    max-width: 300px;

    & > a {
      color: $color-accent;
    }
  }
}

