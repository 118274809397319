.nav-widgets {
  display: flex;
  flex-wrap: nowrap;
  margin-right: -8px;
  margin-left: -8px;
  height: 300px;

  .profile-widget {
    margin-left: 8px;
    margin-right: 8px;
    flex: 0 0 428px;
  }

  .navigation-widget {
    margin-left: 8px;
    margin-right: 8px;
    flex: 0 1 30%;
    max-width: 30%;
  }

  .chart-widget {
    margin-left: 8px;
    margin-right: 8px;
    flex: 0 2 30%;
  }
}

@media (max-width: 1024px) {
  .nav-widgets {
    .chart-widget {
      display: none;
    }

    .navigation-widget {
      flex: 1 1 auto;
      max-width: unset;
    }
  }
}
