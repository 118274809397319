@import '~common/styles/variables';

.team-members-caption {
  padding: 24px 0 32px;
  margin: 0;
}

.team-members-form {
  .form-label-title {
    margin: 0;
  }

  &.icon {
    padding: 0;
    box-shadow: none;
    cursor: pointer;
    margin: 0 8px;

    & > img {
      border-radius: 50%;
      padding: 7px;
      transition: background-color .2s;

      &:hover {
        background: $close-btn-hover-bg;
      }
    }
  }

  &.medium {
    width: 140px;
  }

  &-item {
    margin-bottom: 32px;

    &:hover > button {
      display: block;
    }

    & > label {
      width: 400px;
    }

    & > .btn {
      display: none;

      &:hover {
        background: transparent;
      }
    }
  }
}

.form-btn-submit {
  padding-top: 64px;
}

.team-members-form-item > .form-label {
  & > .form-label-title {
    margin: 0;
  }
}
