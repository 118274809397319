@import '~common/styles/variables';

$switch-active-item-color: #fff;

.switch-label {
  .form-label-title {
    line-height: 1.5;
    vertical-align: middle;
    margin-right: 8px;
    margin-bottom: 10px;
    display: block;
  }

  .notes {
    line-height: 1.5;
    color: $switch-active-item-color;
  }
}

.switch-control {
  font: bold 16px/1 'Lato', sans-serif;
  display: inline-block;

  .switch-control-item {
    cursor: pointer;
    margin-right: 12px;
    border-radius: 4px;
    border: 1px solid $color-font-main;
    padding: 12px 32px;
    display: inline-block;
    transition: background-color .15s;

    &:last-child {
      margin-right: 0;
    }

    &.active,
    &:hover {
      background-color: $color-font-main;
      color: $switch-active-item-color;
    }
  }
}
