@import '~common/styles/variables';

.portlet-head {
  border-bottom: 1px solid $border-light-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 10px 28px;
}

.portlet-title {
  flex: 1 0 auto;

  h2 {
    text-transform: uppercase;
    letter-spacing: .8px;
    font-size: 1.125rem;
    font-weight: normal;
    color: $color-primary;
  }
}
