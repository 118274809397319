@import '~common/styles/variables';

.payment-details-container {
  position: relative;

  .payment-options p {
    font-size: 14px;
    letter-spacing: 0.3px;
    color: #7a8cab;
  }

  & > .payment-details-container {
    border-bottom: 1px solid $color-muted;
  }

  .py-5 {
    padding: 25px 0;
  }

  .py-2 {
    padding: 10px 0;
  }

  & .payment-details-subtitle {
    margin: 0;
    font-size: 14px;
    line-height: 1.71;
  }

  & .payment-details-title {
    font-size: 1rem;
    line-height: 1.71;
    margin: 0 0 24px;
  }

  & .payment-details-title:last-child {
    margin: 0;
  }

  & .change-payment-actions {
    width: calc(50% - 10px);
    margin-top: 20px;
    display: flex;
    flex-wrap: nowrap;

    .btn.confirm {
      flex: 0 0 150px;
    }

    & > a {
      text-decoration: none;
    }

    & > .agreement {
      font-size: 14px;
      letter-spacing: 0.35px;
      line-height: 20px;
    }
  }

  & .orders-list {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    font-weight: bold;
    color: $color-font-dark;

    & p {
      margin: 10px 0;
    }

    & .orders-list-item {
      display: flex;
      justify-content: space-between;
    }

    & .order-info {
      display: flex;
      justify-content: space-between;
      min-width: 160px;
    }

    & .order-info-text {
      margin: 0 6px;
    }

    & .pdf-link,
    & .currency {
      text-align: right;

      & > a {
        text-decoration: underline;
        font-weight: normal;
        color: $color-accent;
        margin-right: 1rem;

        &:hover {
          cursor: pointer;
          opacity: .7;
        }
      }
    }
  }

  & .premium-plan {
    padding: 14px;
    margin-top: 34px;
    border-radius: 4px;
    border: 1px solid $color-accent;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .info-icon {
      margin-right: 50px;

      & > img:hover {
        cursor: pointer;
      }
    }

    & > .radio,
    & > .price {
      margin: 0;
      padding: 0;
      color: $color-font-dark;
    }

    & > .price .euro-sign {
      left: -12px;
    }
  }

  .subscription-plan {
    border: none;
    max-width: 100%;
    padding-right: 16px;
  }

  .empty-list {
    text-align: center;
  }

  .error-vat-id {
    margin-top: 5px;
    font-size: .875rem;
    color: $color-danger;
  }
}
