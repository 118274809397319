@import '~common/styles/variables';

@media screen and (max-width: $media-md) {
  .nav-widgets {
    height: auto;

    .profile-widget {
      flex: none;
      margin: 0;
      
      .card {
        box-shadow: none;
        min-height: 0;
      }
    }

    .navigation-widget {
      display: none;

      .card {
        box-shadow: none;
        min-height: 0;
      }
    }
  }

  .mobile-guest-navigation-widgets {
    display: none;
  }
}
