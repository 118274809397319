@import '~common/styles/variables';

$radio-checked-accent-color: $color-accent;
$radio-checked-primary-color: $color-primary;
$radio-border-color: #b2cbf4;
$radio-size: 20px;
$radio-checked-size: 12px;
$radio-ripple-size: 15px;
$radio-ripple-bg: rgba(0, 0, 0, 0);

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 1px $radio-ripple-bg;
  }

  50% {
    box-shadow: 0 0 0 $radio-ripple-size lighten($radio-ripple-bg, 10%);
  }

  100% {
    box-shadow: 0 0 0 $radio-ripple-size $radio-ripple-bg;
  }
}

.radio {
  margin: 16px 0 0;

  & input:checked ~ span {
    color: $color-font-dark;
  }

  & > span {
    display: inline-block;
    height: $radio-size;
    position: relative;
    padding-left: ($radio-size + 10px);
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;

    &::before,
    &::after {
      position: absolute;
      content: '';
      border-radius: 50%;
      transition: transform .3s ease;
    }

    &::before {
      left: 0;
      top: 0;
      width: $radio-size;
      height: $radio-size;
      border: 1px solid $radio-border-color;
    }

    &::after {
      top: ($radio-size / 2 - $radio-checked-size / 2) + 1;
      left: ($radio-size / 2 - $radio-checked-size / 2) + 1;
      width: $radio-checked-size;
      height: $radio-checked-size;
      transform: scale(0);
    }
  }

  &.accent > span {
    &::after {
      background: $radio-checked-accent-color;
    }
  }

  &.primary > span {
    &::after {
      background: $radio-checked-primary-color;
    }
  }

  input {
    &[type='radio'] {
      display: none;

      &:checked + span &::before {
        border-color: $radio-checked-accent-color;
        animation: ripple .2s linear forwards;
      }

      &:checked + span {
        &::after {
          transform: scale(1);
        }
      }

      &.accent:checked + span &::before {
        background: $radio-checked-accent-color;
      }

      &.primary:checked + span &::before {
        background: $radio-checked-primary-color;
      }
    }
  }
}
