@import '~common/styles/variables';

.comment-container {
  margin: 80px 0;
  height: 228px;
  display: flex;
  position: relative;
  background: $color-muted;
  align-items: center;

  .comment-content {
    font: normal 18px/1.33 'Lato', sans-serif;
    color: $color-font-dark;
    display: flex;
    align-items: center;
    letter-spacing: .3px;
    justify-content: center;

    .comment-left-container {
      width: 120px;
    }

    .comment-right-container {
      width: 40%;
    }

    .signature {
      margin-top: 20px;
    }
  }
}
