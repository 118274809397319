@import '~common/styles/variables';

@media screen and (max-width: $media-xs) {
  .confirmation-code-container {
    .react-code-input {
      margin: 0 -4px;
    }

    .confirmation-code-form {
      padding: 28px 20px 0;
      text-align: left;

      img {
        position: relative;
        left: -10px;
      }
    }

    & .confirmation-title {
      font-size: 18px;
      text-transform: uppercase;
      line-height: 1.33;
      letter-spacing: .7px;
      font-weight: normal;
    }

    & .confirmation-caption {
      font-size: 16px;
      letter-spacing: .13px;
      line-height: 1.5;
      margin-bottom: 20px;
    }

    .auth-form-footer {
      padding: 2px 0 55px;
      text-align: left;

      span {
        display: inline-block;
        width: 100%;
        margin-bottom: 15px;
      }
    }

    .email-to-confirm {
      width: 100%;
      display: inline-block;
    }
  }
}
