@import '~common/styles/variables';

.tabs-container {
  height: 100%;
}

.tabs-head {
  .tabs-list {
    margin: 0;
    height: 60px;
    display: flex;
    padding: 0 16px;
    list-style: none;
    border-bottom: 1px solid $border-light-color;

    li, a {
      padding: 0 4px;
      margin: 0 8px;
      height: 60px;
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;

      &:hover {
        span {
          color: $color-font-dark;
        }
      }

      &.active {
        &:not(:only-child) {
          span {
            color: $color-font-dark;
          }

          &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            height: 4px;
            border-radius: 4px 4px 0 0;
            left: 0;
            right: 0;
          }
        }
      }

      &.disabled {
        pointer-events: none;
        color: #c4cede;
      }

      &:first-child {
        margin-left: 0;
      }
    }

    & a {
      text-decoration: none;
    }

    &.primary {
      li.active,
      a.active {
        color: $color-primary;
        &:not(:only-child) {
          &::after {
            background: $color-primary;
          }
        }
      }
    }

    &.accent {
      li.active,
      a.active {
        color: $color-accent;
        &:not(:only-child) {
          &::after {
            background: $color-accent;
          }
        }
      }
    }
  }
}

.tabs-body {
  white-space: pre-line;
  position: relative;
  padding: 12px 0;
  height: calc(100% - 60px);
}
