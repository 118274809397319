@import '~common/styles/variables';

.priority-match-form .row {
  &.required {
    margin-bottom: 5px;
    border-radius: 4px;
    background: $bg-color-default;

    &.row {
      padding: 15px 0;
    }

    & > .title {
      color: #50617a;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    & > .level {
      top: 10px;
      position: relative;
    }
  }
}
