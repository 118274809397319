@import '~common/styles/variables';

.personal-section-container {
  .personal-section-title {
    text-transform: uppercase;
  }

  .personal-post-list {
    padding: 0;
    margin: 0;
  }

  .personal-post-item {
    margin-bottom: 2rem;

    .text-content {
      .gallery img {
        width: 100%;
        max-width: 100%;
        object-fit: cover;
        border-radius: 5px;
        opacity: .4;

        &.primary {
          opacity: 1;
        }
      }

      .gallery {
        display: flex;
        justify-content: space-between;

        .main-picture {
          width: 100%;

          & > img {
            height: 100%;
          }
        }

        .aside-photos {
          width: 40%;
          display: flex;
          margin-left: 1rem;
          align-items: center;
          flex-direction: column;

          & > img:not(.primary):hover {
            cursor: pointer;
          }

          & > img:not(:last-child) {
            margin-bottom: 5px;
          }
        }
      }

      .post-title {
        font-size: 20px;
        margin-bottom: 0;
      }

      .post-body {
        font-size: 16px;
        color: $color-font-dark;
        letter-spacing: 0.67px;
        margin-bottom: 24px;
      }
    }
  }
}
