@import '~common/styles/variables';

.modal.guest-offer-preview-modal {
  .modal-content {
    min-width: 900px;
    transform: translate(-83%, -40%);
  }

  .modal-columns-container {
    width: 100%;
    height: 100%;
    max-width: 930px;
    border-radius: 4px;
    background-color: #fff;
    padding: 12px 2rem;
    box-shadow: 0 2px 3px 0 $box-shadow-color;

    .back-btn-container > a {
      padding: 0;
    }

    .content {
      max-height: calc(var(--vh, 1vh) * 80);
    }

    .modal-columns {
      display: flex;
      height: calc(100% - 43px);

      .general-info {
        padding: 1rem 0;
        flex: 1;
      }

      .aside-info {
        padding: 16px;
        width: 312px;
      }
    }

    .restricted {
      filter: blur(7px);
    }
  }

  .job-offer-modal {
    width: 100%;

    .job-title {
      margin: 0;
      font-size: 1.5rem;
      font-weight: bold;
      color: $color-text-dark;
    }

    .additional-info {
      padding: 14px 0;
      display: flex;
      align-items: center;

      .branches {
        .chip {
          margin-bottom: 0;
        }
      }

      & > .chip {
        margin: 0;
        color: $color-accent;
        background: rgba($color-accent, .2);
      }
    }

    .branches {
      & span {
        font-size: 14px;
        line-height: 20px;
        margin-right: 12px;
        text-transform: uppercase;
        letter-spacing: 0.78px;
        color: $color-font-main;
      }
    }

    .job-summary-info {
      margin: 0;
      padding: 10px 0;
      list-style: none;

      li {
        padding: 4px 0;
        line-height: 1.5;

        b {
          margin-right: 10px;
          color: $color-text-dark;
        }
      }
    }

    .full-info-container {
      .tabs-list {
        padding-left: 0;
        padding-right: 0;
      }

      .competencies-wrapper {
        padding: 0;
      }
    }

    .actions-buttons {
      display: flex;
      align-items: center;
      margin-bottom: 25px;
    }

    .job-address {
      margin: 0 0 25px 0;
      padding-left: 26px;
      position: relative;

      &:before {
        content: url('/images/shared/location.svg');
        position: absolute;
        left: 4px
      }

      .name {
        margin: 0;
        color: $color-font-dark;
      }

      .address {
        font-size: 1rem;
        font-style: normal;
        letter-spacing: 0.7px;
      }
    }

    .logo-container {
      margin: 32px 0 28px;

      .hotel-logo {
        overflow: hidden;
        text-align: center;

        p {
          max-width: 80%;
          margin: 0 auto;
        }

        .logo-wrapper {
          overflow: hidden;
          border-radius: 4px;
          display: inline-block;

          img {
            width: 100%;
            height: 210px;
            object-fit: cover;
          }
        }
      }
    }

    .job-employer-description {
      margin: 16px 0;

      .title {
        margin: 5px 0;
        font-weight: bold;
      }

      .description {
        font-size: .875rem;
        line-height: 1.71;
      }
    }
  }
}
