@import '~common/styles/variables';

@media screen and (max-width: $media-sm) {
  .reset-password-page {
    .btn[type='submit'] {
      margin-top: 5px;
    }
  }

  .reset-form-description {
    margin: 20px 0 30px 0;
  }
}
