@import '~common/styles/variables';

.modal.apply-modal {
  .modal-content {
    min-width: 600px;
  }

  .apply-content {
    padding: 20px;

    .apply-description {
      margin-top: 0;
      font-size: 14px;
      max-width: 420px;
      color: $color-font-main;
    }
  }

  .applications-action {
    display: flex;
    margin-top: 40px;
    align-items: center;
  }
}
