@import '~common/styles/variables';

$chip-employed-background: #d7f0ae;

.profile-header-container {
  font: normal 16px/1.25 'Lato', sans-serif;
  padding: 25px 35px;
  display: flex;
  height: 100%;

  .navigation > a {
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: 0.9px;
  }

  .information,
  .actions {
    width: 50%;
    display: flex;
    flex-direction: column;
  }

  .information {
    justify-content: space-between;

    .chip {
      text-align: center;
      text-transform: capitalize;
      width: auto !important;
      padding: 5px 20px;

      &.invited {
        color: $color-accent;
        background-color: $color-muted;
      }

      &.accepted,
      &.employed {
        color: $color-primary;
        background-color: rgba($chip-employed-background, .4);
      }

      &.interviewed {
        color: $color-font-main;
        background-color: $bg-color-default;
      }
    }
  }

  .actions {
    justify-content: space-between;
    align-items: flex-end;

    .action {
      width: 275px;
    }
  }
}
