@import '~common/styles/variables';

.close-notifications {
  display: none;
}

@media screen and (max-width: $media-lg) {
  .notifications-modal {
    width: 65vw;
  }
}

@media screen and (max-width: $media-md) {
  .notifications-modal {
    width: 100vw;
  }

  .notifications {
    & > .page {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: $media-sm) {
  .notifications {
    min-width: 1px;
    padding: 0;

    & > .page {
      padding: 0;
      max-width: 100%;
    }
  }

  .notifications-content {
    padding: 35px 20px 80px;

    .close-notifications {
      font-size: 26px;
      display: block;
    }

    & > .caption {
      line-height: 1.17;
      letter-spacing: .2px;
      font-weight: normal;
    }

    & > .label {
      display: none;
    }

    & > .modal-btn {
      margin: 0;
      width: 14px;
      height: 14px;
      line-height: 1px;
      position: absolute;
      top: 18px;
      right: 20px;
      font-size: 0;
    }
  }
}
