@import '~common/styles/variables';

.multi-select-container + input {
  height: 0;
  opacity: 0;
}

@media screen and (max-width: $media-xs) {
  .multi-select-container {
    max-width: calc(100vw - 40px);

    .select__control {
      .select__multi-value {
        max-width: 95%;
      }
    }
  }
}
