@import '~common/styles/variables';

$card-background: #fff;
$card-box-shadow-color: rgba(66, 101, 159, .2);

.mobile-sidebar-container-title,
.mobile-sidebar-container-close {
  display: none;
}

.mobile-sidebar-caption-text {
  margin: 22px auto;
  text-align: center;
}

@media screen and (max-width: $media-md) {
  .profile-modal-content {
    position: fixed;
    width: 100vw;
    max-width: 100%;
    min-width: 100%;
    padding: 35px 20px 20px;
    top: 0;
    right: -100%;
    bottom: 0;
    background: #fff;
    transition: all .5s;
    overflow-y: scroll;

    &.active {
      right: 0;
      z-index: 1;
    }
  }

  .mobile-sidebar-container {
    padding: 20px;
    margin-right: 0;
    border-radius: 4px;
    margin-bottom: 16px;
    background-color: $card-background;
    box-shadow: 0 2px 3px 0 $card-box-shadow-color;

    .mobile-sidebar-head {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      justify-content: space-between;
    }

    .sidebar-caption {
      line-height: 19px;
      letter-spacing: .89px;
    }

    .mobile-sidebar-caption-text {
      display: none;
    }

    .mobile-sidebar-container-close {
      display: block;
      font-size: 24px;
      line-height: 14px;
      color: $color-font-main;
    }

    .mobile-sidebar-container-title {
      display: block;
      font-size: 24px;
      line-height: 1.17;
      letter-spacing: .2px;
      color: $color-text-dark;
    }

    .sidebar-menu-list {
      display: flex;
    }
  }
}

@media screen and (max-width: $media-sm) {
  .mobile-sidebar-container {
    box-shadow: none;
    margin-bottom: 0;
    padding: 20px 30px 0;
  }
}

@media screen and (max-width: $media-sm) {
  .mobile-sidebar-container {
    padding: 20px 21px 0;

    .mobile-sidebar-head {
      align-items: flex-start;
    }

    .mobile-sidebar-container-title {
      margin-top: 12px;
    }
  }
}
