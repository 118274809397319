@import '~common/styles/variables';

.infobox-container {
  margin: 20px 0;
  padding: 35px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;

  .title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    :hover {
      cursor: pointer;
    }

    .title {
      font: normal 28px/1.14 'Lato', sans-serif;
      color: $color-text-dark;
      letter-spacing: 0.6px;
    }
  }

  .content {
    margin: 15px 0 0 0;
  }

  .text {
    font: normal 14px/1.5 'Lato', sans-serif;
    opacity: .8;
    letter-spacing: 0.3px;
    margin: 0 0 15px 0;
  }

  .subtitle {
    font: normal 20px/1.14 'Lato', sans-serif;
    color: $color-text-dark;
    letter-spacing: 0.6px;
    margin: 0 0 15px 0;
  }

  .icon-closed {
    display: inline-block;
    width: 12px;
    height: 20px;
    background: url('/images/landing/icon-right-chevron-big.svg') no-repeat center center;
    transform: rotate(-180deg);
    transition: .3s opacity;
  }

  .icon-opened {
    display: inline-block;
    width: 12px;
    height: 20px;
    background: url('/images/landing/icon-right-chevron-big.svg') no-repeat center center;
    transform: rotate(90deg);
    transition: .3s opacity;
  }
}
