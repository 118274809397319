@import '~common/styles/variables';

@media screen and (max-width: $media-sm) {
  .mobile-table-interact {
    padding: 14px 12px 10px;
    border-collapse: initial;

    thead {
      display: none;
    }

    td,
    tbody {
      display: block;
    }

    tr {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;

      &:not(:last-of-type) {
        border-bottom: 0;
      }
    }

    td {
      border-top: 0;

      &.skill-name {
        padding: 0;
        width: 100%;
        line-height: 2;
        letter-spacing: .33px;
        font-size: 16px;
        color: $color-font-dark;

        .title {
          width: calc(100% + 40px);
          left: -20px;
          position: relative;
          padding: 0 20px 8px;
          box-shadow: inset 0 -1px 0 0 $color-muted;
          font-size: 12px;
          letter-spacing: .67px;
        }
      }

      &.skill-progress {
        order: 1;
        margin-left: 27px;
      }

      &.skill-level {
        order: 2;
        padding: 0;
        line-height: 2;

        &.primary {
          margin: 0 8px;
          order: 5;
        }
      }

      &.mobile-skill-progress {
        order: 4;
        width: 124px;
        margin-left: 33px;

        .knowledge-level-container {
          width: 124px;
        }
      }

      &.empty-range {
        width: 124px;
        order: 4;
        margin-left: 37px;

        .knowledge-level-container {
          width: 118px;

          .rangeslider {
            .rangeslider__labels > li {
              &::after {
                left: 0;
                right: 0;
              }
            }
          }
        }
      }

      &.mobile-skill-required-wrapper {
        order: 3;
        flex-basis: 100%;
      }
    }

    .progress-container {
      width: 124px;
      margin: 6px 6px 3px;

      .progress-point {
        &.mobile-point {
          margin-left: -4px;
        }
      }
    }
  }

  .mobile-table-actions {
    height: 64px;
    display: flex;
    padding: 10px 20px;
    box-shadow: 0 -1px 0 0 $color-muted;
    position: fixed;
    bottom: 0;
    background-color: #fff;
    width: 100%;

    & > p {
      font-size: 0;
    }
  }
}

@media screen and (max-width: $media-xs) {
  .mobile-table {
    td {
      &.skill-progress {
        margin-left: 33px;
      }
    }
  }

  .mobile-table-actions {
    .btn {
      width: 100%;
      padding: 11px;
      min-width: auto;

      &.grey {
        order: -1;
        margin-right: 10px;
      }

      &:not(.grey) {
        white-space: nowrap;
        margin-right: 0;
      }
    }
  }
}
