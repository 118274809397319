@import '~common/styles/variables';

.skills-filter {
  .title {
    font: bold 28px/1 'Lato', sans-serif;
    padding: 28px 30px;
    margin: 0;
  }

  .search-skills {
    padding: 0 30px;
  }

  .search-container {
    margin-bottom: 15px;

    .form-label-title {
      display: none;
    }
  }

  .filter-buttons {
    .filter {
      color: $color-font-main;
      padding: 11px 20px;
      font-weight: normal;
      border-color: $border-light-color;
    }

    .select-filter {
      color: $color-font-dark;
      padding-left: 5px;
    }
  }

  .filter-buttons {
    & > div {
      display: inline-block;
      min-width: 110px;
      padding-right: 15px;
    }
  }

  .skills-container {
    height: calc(var(--vh, 1vh) * 100 - 325px);
    margin-top: 20px;

    .ps__rail-y {
      right: 10px !important;
    }

    .ps__thumb-y {
      background-color: $border-light-color;
    }

    .skills-list {
      padding: 0 0 20px;
      margin-top: 0;
      list-style: none;

      .empty {
        padding: 8px 30px;
      }

      .checkbox-inline {
        padding: 8px 30px;
        transition: .3s;

        & > span {
          width: calc(100% - 50px);
          padding-left: 5px;
        }

        &:hover {
          background-color: #e5eefc;
        }
      }
    }
  }

  .form-buttons {
    padding: 10px 30px 40px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 -23px 20px 0 #fff;

    .apply {
      width: 56%;
    }

    .reset {
      width: 40%;
      margin-right: 0;
    }
  }
}
