@import '~common/styles/variables';

.button-send-icon {
  display: none;
}

@media screen and (max-width: $media-md) {
  .mobile-conversation {
    height: calc(var(--vh, 1vh) * 100 - 60px - 52px - 32px);

    .scrollbar-container {
      height: calc(100% - 77px);
    }
  }

  .conversation-controls-panel {
    height: auto;
  }
}

@media screen and (max-width: $media-sm) {
  .mobile-conversation {
    height: calc(var(--vh, 1vh) * 100 - 52px);

    .scrollbar-container {
      margin: 10px 0;
      height: calc(100% - 84px);
    }
  }

  .candidate-page {
    .conversation-controls-panel {
      padding: 5px 2px;

      .upload-file {
        width: 36px;
        display: flex;
        align-items: center;
      }

      .message-input {
        width: calc(100% - 40px);
      }

      .send-message {
        .send-message-button {
          padding: 10px 10px 8px 8px;

          .button-send-text {
            display: none;
          }

          .button-send-icon {
            display: block;
          }
        }
      }
    }
  }
}
