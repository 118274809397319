@import '~common/styles/variables';

$date-separator-color: #7daeff;

.date-separator {
  font-size: 14px;
  color: $date-separator-color;
  text-align: center;
  width: 100%;
  padding: 10px;
}
