.knowledge-level-form {
  .row {

    .level {
      min-width: 300px;
      margin-right: 50px;
    }

    &.skill-name {
      justify-content: space-between;
    }
  }

  & .form-buttons {
    bottom: 0;
    z-index: 100;
    position: sticky;
    background: #fff;
    padding-top: 65px;
    box-shadow: 0 -23px 20px 0 #fff;
  }
}
