@import '~common/styles/variables';

$background-white: #fff;
$dropdown-shadow: rgba(80, 95, 121, .3);

.application-dropdown-container {
  .outside {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
  }

  .application-dropdown-filter {
    position: relative;

    &:hover {
      .application-dropdown-label {
        border-color: $color-accent;
      }
    }

    .application-dropdown-label {
      cursor: pointer;
      padding: 12px 16px;
      border-radius: 4px;
      position: relative;
      white-space: nowrap;
      transition: border-color .15s;
      border: solid 1px $border-light-color;

      .application-dropdown-label-value {
        color: $color-font-dark;
      }

      &::after {
        content: url('/images/shared/arrow-down.svg');
        position: absolute;
        right: 12px;
        top: 12px;
      }
    }

    .application-dropdown-options {
      left: 0;
      top: 110%;
      width: 100%;
      z-index: 990;
      padding: 12px 0;
      min-width: 200px;
      overflow-y: auto;
      max-height: 300px;
      position: absolute;
      border-radius: 4px;
      background: $background-white;
      border: solid 1px $color-muted;
      box-shadow: 0 8px 15px 0 $dropdown-shadow;

      .application-dropdown-list {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          cursor: pointer;
          padding: 11px 24px;
          font-weight: bold;
          color: $color-font-dark;

          &.active,
          &:hover {
            background-color: $color-muted;
          }
        }

        .create-application-action {
          margin-top: 3rem;
          color: $color-primary;
          letter-spacing: .33px;
        }
      }
    }
  }

  .application-dropdown-label {
    .form-label-title {
      margin-bottom: 1rem;
      display: inline-block;
    }
  }
}
