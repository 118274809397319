@import '~common/styles/variables';

.mobile-chat-placeholder {
  height: calc(100% - 60px);
}

@media screen and (max-width: $media-md) {
  .mobile-chat-placeholder {
    height: 100%;
  }
}
