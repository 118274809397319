@import '~common/styles/variables';

.realize-section-container {
  display: flex;
  padding-bottom: 30px;
  align-items: center;
  border-bottom: 1px solid #bdc9df;
  flex-direction: column;

  .realize-section-title {
    text-align: center;
    font: normal 20px/1 'Lato', sans-serif;
    color: $color-text-dark;
    margin-bottom: 15px;
  }

  .realize-section-list {
    font: normal 16px/1.33 'Lato', sans-serif;
    color: $color-font-main;
    letter-spacing: .4px;
    list-style: none;
  }

  .realize-section-description {
    font: normal 16px/1.33 'Lato', sans-serif;
    color: $color-font-main;
    letter-spacing: .4px;
    display: flex;
    flex-direction: column;
    padding: 0 10% 3%;

    &-element {
      padding-top: 15px;
    }
  }

  .realize-section-button {
    margin-top: 20px;
  }
}
