@import '~common/styles/variables';

.modal.invites-modal {
  .modal-content {
    min-width: 620px;
    transform: translate(-35%, -50%);
  }
}

.invites-content {
  padding: 1rem 20px;
}

.invites-modal-form {
  & > .form-group {
    width: 320px;
    padding-top: 12px;
  }
}

.invites-description {
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: .1px;
  color: $color-font-dark;
  font-family: Lato, sans-serif;
}

.form-group.invites-action {
  padding-top: 49px;
}
