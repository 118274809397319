@import 'variables';

$highlight-color: #fff;

.rc-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
  font-size: 12px;
  line-height: 1.5;
  opacity: .9;

  &.rc-tooltip-hidden {
    display: none;
  }

  .rc-tooltip-content {
    background: $color-font-dark;
    border-radius: 4px;
    color: $highlight-color;
    padding: 8px 22px;
    font-size: 14px;
    position: relative;
    font-weight: bold;

    .rc-tooltip-arrow {
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -4px;
      border: 4px solid transparent;
      border-top-color: $color-font-dark;
    }
  }
}
