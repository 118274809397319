@import '~common/styles/variables';

.suggest-skill-modal-container {
  .modal-content {
    min-width: 470px;
  }

  .suggest-skill-modal-form {
    .form-label-title {
      text-transform: capitalize;
    }

    .form-group {
      padding: 40px 20px;
      margin: 0;
    }

    .suggest-skill-success-description {
      padding: 40px;
      text-align: center;
      color: $color-primary;
      font: normal 16px/1.6 'Lato', sans-serif;

      .success-requested-skill-icon {
        width: 40px;
        height: 40px;
      }
    }
  }
}

.suggest-skill-modal-form-action {
  display: flex;
  padding: 14px 20px;
  border-top: 1px solid $color-muted;
  text-transform: capitalize;

  .btn {
    text-transform: capitalize;
  }
}
