@import '~common/styles/variables';

.companies-container {
  .title {
    font: normal 28px/1.14 'Lato', sans-serif;
    color: $color-text-dark;
    margin: 40px 0 20px;
    letter-spacing: 0.6px;
  }

  .companies-list {
    display: flex;
    justify-content: space-between;
  }

  .company {
    position: relative;
    max-width: 100%;
    height: 120px;

    &_size_s {
      width: 228px;
    }

    &_size_m {
      width: 310px;
    }

    &_size_l {
      width: 355px;
    }
  }
}
