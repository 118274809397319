@import '~common/styles/variables';

.on-boarding-modal + .modal-overlay.overlayed {
  background: rgba(122, 140, 170, .4);
}

.on-boarding-modal {
  &.open {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999999;
    max-width: 100%;
    height: 61%;
    max-height: 100%;

    @media screen and (max-width: 768px) {
      width: 92%;
    }
  }

  .modal-content {
    display: block;
    width: 100%;
    text-align: center;
    padding: 10px 2px 0;
    flex-direction: column;
  }

  .video-player {
    width: 100%;
  }
}
