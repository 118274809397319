.general-info-form {
  width: 80%;
  min-width: 700px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  .form-buttons {
    margin-top: 40px;
  }

  .salary-range,
  .working-time {
    display: flex;
    width: 100%;

    .salary-field-width {
      flex-basis: calc(25% - 10px);
    }

    .working-time-field-width {
      flex-basis: calc(35% - 10px);
    }

    .form-group {
      margin-right: 12px;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .min-salary input {
      padding-left: 55px;
    }

    .max-salary input {
      padding-left: 40px;
    }
  }

  .salary-range {
    align-items: center;
  }
}
