.icon-applications {
  &::before {
    content: '\e900';
  }
}

.icon-ask-me {
  &::before {
    content: '\e901';
  }
}

.icon-balance-board {
  &::before {
    content: '\e902';
  }
}

.icon-chat {
  &::before {
    content: '\e903';
  }
}

.icon-favorites {
  &::before {
    content: '\e904';
  }
}

.icon-follow {
  &::before {
    content: '\e905';
  }
}

.icon-match {
  &::before {
    content: '\e906';
  }
}

.icon-my-posts {
  &::before {
    content: '\e907';
  }
}

.icon-search {
  &::before {
    content: '\e908';
  }
}

.icon-sv {
  &::before {
    content: '\e909';
  }
}

.icon-active {
  &::before {
    content: '\e90a';
  }
}

.icon-cancel-m {
  &::before {
    content: '\e90b';
  }
}

.icon-cancel-s {
  &::before {
    content: '\e90c';
  }
}

.icon-edit {
  &::before {
    content: '\e90d';
  }
}

.icon-favorites-off {
  &::before {
    content: '\e90e';
  }
}

.icon-favorites-on {
  &::before {
    content: '\e90f';
  }
}

.icon-settings {
  &::before {
    content: '\e910';
  }
}

.icon-calendar {
  &::before {
    content: '\e953';
  }
}

.icon-statistic {
  &::before {
    content: '\e99c';
  }

.icon-close {
  &::before {
    content: '\e993';
  }
} 
}
