@import '~common/styles/variables';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

.mobile-gallery-carousel {
  display: none;
}

.create-post-modal {
  .text-content {
    & > p {
      word-break: break-word;
    }
  }
}

@media screen and (max-width: $media-xl) {
  .create-post-modal {
    width: 70vw;
  }
}

@media screen and (max-width: $media-lg) {
  .create-post-modal {
    width: 100vw;
  }

  .edit-post-modal-mobile {
    .content {
      .card {
        min-height: auto;
      }
    }
  }
}

@media screen and (max-width: $media-md) {
  .create-post-modal {
    padding: 0;

    .modal-header {
      display: block;
      padding: 20px 20px 15px;
      margin: 0 0 10px;

      .left-side {
        padding: 0 0 10px;

        h2 {
          margin: 10px 0 15px;
        }
      }

      .right-action {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .btn {
          min-width: 120px;
          width: calc(50% - 5px);
          display: block;
          margin: 0;
        }

        .btn:first-of-type {
          margin: 0;
        }
      }
    }

    .text-content {
      max-width: 100%;

      .gallery {
        display: block;
        max-height: none;

        .mobile-gallery-carousel {
          display: block;
        }

        img {
          opacity: 1;
          max-height: 50vh;
          object-fit: contain;
        }

        .slick-dots {
          position: relative;
          bottom: 0;
          margin: 0 0 20px;

          li {
            width: 8px;
            height: 8px;
            margin: 0 2px;
          }

          button {
            width: 8px;
            height: 8px;
            background: #fff;
            border: solid 1px $color-primary;
            border-radius: 50%;
            padding: 0;
            margin: 0;

            &:before {
              content: none;
            }
          }

          .slick-active {
            button {
              background: $color-primary;
            }
          }
        }

        .mobile-main-picture {
          display: none;
        }

        .aside-photos {
          display: none;
        }
      }

      & > p {
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: .89px;
      }
    }
  }

  .edit-post-modal-mobile {
    .content {
      .card {
        height: auto;
        overflow: hidden;
      }
    }
  }
}
