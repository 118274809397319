@import '~common/styles/variables';

@media screen and (max-width: $media-md) {
  .mobile-search-empty {
    display: none;
  }

  .conversations-search {
    padding: 20px 20px 18px;
  }

  .conversations-list {
    max-height: calc(var(--vh, 1vh) * 100 - 52px - 32px - 60px);

    .conversation {
      padding: 10px 20px;

      .body {
        padding-top: 2px;

        .name {
          padding-bottom: 0;
          line-height: 1.71;
          letter-spacing: .29px;
          color: $color-text-dark;
        }

        .text {
          line-height: 1.43;
          letter-spacing: .29px;
          color: $color-font-main;
        }
      }

      .date-time {
        justify-content: flex-start;

        .time {
          line-height: 2;
          padding-bottom: 3px;
          white-space: nowrap;
          letter-spacing: .29px;
          color: $soft-dark-text;
        }
      }
    }
  }
}

@media screen and (max-width: $media-sm) {
  .conversations-list {
    max-height: calc(var(--vh, 1vh) * 100 - 52px);
  }
}
