@import '~common/styles/variables';

$header-background: #fff;
$header-shadow-color: rgba(66, 101, 159, .2);

.main-header {
  background: $header-background;
  box-shadow: 0 2px 3px 0 $header-shadow-color;
  height: 52px;

  &.fixed {
    left: 0;
    right: 0;
    z-index: 9999;
    position: fixed;
  }

  .header-container {
    display: flex;
    min-height: 52px;
    justify-content: space-between;
  }

  .headers-links {
    display: flex;
    align-items: center;

    .nav-list {
      padding: 1px 0;
      margin: 0;
      display: flex;
      align-items: center;

      li {
        list-style: none;

        .nav-link {
          padding: 1rem 1.125rem;
          font-size: .875rem;
          display: inline-block;
          color: $color-font-dark;
        }
      }
    }

    .language-switcher {
      display: flex;
      width: 70px;
      justify-content: flex-end;

      .btn-switcher {
        padding: .75rem;
        cursor: pointer;

        img {
          width: 28px;
          vertical-align: middle;
        }
      }
    }

    .control-panel {
      margin: auto 0 auto 20px;

      .btn {
        margin-right: 0;
        padding: 9px 19px;
      }
    }
  }
}

.ring-bell {
  width: 70px;
  border-left: 1px solid $color-muted;
  border-right: 1px solid $color-muted;
  display: flex;
  justify-content: center;
  align-items: center;

  & > .badge-wrapper {
    position: relative;
    cursor: pointer;
  }

  & .badge {
    top: -9px;
    width: 20px;
    right: -9px;
    color: #fff;
    height: 20px;
    display: flex;
    font-size: 10px;
    overflow: hidden;
    border-radius: 50%;
    text-align: center;
    position: absolute;
    align-items: center;
    letter-spacing: .3px;
    justify-content: center;
    background: $color-badge;
  }
}
