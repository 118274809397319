@import '~common/styles/variables';

@media screen and (max-width: $media-md) {
  .mobile-create-letter-application {
    box-shadow: 0 -1px 0 0 $color-muted;
    height: 64px;
    margin-top: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
  }
}

@media screen and (max-width: $media-sm) {
  .mobile-create-letter-application {
    margin-top: 0;
  }
}

@media screen and (max-width: $media-xs) {
  .mobile-applications-portlet-body {
    padding: 0;

    .ex-list-xl {
      border-spacing: 0;

      tr {
        border-radius: 0;
        padding: 7px 20px 10px;

        &:first-child {
          border-top: 0;
        }

        td {
          .item-cell {
            padding: 10px 0;
          }

          .chip {
            min-width: 120px;
            text-align: center;
          }
        }
      }
    }
  }
}
