@import '~common/styles/variables';

@media screen and (max-width: $media-sm) {
  .ex-list-xl {
    tr {
      td {
        &:first-child {
          padding-left: 20px;
        }

        &:last-child {
          padding-right: 10px;
        }

        .cell {
          padding: 8px 5px;
        }
      }
    }
  }
}

@media screen and (max-width: $media-xs) {
  .portlet-body {
    &.mobile-portlet-chat,
    &.mobile-portlet-list {
      padding: 0;
      margin-top: 20px;
      border-top: 1px solid #e5eefc;
      border-bottom: 1px solid #e5eefc;
    }
  }

  .ex-list-xl {
    tr {
      display: block;
      border-bottom: 1px solid $color-muted;

      td {
        display: block;
        box-shadow: none;
        border: 0 none;

        &:first-child {
          border: 0;
          padding-left: 0;
          box-shadow: none;
        }

        &:last-child {
          border: 0;
          padding-right: 0;
          box-shadow: none;
        }

        .cell {
          padding: 0;
          min-height: 1px;
          margin: 0 0 5px;
        }
      }

      .actions {
        .custom-btn {
          width: 20px;
          height: 20px;
          position: absolute;
          top: 17px;
          right: 20px;

          .icon-cancel-m,
          .icon-favorites-on,
          .icon-favorites-off {
            width: 15px;
            height: 14px;
            margin: 0 auto;

            &:before {
              font-size: 16px;
              line-height: 14px;
            }
          }
        }
      }
    }
  }
}
