@import '~common/styles/variables';

.nav-filter-list {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    height: 44px;
    cursor: pointer;
    display: flex;
    padding: 8px 20px;
    font-size: .875rem;
    align-items: center;
    justify-content: space-between;

    &:hover,
    &.active {
      background-color: $color-muted;
    }

    h5 {
      font-size: .875rem;
      margin: 0;
    }

    .counter {
      font-weight: bold;
      color: $color-primary;
    }
  }
}
