@import '~common/styles/variables';

$footer-height: 52px;

.success-payment-container {
  max-width: 700px;
  padding: 16px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(var(--vh, 1vh) * 100 - #{$footer-height});
}

.success-payment-content {
  padding: 50px;

  & > h2 {
    margin: 0 0 34px;
  }

  & .success-text {
    color: $color-primary;
    text-transform: uppercase;
  }

  & .payment-description {
    letter-spacing: .4px;
    line-height: 24px;
    max-width: 430px;
    margin: 0 auto;
    padding: 20px 0 38px;
  }

  & .agreement {
    font-size: 14px;
    letter-spacing: .35px;
    line-height: 20px;

    & > a {
      color: $color-accent;
    }
  }
}

.success-payment-action {
  max-width: 320px;
  margin: 0 auto;

  & .start-btn {
    min-width: 320px;
    margin-bottom: 12px;
  }
}

.success-payment-footer > .container {
  min-width: auto;
  padding: 0;

  & > .footer-content {
    padding: 0;
  }
}
