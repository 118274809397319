@import '~common/styles/variables';

.auth-wrapper {
  height: calc(var(--vh, 1vh) * 100);
  min-height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .auth-container {
    max-width: 580px;
    margin: 0 auto;
  }

  .auth-footer-container {
    max-width: 680px;
  }

  .auth-full-width-container {
    max-width: none;
  }

  .logo-container {
    text-align: center;

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }

    .header-title {
      font: normal 28px/1.14 'Lato', sans-serif;
      color: $color-text-dark;
      margin: 0 0 10px;
    }

    .header-information {
      font: normal 18px/1.56 'Lato', sans-serif;
      color: $color-font-main;
    }

    img {
      display: inline-block;
      vertical-align: middle;
      margin: 28px auto;
    }
  }

  .auth-form-container {
    .agreement {
      text-align: center;
      font-size: .875rem;
      font-style: italic;
      padding: 0 40px;

      a {
        color: $color-accent;
      }
    }
  }
}
