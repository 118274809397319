@import '~common/styles/variables';

@media screen and (max-width: $media-xs) {
  .forgot-link-holder {
    flex-direction: column-reverse;
    align-items: flex-start;
    padding-bottom: 10px;

    .forgot-link {
      display: inline-block;
      margin-bottom: 30px;
    }
  }
}
