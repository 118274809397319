@import '~common/styles/variables';

$counter-color: #fff;

.message {
  width: 100%;
  display: flex;
  padding: 10px 15px;
  min-height: 65px;

  .body {
    width: calc(85% - 40px);
    font-size: 14px;
    padding-top: 5px;

    .name {
      color: $color-text-dark;
      font-weight: bolder;
      padding-bottom: 5px;
    }
  }

  .attachment {
    display: flex;

    .file {
      color: $color-font-main;
      margin-top: 10px;
      border: 1px solid $border-light-color;
      font-size: 14px;
      border-radius: 4px;
      background-color: $counter-color;
      display: flex;
      align-items: center;
      padding: 7px 10px 7px 20px;
      text-decoration: none;
      transition: .3s opacity;

      &:hover {
        opacity: 0.7;
      }

      .title {
        padding-right: 20px;
      }

      .size {
        color: $soft-dark-text;
        font-size: 12px;
      }
    }
  }

  .date-time {
    width: 15%;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;

    .time {
      color: $color-font-dark;
      padding-bottom: 5px;
    }

    .status {
      color: $soft-dark-text;
    }
  }
}
