@import '~common/styles/variables';

.chat-user {
  display: flex;
  font-size: 14px;

  &.overlay {
    cursor: pointer;
    transition: opacity .3s;

    &:hover {
      opacity: .4;
    }
  }

  .information {
    padding: 5px 0;
    text-align: right;
    margin-right: 15px;

    .name {
      color: $color-font-dark;
      font-weight: bolder;
      padding-bottom: 2px;
    }
  }

  .avatar-container {
    margin-right: 0;
  }
}
