@import '~common/styles/variables';

.mobile-salary-range-container {
  .salary-type {
    margin-top: 10px;
  }
}

.min-salary {
  .form-label-title {
    white-space: nowrap;
  }
}

@media screen and (min-width: $media-sm) {
  .max-width {
    .min-salary {
      max-width: 165px;
    }
  }
}

@media screen and (max-width: $media-sm) {
  .mobile-salary-range-container {
    flex-wrap: wrap;
    margin: 0 0 20px;
    align-items: flex-start;

    .salary-field-width {
      margin: 0 0 10px;
      flex-basis: auto;
      width: 50%;
      padding-right: 10px;

      &.max-salary {
        padding-right: 0;
      }

      &.salary-type {
        width: 100%;
        padding-right: 0;
        margin-bottom: 0;
      }
    }
  }
}
