@import '~common/styles/variables';
@import '~react-datepicker/dist/react-datepicker.css';

@media screen and (max-width: $media-xs) {
  .date-picker {
    .react-datepicker__header {
      .react-datepicker__day-names {
        padding: 9px 0;
      }
    }

    .react-datepicker__year-select {
      opacity: 0;
    }
  }
}
