@import '~common/styles/variables';

$background-white: #fff;
$muted-input-color: #bdc9df;
$input-color-muted: #bdc9df;

.confirmation-code-container {
  .react-code-input {
    input {
      position: relative;
      padding: 0 !important;
      font-size: 18px !important;
      text-align: center !important;
      transition: border-1color .15s;
      box-shadow: none !important;
      text-transform: uppercase !important;
      color: $color-font-dark !important;
      font-family: 'Lato', sans-serif !important;
      border: solid 1px $border-light-color !important;

      &::placeholder {
        opacity: 1;
        font-weight: normal;
        color: $muted-input-color !important;
      }

      &:hover,
      &:focus {
        outline: none;
        border-color: $color-accent !important;
      }
    }
  }

  .confirmation-code-form {
    text-align: center;
    padding: 38px 55px 0;
  }

  .email-to-confirm {
    font-weight: bold;
  }

  & .confirmation-title {
    font-size: 28px;
    font-weight: bold;
    line-height: 1;
  }

  & .confirmation-caption {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 2rem;
  }

  & .confirmation-actions {
    margin-bottom: 20px;
  }

  & form {
    border-bottom: 1px solid $border-light-color;
  }

  .confirm-btn {
    margin: 0;
    max-width: 130px;
  }

  .resend-link {
    cursor: pointer;
    text-underline: none;

    &.accent {
      color: $color-accent;
    }

    &.primary {
      color: $color-primary;
    }
  }

  .auth-form-footer {
    padding: 18px 70px;
  }
}
