.modal.premium-info-modal {
  transform: translateX(14%);

  .modal-content {
    min-width: 432px;
  }

  .feature-list {
    margin: 8px 0;
    font-size: 14px;
    padding: 0 20px;

    & > li {
      list-style-image: url('/images/shared/list-check-icon.png');
      margin-bottom: 5px;
    }
  }

  & .price {
    padding: 0 29px 1rem;

    & .euro-sign {
      left: -10px;
    }
  }
}
