@import '~common/styles/variables';

.match-candidates-job-offer-info {
  font: normal 16px/1.25 'Lato', sans-serif;
  width: 750px;
  padding: 25px;

  .title {
    color: $color-text-dark;
    padding: 15px 0;
    font-size: 24px;
    font-weight: bolder;
  }

  .badge {
    color: $color-font-dark;
    padding: 5px 15px;
    font-size: 14px;
    border-radius: 14px;
    background-color: $bg-color-default;
    margin-right: 10px;

    &.published {
      color: $color-font-main;
    }

    &.points {
      color: $color-accent;
      background-color: $color-muted;
    }
  }
}
