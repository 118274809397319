@import '~common/styles/variables';

.profile-view {
  padding: 15px;
  display: flex;
  height: 100%;
}

.candidate-profile-preview {
  width: 100%;
  padding: 10px 0;

  .profile-preview {
    height: calc(100% - 44px);
    margin: 5px;
    display: flex;
    padding-right: 5px;

    .general-info {
      padding: 16px;
      flex: 1 1;
    }

    .aside-info {
      padding: 16px;
      width: 312px;
    }
  }
}
