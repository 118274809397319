.Toastify__toast {
  border-radius: 4px;
  background-color: #505F79;

  .Toastify__toast-body {
    color: #fff;
    font: normal 14px/1 'Lato', sans-serif;
  }

  .toastify-image {
    width: 17px;
    height: 17px;
    margin: auto 10px;
  }

  &.Toastify__toast--success,
  &.Toastify__toast--error {
    .Toastify__toast-body {
      position: relative;
      padding-left: 45px;

      &::before {
        content: '';
        background: url('/images/shared/toaster-success.svg') center/cover;
        position: absolute;
        top: -2px;
        left: 0;
        width: 20px;
        height: 20px;
        margin: 0 11px;
      }
    }
  }

  &.Toastify__toast--error {
    .Toastify__toast-body {
      &::before {
        background: url('/images/shared/toaster-error.svg') center/cover;
      }
    }
  }
}
