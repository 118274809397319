@import '~common/styles/variables';

.list-placeholder-container {
  display: flex;
  margin-top: 20px;
  align-items: center;
  flex-direction: column;

  .title {
    font: normal 24px/1 'Lato', sans-serif;
    color: $color-text-dark;
    padding: 15px 0;
    letter-spacing: 0.2px;
  }

  .content {
    color: $color-font-main;
    max-width: 400px;
    font-size: 18px;
    text-align: center;
  }
}
