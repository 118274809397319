@import "~common/styles/variables";

.chip {
  display: inline-block;
  border-radius: 14px;
  padding: 4px 12px;
  font-size: .875rem;
  color: $color-font-dark;
  margin-bottom: 8px;
  margin-right: 8px;

  &.mutted {
    background: $bg-color-default;
  }
}
