@import '~common/styles/variables';

.video-container {
  .video-content {
    .video-player {
      width: 100%;
      position: relative;
      padding: 0 0 0 0;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
      }
    }

    .mobile-video-subtitle {
      font-size: 16px;
      line-height: 1.25;
      margin-bottom: 13px;
      letter-spacing: .33px;
      color: $color-font-main;
    }

    .mobile-video-switcher {
      display: flex;
      margin-bottom: 29px;

      .btn {
        padding: 10px;
        min-width: 136px;
        letter-spacing: .33px;
        color: $color-font-main;
        background-color: transparent;
        border: 1px solid $color-font-main;

        &.active,
        &:hover {
          color: #fff;
          background: $color-font-main;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: $media-lg) {
  .video-container {
    .video-content {
      .video-title {
        font-size: 36px;
      }
    }
  }
}

@media screen and (max-width: $media-sm) {
  .video-container {
    margin-bottom: 40px;

    .video-content {
      .video-title {
        font-size: 28px;
        width: 80%;
        margin: 40px 0;
      }
    }
  }
}

@media screen and (max-width: $media-xs) {
  .video-container {
    .video-content {
      padding-left: 20px;
      padding-right: 20px;

      .video-title {
        font-size: 24px;
        line-height: 1.33;
        letter-spacing: .5px;
        text-align: left;
        width: 100%;
        margin-bottom: 35px;
      }

      .video-player {
        padding: 0 0 0 0;
      }

      .mobile-video-subtitle {
        width: 61%;
        display: inline-block;
        align-self: flex-start;
      }
    }

    .background {
      height: 90%;
    }
  }
}
