@import '~common/styles/variables';

@media screen and (max-width: $media-xs) {
  .phone-input-container {
    .code-action-button {
      .selected-flag {
        width: 46px;
      }
    }

    .options-list.country-list {
      max-width: calc(100vw - 40px);
    }
  }
}
