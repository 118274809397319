@import '~common/styles/variables';

.icon-cancel-m,
.icon-favorites-on,
.icon-favorites-off {
  display: block;
  width: 38px;
  height: 38px;

  &::before {
    font-size: 24px;
    line-height: 40px;
  }

  &.primary {
    color: $color-primary;
  }

  &.accent {
    color: $color-accent;
  }
}

.hidden.rc-tooltip {
  display: none !important;
}

.custom-btn {
  border: 0;
  outline: none;
  appearance: none;
  background: none;
  cursor: pointer;
  color: $color-primary;

  &.accent {
    color: $color-accent;
  }

  span {
    color: currentColor;

    &::before {
      color: currentColor;
    }
  }

  &.icon {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 0;
    transition: background-color .2s;

    &:hover {
      background: $close-btn-hover-bg;
    }
  }

  &.outlined {
    border: 1px solid;
    border-radius: 4px;
  }

  & > .close {
    width: 24px;
    height: 24px;
    display: inline-block;
    transition: background-color .2s ease-in;
    background: url('/images/shared/cancel-s.svg') no-repeat;
  }

  & > .primary-close {
    width: 19px;
    height: 19px;
    display: inline-block;
    transition: background-color .2s ease-in;
    background: url('/images/shared/primary-close.png') no-repeat;
  }

  & > .accent-delete {
    width: 24px;
    height: 24px;
    display: inline-block;
    transition: background-color .2s ease-in;
    background: url('/images/shared/basket.svg') no-repeat;
  }
}
