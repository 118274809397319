@import '~common/styles/variables';

@media screen and (max-width: $media-xl) {
  .mobile-cv-modal {
    width: 70vw;
  }
}

@media screen and (max-width: $media-lg) {
  .mobile-cv-modal {
    width: 95vw;
  }
}

@media screen and (max-width: $media-md) {
  .mobile-cv-modal {
    width: 100vw;

    .cv-content {
      max-width: 100%;
    }

    .content {
      width: 100%;
      min-width: auto;
      max-width: 100%;
    }
  }
}

@media screen and (max-width: $media-sm) {
  .mobile-cv-modal {
    padding: 0;

    .cv-content {
      margin-bottom: 0;
    }

    .card {
      min-height: auto;
    }
  }
}

@media screen and (max-width: $media-xs) {
  .mobile-actions {
    width: 100%;
    display: flex;
    box-shadow: none;
    border-top: 1px solid $color-muted;

    .btn {
      white-space: nowrap;

      &:not(.grey) {
        width: 100%;
        padding: 11px 5px;
      }

      &.grey {
        order: 1;
        padding: 11px;
        max-width: 35%;
        margin-right: 10px
      }
    }
  }
}
