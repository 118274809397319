$card-box-shadow-color: rgba(66, 101, 159, .2);
$card-background: #fff;

.companies-list {
  .card {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;

    padding: 12px;

    border: none;
    outline: none;
    border-radius: 4px;
    background-color: $card-background;
    box-shadow: 0 2px 3px 0 $card-box-shadow-color;
    cursor: pointer;

    &:hover,
    &:focus {
      text-decoration: none;

      .card-body .icon {
        opacity: .5;
      }
    }
  }

  .btn.card {

  }

  .card-head {
    position: relative;
    padding-right: 110px;
    width: 100%;

    .image-container {
      display: flex;
      align-items: center;
      height: 60px;
      text-align: left;

      .logo {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .logo_size_l {
        width: 154px;
        height: 29px;
      }

      .logo_size_m {
        width: 113px;
        height: 43px;
      }
    }

    .offers {
      position: absolute;
      top: 0;
      right: 0;

      display: inline-block;
      padding: 2px 12px;
      height: 24px;

      font: normal 12px/1.6 'Lato', sans-serif;
      text-align: center;
      text-transform: capitalize;
      letter-spacing: 0.25px;

      color: #7a8cab;
      background-color: #f4f5f7;
      border-radius: 14px;
    }
  }

  .card-body {
    position: relative;
    width: 100%;
    text-align: left;

    .name {
      display: block;
      //padding: 2px 5px;
      width: 80%;
      font: normal 16px/1.25 'Lato', sans-serif;
      letter-spacing: 0.3px;
      color: #3b475a;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .description {
      font: normal 12px/0.25 'Lato', sans-serif;
      line-height: 1.6;
      color: #7a8cab;
    }

    .icon {
      position: absolute;
      top: 0;
      right: 5px;
      display: inline-block;
      width: 12px;
      height: 20px;
      background: url('/images/landing/icon-right-chevron.svg') no-repeat center center;
      transition: .3s opacity;
    }
  }
}
