@import '~common/styles/variables';

.mobile-restriction-info,
.candidate-card-title {
  display: none;
}

@media screen and (max-width: $media-md) {
  .candidate-card-title {
    display: block;
    line-height: 1.17;
    letter-spacing: .2px;
    font-size: 24px;
    color: $color-text-dark;
    padding: 0 0 25px;
  }

  .restriction-info {
    display: none;
  }

  .mobile-restriction-info {
    display: block;
    position: relative;
    letter-spacing: .35px;
    margin: -10px 0 0;
  }
}

@media screen and (max-width: $media-sm) {
  .candidate-info-form {
    .profile-activity-container {
      padding: 0 0 10px;
    }
  }

  .divider {
    height: 8px;
    background: $bg-color-default;
    width: calc(100% + 40px);
    margin: 5px -20px 10px;
    box-shadow: inset 0 2px 3px -2px rgba(66, 101, 159, .2);
  }
}

@media screen and (max-width: $media-xs) {
  .candidate-info-form {
    & > .form-btn-submit {
      display: block;
      padding: 20px 0 0;
      max-width: 280px;

      .btn {
        width: 100%;
        margin-right: 0;

        &[type='submit'] {
          margin-bottom: 45px;
        }
      }
    }
  }

  .candidate-avatar.candidate-content {
    .upload-avatar-container {
      flex-wrap: wrap;
      padding: 0 0 20px;

      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        order: 2;
      }
    }

    .upload-avatar-container > .avatar {
      order: 1;
      margin-bottom: 25px;
    }

    .btn {
      order: 3;
    }
  }
}
