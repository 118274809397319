@import '~common/styles/variables';

@media screen and (max-width: $media-xs) {
  .notification-item {
    &:first-of-type {
      border: 0 none;
    }

    .logo-container {
      min-width: 48px;
    }

    .notification-item-content {
      border: 0 none;
      padding: 10px 0;

      .content {
        & > h3 {
          color: $color-text-dark;
          line-height: 1.25;
          letter-spacing: .13px;
        }

        & > p {
          line-height: 1.5;
          letter-spacing: .13px;
          margin: 5px 0;
        }

        & .publish-date {
          line-height: 1.43;
          letter-spacing: .11px;
          color: #bdc9df;
        }

        .actions {
          .btn {
            width: 100%;

            &:last-of-type {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}
