.radio-group {
  width: 100%;
  padding: 18px 0;

  & > .radio {
    display: block;
    flex: 1;
  }

  &.radio-inline {
    & > .radio {
      display: inline-block;
    }
  }
}

.radio-label-title {
  display: block;
  line-height: 1.5;
  vertical-align: middle;
}
