@import '~common/styles/variables';

.active-jobs-filters {
  border: 1px solid $border-light-color;
  padding: 11px 40px 11px 20px;
  font-size: 14px;
  margin-right: 12px;
  border-radius: 4px;
  position: relative;

  .count {
    color: $color-primary;
    font-weight: bold;
    padding-left: 5px;
  }

  .reset {
    top: 9px;
    right: 6px;
    cursor: pointer;
    position: absolute;
    font-size: 22px;
    text-decoration: none;

    &:hover {
      opacity: .7;
    }
  }
}
