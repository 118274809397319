@import '~common/styles/variables';
@import '~react-rangeslider/lib/index.css';

$line-color: #0052dd;
$picker-color: #3b83ff;
$handle-shadow: rgba(0, 0, 0, .25);
$line-background: rgb(141, 175, 232);
$line-empty-color: #e5eefc;

.range-control-container {
  width: 90%;

  .rangeslider {
    box-shadow: none;
    height: 4px;
    background: $line-empty-color;

    .rangeslider__label-item {
      color: transparent;

      &::after {
        content: '';
        width: 8px;
        height: 8px;
        display: inline-block;
        position: absolute;
        top: 33px;
        left: 0;
        right: 0;
        margin: 0 auto;
        border-radius: 50%;
        background: $line-empty-color;
      }

      &:first-child {
        &::after {
          left: 10px;
        }
      }

      &:last-child {
        &::after {
          right: 10px;
        }
      }
    }

    .rangeslider__handle-label {
      top: -32px;
      position: relative;
      right: 6px;
      font: normal 16px/1.25 'Lato', sans-serif;
      color: $color-font-dark;
      user-select: none;
    }

    .rangeslider__fill {
      background: $line-background;
      box-shadow: none;
    }

    .rangeslider__handle {
      width: 16px;
      height: 16px;
      outline: none;
      border-radius: 30px;
      border: solid 5px $picker-color;
      box-shadow: 0 1px 2px 0 $handle-shadow;
      z-index: 10;

      &::after {
        display: none;
      }
    }
  }

  &.disabled {
    & .rangeslider {
      & .rangeslider__handle {
        border-color: $color-muted;
      }
    }
  }
}
