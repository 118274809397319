@import '~common/styles/variables';

$background-white: #fff;
$checkbox-color: #fff;

.form-control {
  &[type='checkbox'] {
    visibility: hidden;
    position: absolute;

    & + span {
      display: inline-block;
      width: 24px;
      height: 24px;
      line-height: 22px;
      border-radius: 4px;
      background-color: $background-white;
      border: solid 1px $border-light-color;
      appearance: none;
      margin: 0 8px 0 0;
      vertical-align: middle;
      text-align: center;
      outline: none;
      transition: background .15s, border-color .15s;
      cursor: pointer;

      &:hover {
        border-color: $color-accent;
      }
    }

    &:checked {
      & + span {
        background: $color-primary;
        border-color: $color-primary;

        &:hover {
          background: darken($color-primary, 16%);
        }

        &::before {
          content: '';
          display: inline-block;
          width: 4px;
          height: 11px;
          border-right: 3px solid $checkbox-color;
          border-bottom: 3px solid $checkbox-color;
          transform: rotate(35deg);
        }

        &.accent {
          background: $color-accent;
          border-color: $color-accent;

          &:hover {
            background: darken($color-accent, 16%);
          }
        }
      }
    }
  }
}

.form-label {
  &.checkbox-inline {
    align-items: baseline;
    justify-content: flex-start;

    & > .checkbox-label {
      width: 25px;
    }

    & > .checkbox-label-text {
      top: 2px;
      width: calc(100% - 25px);
      padding: 0 0 5px 8px;
      position: relative;

      a {
        color: $color-primary;
        margin: 0 3px;
      }

      &.accent {
        a {
          margin: 0 3px;
          color: $color-accent;
        }
      }
    }
  }
}
