@import '~common/styles/variables';

.divider {
  border: none;
}

.form-group.birthday {
  & .date-picker {
    width: 220px;
  }
}

.candidate-content > .upload-avatar-container {
  padding-top: 0;

  .avatar {
    width: 92px;
    height: 92px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 28px;
    border: 1px solid #6f6f71;
  }

  .upload-btn {
    letter-spacing: .33px;
    padding: 11px 16px;
    margin-right: 20px;
  }

  .avatar-error {
    top: 80px;
  }
}
