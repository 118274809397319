@import '~common/styles/variables';

@media screen and (max-width: $media-sm) {
  .form-label {
    &.checkbox-inline {
      & > .checkbox-label-text {
        top: 0;
        font-size: 14px;
        line-height: 1.4;
        letter-spacing: .11px;

        &.mobile-job-skills-interactions {
          font-size: 16px;
          line-height: 2;
          letter-spacing: .33px;
          padding: 0 0 5px 10px;
        }
      }
    }
  }
}

@media screen and (max-width: $media-xs) {
  .form-control {
    &[type='checkbox'] {
      & + span {
        width: 20px;
        height: 20px;
        line-height: 18px;
      }
    }
  }
}
