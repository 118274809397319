@import '~common/styles/variables';

.upload-avatar-container {
  padding: 40px 0 34px;
  display: flex;
  align-items: center;
  position: relative;

  & > .delete-btn {
    padding: 7px 8px;
  }

  & .avatar-error {
    top: 115px;
    left: 120px;
    font-size: 14px;
    position: absolute;
    color: $color-danger;
  }
}

.upload-file-input {
  height: 100%;
  left: 0;
  font-size: 0;
  position: absolute;
  width: 100%;
  opacity: 0;
  cursor: pointer;
  right: 0;
  top: 0;
  bottom: 0;
}

.upload-btn {
  position: relative;
}
