.study-place-container {
  padding: 24px;
  margin-bottom: 1rem;

  .study-place-caption {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .study-place-form-field {
    max-width: 450px;
  }

  .currently-study-field {
    display: inline-block;
  }

  .from-date {
    width: 100%;
    display: flex;
    margin: 0 0 24px;
    justify-content: space-between;

    .from-date-field {
      width: calc(50% - 10px);

      .react-datepicker-wrapper {
        width: 100%;

        .react-datepicker__input-container {
          width: 100%;
        }
      }
    }
  }
}
