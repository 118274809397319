@import '~common/styles/variables';

@media screen and (max-width: $media-sm) {
  .social-buttons {
    .social-login-btn {
      width: calc(50% - 8px);
      display: inline-block;
      margin: 6px 4px;
    }
  }

  .auth-divider {
    justify-content: flex-start;
    margin: 18px 0 12px;

    span {
      padding-left: 0;
    }
  }
}
