@import '~common/styles/variables';

.mobile-match-list {
  flex: 0 0 54%;
}

.mobile-chat-title-count,
.mobile-title-count {
  display: none;
}

@media screen and (max-width: $media-sm) {
  .mobile-match-list {
    display: none;
  }

  .mobile-chat-title-count,
  .mobile-title-count {
    display: inline-block;
    line-height: 1.17;
    letter-spacing: .2px;
    font-size: 24px;
    margin-left: 5px;
    color: $color-text-dark;
  }

  .mobile-head-count,
  .mobile-chat-conversations-head {
    justify-content: flex-start;

    .portlet-title {
      display: inline-block;
      flex: none;
    }
  }
}
