@import '~common/styles/variables';

@media screen and (max-width: $media-xl) {
  .list-placeholder-container {
    padding: 20px;
  }
}

@media screen and (max-width: $media-sm) {
  .list-placeholder-container {
    padding: 0 20px 20px;

    .image {
      margin: 15px 0 0;

      img {
        max-width: 100%;
        width: 256px;
        margin: 0 auto;
      }
    }

    .title {
      font-size: 18px;
      line-height: 1.33;
      letter-spacing: .7px;
      text-transform: uppercase;
      text-align: center;
      padding: 30px 15px 15px;
    }

    .content {
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: .13px;
      text-align: center;
    }
  }
}
