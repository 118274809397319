.modal.application-delete-modal {
  .modal-content {
    transform: translate(-81%, -50%);
  }

  .delete-modal-content {
    padding: 1rem;
    width: 380px;

    .confirm-delete {
      margin: 0 0 40px;
    }
  }

  .delete-action {
    display: flex;
  }
}
