@import '~common/styles/variables';

@media screen and (max-width: $media-lg) {
  .start-as-container {
    padding: 0 20px;
  }
}

@media screen and (max-width: $media-md) {
  .auth-container {
    .auth-wrapper {
      min-height: calc(var(--vh, 1vh) * 100);
      height: auto;
    }
  }

  .start-as-container {
    .recruiters-container,
    .candidates-container {
      height: auto;

      .banner-information {
        .control-container {
          .login-link {
            width: 200px;
            height: 40px;
            line-height: 40px;
            border-radius: 4px;
            background-color: $bg-color-default;
            color: $color-font-main;
            letter-spacing: .3px;
            text-align: center;
            display: inline-block;
            padding: 0 10px;
            font-size: 16px;
            border: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $media-sm) {
  .auth-wrapper {
    .logo-container {
      &.auth-home-page {
        padding: 0;
        margin: 0 0 5px;
        background: none;
        box-shadow: none;
        text-align: center;

        .header-title {
          letter-spacing: .6px;
          margin: 10px 0 5px;
        }

        .header-information {
          font-size: 16px;
          line-height: 1.75;
          letter-spacing: .3px;
        }
      }
    }
  }

  .start-as-container {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    padding: 0 20px 30px;

    .recruiters-container,
    .candidates-container {
      width: 100%;
      max-width: 440px;
      margin: 20px auto;
    }
  }
}

@media screen and (max-width: $media-md) {
  .start-as-container {
    .recruiters-container,
    .candidates-container {
      .banner-information {
        .control-container {
          .login-link,
          .btn {
            width: 100%;
            min-width: 248px;
            max-width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $media-xs) {
  .start-as-container {
    padding: 20px 8px 30px;

    .recruiters-container,
    .candidates-container {
      min-height: 1px;
      padding: 25px 28px 36px;
      margin: 0 auto 12px;
      max-width: 340px;

      &::after {
        content: none;
      }

      .title {
        margin: 0 0 5px;
      }

      .items {
        line-height: 1.43;
        letter-spacing: .3px;
        opacity: 1;
        width: 100%;

        .item {
          margin-bottom: 15px;
        }
      }

      .banner-information {
        margin: 15px 0 0;

        .control-container {
          margin: 0;
          width: 100%;
        }
      }
    }
  }
}
