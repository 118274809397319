@import '~common/styles/variables';

$search-modal-breakpoint-big: 1660px;
$search-modal-breakpoint-medium: 1160px;

.invite-profile-modal {
  display: flex;
  padding: 1rem 40px;
  height: calc(var(--vh, 1vh) * 100);
  background: $bg-color-default;
  width: 60vw;
  box-shadow: 0 5px 21px 0 rgba(66, 101, 159, .2);
  overflow: hidden;
  cursor: auto;
  flex-direction: column;

  & > .card {
    position: relative;

    &.profile-header {
      height: 150px;
      max-width: 900px;
      margin-bottom: 10px;
    }

    &.profile-view {
      width: 100%;
      max-width: 900px;
      height: calc(var(--vh, 1vh) * 100 - 195px);
    }
  }
}

@media all and (max-width: $search-modal-breakpoint-big) {
  .invite-profile-modal {
    width: 70vw;
  }
}

@media all and (max-width: $search-modal-breakpoint-medium) {
  .invite-profile-modal {
    width: 95vw;
  }
}
