@import '~common/styles/variables';


.mobile-message {
  .body {
    .name {
      .mobile-time {
        display: none;
      }
    }
  }
}

@media screen and (max-width: $media-sm) {
  .mobile-message {
    padding: 0 10px 10px;
    flex-direction: column;

    .mobile-chat-avatar-wrapper {
      display: none;
    }

    .body {
      width: auto;

      .name {
        display: flex;
        flex-wrap: nowrap;
        line-height: 1.71;
        padding-bottom: 1px;
        letter-spacing: .29px;

        .mobile-time {
          display: block;
          font-weight: 400;
          margin-left: 12px;
          white-space: nowrap;
          color: $soft-dark-text;
        }
      }

      .text {
        line-height: 1.43;
        padding-bottom: 7px;
        letter-spacing: .29px;
      }
    }

    .date-time {
      width: auto;
      align-items: flex-start;

      .mobile-time-hide {
        display: none;
      }
    }
  }
}

