@import '~common/styles/variables';

$bg-accepted: #edf9da;
$bg-declined: #f4f5f7;
$text-accepted: #70b53a;
$text-declined: #505f79;

.notification-item {
  display: flex;
  position: relative;

  &:first-of-type {
    border-top: 1px solid $color-muted;
  }

  & > .avatar {
    width: 34px;
    height: 34px;
    margin-right: 13px;
    border: none;
  }

  &.active::after {
    content: "";
    position: absolute;
    border-radius: 50%;
    right: 5px;
    top: 20px;
    width: 8px;
    height: 8px;
    box-shadow: 0 0 3px 1px $color-badge;
    background: $color-badge;
  }

  .logo-container {
    min-width: 50px;

    img {
      max-width: 100%;
      width: 36px;
      height: 36px;
      object-fit: cover;
      border-radius: 50%;
      overflow: hidden;
    }
  }

  .notification-item-content {
    flex: 1;
    padding: 1rem 0;
    display: flex;
    border-bottom: 1px solid #e5eefc;

    .content {
      line-height: 1.5;

      & > h3 {
        margin: 0;
        font-size: 16px;
      }

      & > p {
        font-size: 16px;
        margin: 10px 0;
      }

      a {
        padding: 0 5px;
        color: $color-accent;
        text-decoration: underline;
      }

      & .accent-text {
        color: $color-accent;
      }

      & .publish-date {
        font-size: 14px;
        color: rgba(66, 101, 159, .3);
      }

      .actions {
        margin-top: 15px;

        .btn {
          width: 100%;
          max-width: 130px;

          &:last-of-type {
            margin-right: 0;
          }
        }
      }

      .decision {
        padding: 13px;
        max-width: 272px;
        margin-top: 15px;
        border-radius: 4px;
        text-align: center;
        color: $text-declined;
        background-color: $bg-declined;

        &.accepted {
          color: $text-accepted;
          background-color: $bg-accepted;
        }
      }

      .coma-separated-element {
        &:not(:last-of-type) {
          &::after {
            content: ', ';
          }
        }
      }
    }
  }
}
