@import '~common/styles/variables';

@media screen and (max-width: $media-sm) {
  .reset-btn {
    margin-top: 0;
  }

  .reset-form-caption {
    margin: 20px 0 28px;
  }

  .confirm-reset-password-page {
    .auth-form-footer {
      span {
        margin: 0;
        display: block;
      }

      a {
        margin: 15px 0 0;
        display: inline-block;
      }
    }
  }
}
