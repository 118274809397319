@import '~common/styles/variables';

$table-border: #c1c7cd;

.table {
  display: flex;
  flex-direction: column;
  border: 1px solid $table-border;
}

.table-row {
  display: flex;

  &:not(:last-of-type) {
    border-bottom: 1px solid $table-border;
  }
}

.table-cell {
  flex-grow: 1;
  padding: 6px;
  word-break: break-word;
  display: flex;
  flex-direction: column;

  &.bold {
    &:first-of-type {
      font-weight: bold;
    }
  }

  &.cell-width {
    width: 20%;
  }

  &:not(:last-of-type) {
    border-right: 1px solid $table-border;
  }
}
