@import '~common/styles/variables';

.create-application-modal {
  display: flex;
  height: calc(var(--vh, 1vh) * 100);
  padding: 18px 1rem;
  background: $bg-color-default;
  width: 60vw;
  box-shadow: 0 5px 21px 0 rgba(66, 101, 159, .2);
  overflow: hidden;
  cursor: auto;

  .card {
    min-width: 700px;
    max-width: 932px;
  }

  .application-back-link {
    padding: 2rem 0 0 2rem;
    display: inline-block;
  }

  .create-application-form {
    padding: 2rem;
  }

  .content {
    height: 95%;
    overflow: hidden;

    .ps__thumb-y {
      width: 4px;
      background-color: $color-font-main;
    }

    & > .title {
      font: bold 16px/1.25 'Lato', sans-serif;
      margin: 16px 0 24px;
    }
  }
}
