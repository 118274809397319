@media screen and (max-width: 768px) {
  .hoga-netz-recommendation-container {
    flex-direction: column;

    .hoga-netz-recommendation-info {
      width: 100%;
      padding: 0;
    }

    .hoga-netz-recommendation-photo {
      margin-top: 20px;
    }
  }
}