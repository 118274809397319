@import '~common/styles/variables';

$button-color: #fff;
$google-color: #ed412d;
$facebook-color: #3b5998;

.social-buttons {
  display: flex;
  flex-wrap: wrap;
  margin: 12px -6px;

  .social-login-btn {
    margin: 6px;
    padding: 11px 40px;
    border-radius: 4px;
    flex: 1;
    position: relative;
    box-shadow: none;
    font-weight: 400;
    text-align: center;
    text-decoration: none;

    &[disabled] {
      pointer-events: none;
      opacity: .6;
    }

    &.facebook {
      background-color: $facebook-color;

      &:hover {
        background-color: darken($facebook-color, 10%);
      }

      &::before {
        content: url('/images/auth/socials/facebook.svg');
        position: absolute;
        left: 1rem;
      }
    }

    &.google {
      background-color: $google-color;

      &:hover {
        background-color: darken($google-color, 10%);
      }

      &::before {
        content: url('/images/auth/socials/google.svg');
        position: absolute;
        left: 1rem;
        top: 13px;
      }
    }
  }
}

.auth-divider {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  margin: 12px 0;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    background-color: $border-light-color;
    height: 1px;
    width: 100%;
  }

  span {
    position: relative;
    display: block;
    padding: 4px 12px;
    background: $button-color;
    line-height: 1.5;
  }
}
