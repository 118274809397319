@import '~common/styles/variables';

$light-gray: #3b475a;

.list-placeholder {
  display: flex;
  padding: 50px;
  height: 80%;
  align-items: center;
  flex-direction: column;
  position: relative;

  &::before {
    top: 100px;
    left: 20%;
    width: 209px;
    height: 182px;
    content: '';
    display: block;
    position: absolute;
    background: url('/images/shared/not-found-left.png') no-repeat center/cover;
  }

  &::after {
    top: 100px;
    right: 21%;
    width: 168px;
    height: 182px;
    content: '';
    display: block;
    position: absolute;
    background: url('/images/shared/not-found-right.png') no-repeat center/cover;
  }

  .title {
    color: $color-text-dark;
    font-size: 34px;
    letter-spacing: .2px;
  }

  .description {
    font-size: 18px;
    margin-top: 10px;
    width: 350px;
    text-align: center;
    line-height: 1.5;
    z-index: 1;
  }

  .actions {
    margin-top: 20px;

    .btn {
      text-align: center;
    }
  }
}
