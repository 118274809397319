@import '~common/styles/variables';

$link-color-active: #fff;

.main-header {
  .header-mobile-nav {
    display: none;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    .mobile-user {
      margin-right: 25px;
    }
  }

  .header-portal-nav {
    display: none;

    .badge-wrapper {
      width: 52px;
      margin-right: -20px;
      border-left: 1px solid $color-muted;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .mobile-chat-header {
      width: 52px;
      position: relative;
      border-left: 1px solid $color-muted;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;

      .icon-chat {
        font-size: 24px;
      }
    }
  }

  .burger-button {
    display: none;
    height: 52px;
    width: 52px;
    margin-left: -16px;
    border-right: 1px solid $color-muted;
    justify-content: center;
    align-items: center;
  }

  .mobile-burger {
    position: relative;
    z-index: 1;
    width: 28px;
    height: 18px;

    .burger-top,
    .burger-middle,
    .burger-bottom {
      width: 100%;
      height: 2px;
      position: absolute;
      right: 0;
      background-color: $color-font-main;
      transition: all .3s;
    }

    .burger-top {
      top: 0;
    }

    .burger-middle {
      top: calc(50% - 1px);
      transition: opacity .1s;
    }

    .burger-bottom {
      bottom: 0;
    }

    .badge {
      display: inline-block;
      width: 20px;
      color: $link-color-active;
      height: 20px;
      font-size: 14px;
      line-height: 20px;
      overflow: hidden;
      border-radius: 50%;
      text-align: center;
      align-items: center;
      letter-spacing: .3px;
      justify-content: center;
      background: $color-badge;
      position: absolute;
      top: -7px;
      right: -6px;
    }

    &.open {
      .burger-top {
        width: 85%;
        top: 50%;
        transform: rotate(-45deg);
      }

      .burger-middle {
        opacity: 0;
      }

      .burger-bottom {
        width: 85%;
        top: 50%;
        bottom: auto;
        transform: rotate(45deg);
      }
    }
  }

  .headers-links {
    .mobile-lang-switcher {
      display: none;

      .mobile-lang-switcher-title {
        line-height: 1.2;
        letter-spacing: .4px;
        font-size: 20px;
        color: $color-font-main;
        display: inline-block;
        margin: 35px 0 10px;
      }

      .mobile-lang-switcher-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        list-style: none;
      }

      .mobile-lang-switcher-item {
        width: 44px;
        height: 32px;
        border-radius: 2px;
        padding: 8px;
        margin-right: 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.active{
          border: solid 1px $border-light-color;
        }

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    .header-copyright {
      display: none;
      order: 4;
      flex-grow: 1;
      align-items: flex-end;
      font-size: 14px;
      line-height: 1.71;
      letter-spacing: .3px;
      color: $color-font-main;
      padding-top: 35px;
    }

    &.open {
      right: 0;
    }
  }

  .mobile-pricing-for-recruiters {
    display: none;
  }
}

.mobile-link-button {
  display: none;
}

@media screen and (max-width: $media-md) {
  .main-header {
    position: relative;
    z-index: 1;

    .logo {
      max-width: calc(100% - 125px);
      padding: 0 5px;

      img {
        max-width: 100%;
      }
    }

    .burger-button {
      display: flex;
    }

    .header-portal-nav {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .header-container {
      padding: 0 5px 0 0;
    }

    .header-mobile-nav {
      display: flex;
    }

    .headers-links {
      flex-direction: column;
      align-items: flex-start;
      position: fixed;
      top: 0;
      right: -120%;
      bottom: 0;
      width: 100%;
      min-height: calc(var(--vh, 1vh) * 100);
      overflow-y: auto;
      background: #fff;
      transition: all .5s;
      padding: 75px 20px 20px;

      .nav-list {
        display: block;

        li {
          .nav-link {
            color: $color-text-dark;
            font-size: 20px;
            letter-spacing: .4px;
            padding: 10px 0;
          }
        }
      }

      .language-switcher {
        display: none;
      }

      .mobile-lang-switcher {
        display: block;
        order: 3;
      }

      .control-panel {
        margin: 0;
        width: 100%;
        order: 2;

        .btn {
          max-width: 280px;
          width: 100%;
          margin-top: 30px;
        }
      }

      .header-copyright {
        display: flex;
      }

      &:before {
        content: 'Menu';
        color: $soft-dark-text;
        font-size: 28px;
        line-height: 1.14;
        letter-spacing: .6px;
        position: absolute;
        top: 10px;
        left: 20px;
      }
    }

    &.mobile-not-found-page-header {
      z-index: 2;
    }

    &.mobile-not-found-page-header-portal {
      .burger-button {
        display: none;
      }
    }
  }

  .portal-container {
    .main-header {
      .mobile-burger {
        z-index: 0;
      }
    }
  }

  .portal-container {
    .main-header.mobile-portal-guest {
      .mobile-burger {
        z-index: 1;
      }
    }
  }

  .ring-bell {
    width: auto;
    border: 0 none;

    & .badge {
      top: 8px;
      right: 6px;
      font-size: 14px;
      line-height: 20px
    }
  }

  .mobile-header-hide {
    z-index: 0;
  }

  .mobile-pricing-for-recruiters {
    margin-left: 7px;
    letter-spacing: .42px;
    color: $color-font-main;
  }

  .mobile-link-button-hide {
    display: none;
  }

  .mobile-link-button {
    display: inline;
  }
}
