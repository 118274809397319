@import '~common/styles/variables';

$background-white: #fff;
$dropdown-shadow: rgba(80, 95, 121, .3);

.outside {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.languages-dropdown-container {
  position: relative;

  .languages-dropdown {
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    transition: all 0.3s;
    border: 1px solid transparent;

    &:hover {
      border: 1px solid #B2CBF4;
    }
  }

  .filter-label-value {
    display: inline-block;
    width: 28px;
    height: 16px;
    margin-right: 8px;
  }

  .filter-label-img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  .filter-label-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
  }

  .filter-container {
    .filter-label-icon {
      &:after {
        position: static;
      }
    }
  }

  .filter-multiple {
    display: flex;
    min-height: 46px;
    align-items: center;
    white-space: pre-wrap;
    padding: 6px 35px 6px 6px;

    .chips-list {
      .default-label {
        padding-left: 16px;
      }
    }
  }

  .languages-options {
    top: 140%;
    left: 0;
    width: 100%;
    z-index: 990;
    min-width: 60px;
    padding: 12px 0;
    overflow-y: auto;
    max-height: 300px;
    position: absolute;
    border-radius: 4px;
    background: $background-white;
    border: solid 1px $color-muted;
    box-shadow: 0 8px 15px 0 $dropdown-shadow;

    .languages-list {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        display: flex;
        cursor: pointer;
        min-height: 40px;
        align-items: center;
        justify-content: center;

        &.active,
        &:hover {
          background-color: $color-muted;
        }
      }
    }
  }
}
