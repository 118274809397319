@import '~common/styles/variables';

.platform-container {
  display: flex;
  padding: 100px 0;
  align-items: center;
  flex-direction: column;

  .platform-title,
  .platform-colons-title {
    font: normal 42px/1.24 'Lato', sans-serif;
    width: 65%;
    color: $color-text-dark;
    text-align: center;
    margin-bottom: 15px;
  }

  .platform-description {
    font: normal 18px/1.33 'Lato', sans-serif;
    color: $color-font-dark;
    padding: 0 50px;
    letter-spacing: .4px;
    line-height: 28px;
  }

  .platform-content {
    display: flex;
    max-width: 1180px;
    margin-top: 40px;

    .platform-image,
    .platform-description {
      width: 50%;
    }

    .platform-image {
      position: relative;

      img {
        width: 481px;
        height: 297px;
        position: relative;
        box-shadow: 0 6px 19px;
      }
    }
  }

  .platform-colons {
    display: flex;
    margin-top: 60px;
    justify-content: space-between;
    
    &-title {
      text-align: left;
    }

    .platform-description {
      padding: 0;
    }

    &-item {
      flex-basis: 50%;

      &:first-of-type {
        padding-right: 100px;
      }

      &:nth-of-type(2n) {
        padding-left: 45px;
        padding-right: 30px;
      }
    }
  }
}
