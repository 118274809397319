@import '~common/styles/variables';

.image-crop-modal {
  min-width: 50vw;
  max-height: 90vh;
  max-width: 100vw;

  .image-crop-container {
    .cropping-img {
      width: 100%;
      padding: 6px;
      text-align: center;
      border-bottom: #b2cbf4 2px solid;

      .cropping-img-module {
        display: inline-block;
        max-width: 300px;
        max-height: 300px;
      }
    }

    .cropped-img {
      padding: 6px;
      text-align: center;

      .cropped-img-round {
        margin: 0 6px;
        max-width: 260px;
        min-width: 260px;
        max-height: 260px;
        min-height: 260px;
        border-radius: 50%;
        border: 2px solid #6f6f71;
      }

      .cropped-img-square {
        margin: 0 6px;
        max-width: 260px;
        min-width: 260px;
        max-height: 260px;
        min-height: 260px;
        border: 2px solid #6f6f71;
      }
    }

    .button-container {
      width: 100%;
      padding: 6px;
      display: flex;
      justify-content: flex-end;
      background-color: #e5eefc;
    }
  }
}
