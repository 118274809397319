@import '~common/styles/variables';

.general-info-form {
  .salary-type {
    margin-top: 10px;
  }
}

@media screen and (max-width: $media-sm) {
  .general-info-form {
    &.mobile-balance-board-general-info-form {
      min-width: auto;
      width: 100%;
      padding: 0 0 100px;

      .divider {
        margin-bottom: 35px;
      }

      .salary-range-container {
        flex-wrap: wrap;
        margin: 0 0 20px;
      }

      .salary-field-width {
        flex-basis: auto;
        margin: 0;
      }

      .min-salary {
        width: 50%;
        padding-right: 10px;
      }

      .max-salary {
        width: 50%;
        padding-left: 10px;
      }

      .salary-type {
        width: 100%;
        padding-top: 12px;
      }

      .working-time-field-width {
        flex-basis: auto;
        width: 100%;
      }

      .form-buttons {
        width: calc(100% + 40px);
        left: -20px;
        position: fixed;
        margin: 0;
        padding: 10px 40px;
        height: 64px;
        box-shadow: none;
      }
    }
  }
}

@media screen and (max-width: $media-xs) {
  .general-info-form {
    &.mobile-balance-board-general-info-form {
      .min-salary {
        padding-right: 4px;
      }

      .max-salary {
        padding-left: 4px;
      }

      .salary-type {
        .filter-label-value {
          letter-spacing: .33px;
          font-weight: bold;
        }
      }

      .form-buttons {
        display: flex;
        height: auto;
        flex-direction: row;
        padding-bottom: 15px;

        .grey {
          width: 50%;
          margin-right: 8px;
        }

        .btn[type='submit'] {
          width: 100%;
          padding: 11px 10px;
        }
      }

      .available-from {
        max-width: 100%;

        .react-datepicker-wrapper {
          width: 100%;

          .react-datepicker__input-container {
            width: 100%;
          }
        }
      }

      .select__multi-value__label {
        letter-spacing: .29px;
        color: $color-font-dark;
        padding-left: 3px;
      }

      .select__clear-indicator,
      .select__indicator-separator {
        display: none;
      }

      .working-time {
        .working-time-field-width {
          flex-basis: 100%;
        }
      }

      .board-form-field {
        .btn {
          &.element {
            width: 100%;
            margin: 0 0 12px;

            &:last-of-type {
              margin: 0;
            }
          }
        }
      }

      .form-buttons {
        padding-bottom: 10px;
        border-top: 1px solid $color-muted;
      }
    }
  }
}
