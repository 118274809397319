@import '~common/styles/variables';
@import '~rc-slider/assets/index.css';

$slider-background: #d7f0ae;

.default-filter-title {
  font: bold 28px/1 'Lato', sans-serif;
  padding: 28px 30px;
  margin: 0;
}

.default-filter {
  padding: 0 20px 0 30px;
  margin-right: 10px;

  &.scrollbar-container {
    height: calc(var(--vh, 1vh) * 100 - 150px);
  }

  .ps__rail-y {
    .ps__thumb-y {
      background-color: $slider-background;
    }

    &:hover {
      background: transparent;

      .ps__thumb-y {
        width: 6px;
      }
    }
  }

  .general-filters {
    margin-bottom: 40px;
    border-bottom: 1px solid $color-muted;
    padding-bottom: 10px;
  }

  .additional-filter {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 30px;
  }

  .location {
    .checkbox-inline {
      padding: 15px 0;
    }
  }

  .form-buttons {
    padding: 40px 0;
    display: flex;
    justify-content: space-between;

    .apply {
      width: 56%;
    }

    .reset {
      width: 40%;
      margin-right: 0;
    }
  }

  .salary-range {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .salary-field-width {
      flex-basis: calc(50% - 10px);
    }

    .min-salary {
      .form-control {
        padding-left: 55px;
      }
    }

    .max-salary {
      .form-control {
        padding-left: 40px;
      }
    }
  }

  .salary-slider {
    padding: 15px 0 40px;

    &.primary {
      .rc-slider-handle {
        width: 16px;
        height: 16px;
        border: solid 5px $color-primary;
        margin-top: -6px;
      }

      .rc-slider-track {
        background-color: $slider-background;
      }
    }
  }

  .date-picker {
    .form-label {
      flex-direction: column;
      align-items: baseline;
    }

    .form-label-title {
      margin-bottom: 0;
    }

    .react-datepicker-wrapper {
      width: 100%;

      .react-datepicker__input-container {
        width: 100%;
      }
    }
  }
}

.default-filter-buttons {
  padding: 10px 30px 40px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 -23px 20px 0 #fff;

  .apply {
    width: 56%;
  }

  .reset {
    width: 40%;
    margin-right: 0;
  }
}
