.empty-search-container {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;

  .header {
    font-weight: 400;
    font-size: 34px;
    line-height: 41px;
    letter-spacing: .71px;
  }

  p {
    margin: 0;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: .37px;
  }

  .image-content {
    display: flex;
    min-width: 560px;
    padding-top: 3rem;
    align-items: center;
    justify-content: space-between;

    .btn {
      margin: 0;
      min-width: 174px;
    }
  }
}
