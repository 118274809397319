@import '~common/styles/variables';

.beta-intro-step2 {
  .tabs-head {
    margin-bottom: 30px;

    @media screen and (max-width: $media-xs) {
      margin-bottom: 40px;
    }
  }

  .tabs-list {
    justify-content: center;
    height: auto;
    border: none;

    li {
      width: 184px;
      height: 40px;
      padding: 12px;

      font-weight: bold;
      letter-spacing: 0.33px;
      text-align: center;

      color: $color-font-main;
      background-color: #FFF;
      border-radius: 4px;
      border: 1px solid $color-font-main;
    }

    @media screen and (max-width: $media-sm) {
      flex-direction: column;

      li {
        width: 100%;
        margin: 0;
        padding: 24px 12px;
      }

      li:first-of-type {
        margin-bottom: 15px;
      }
    }
  }

  .tabs-head .tabs-list.accent {
    li {
      justify-content: center;
    }

    li.active,
    a.active {
      color: #FFF;
      background-color: $color-font-main;

      &::after {
        display: none;
      }
    }
  }

  .tabs-body {
    width: 570px;
    max-width: 100%;
    margin: 0 auto;
    padding: 0;

    @media screen and (max-width: $media-xs) {
      margin-bottom: 15px;
    }
  }

  .tab-header {
    .title {
      font-size: 28px;
      font-weight: normal;
      line-height: 1.15;
      letter-spacing: 0.58px;

      @media screen and (max-width: $media-xs) {
        font-size: 22px;
      }
    }
  }

  .tester-steps-list {
    counter-reset: item;
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    li {
      counter-increment: item;
      position: relative;
      padding-left: 40px;

      font-size: 18px;
      line-height: 1.5;
      text-align: left;

      &:not(:last-of-type) {
        margin-bottom: 12px;
      }

      &:last-of-type:before {
        background-color: #d7f0ae;
      }

      @media screen and (max-width: $media-xs) {
        font-size: 16px;
        line-height: 1.3;
      }
    }

    li:before {
      content: counter(item) ".";
      position: absolute;
      left: 0;

      display: inline-block;
      width: 28px;
      height: 28px;
      flex-shrink: 0;

      font-size: 12px;
      line-height: 2.4;
      text-align: center;

      color: $color-font-main;
      border-radius: 100%;
      background-color: $color-muted;
    }
  }

  .text-bold {
    font-weight: bold;
  }
}
