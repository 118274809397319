@import '~common/styles/variables';

.preview-balance-board-modal {
  display: flex;
  padding: 18px 2rem;
  height: calc(var(--vh, 1vh) * 100);
  background: $bg-color-default;
  width: 60vw;
  box-shadow: 0 5px 21px 0 rgba(66, 101, 159, .2);
  overflow: hidden;
  cursor: auto;

  .scrollbar-container {
    min-width: 700px;
    max-width: 800px;
  }

  .modal-header {
    display: flex;
    padding: 20px;
    height: auto;
    margin-bottom: 1%;
    align-items: flex-end;
    justify-content: space-between;

    & ~ .card {
      min-height: 77%;
      height: auto;
    }

    .left-side {
      .branches-container {
        max-width: 370px;
      }

      & > a {
        padding: 0;
      }
    }

    .right-action > .btn {
      margin: 0;
    }
  }
}

.competencies-wrapper {
  padding: 20px;

  .balance-board-info {
    list-style: none;
    padding: 0;
    margin: 0 0 1rem;

    & > li {
      line-height: 32px;

      & > b {
        color: $color-font-dark;
        margin-right: 10px;
      }
    }
  }
}
