@import '~common/styles/variables';

@media screen and (max-width: $media-sm) {
  .mobile-education-section-container {
    .education-item {
      flex-direction: column;

      .left-info {
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        max-width: 100%;

        p {
          line-height: 1.43;
          letter-spacing: 0.78px;

          &:nth-of-type(2) {
            margin-left: 5px;
          }
        }

        .duration {
          width: 100%;
          margin-top: 0;
        }
      }

      .right-info {
        margin-left: 0;

        .degree {
          margin: 7px 0 0;
          font-size: 16px;
          line-height: 1.5;
          letter-spacing: .89px;
          color: $color-text-dark;
        }

        .school {
          margin-top: 3px;
          font-size: 14px;
          line-height: 1.43;
          letter-spacing: .78px;
          color: $color-text-dark;

          &.link {
            &::after {
              margin-left: 8px;
              display: inline-block;
              content: url('/images/shared/mobile-link.svg');
            }
          }

          &.mobile-school {
            line-height: 1.71;
            letter-spacing: .78px;
            color: $color-font-main;
          }

          &.mobile-education-school {
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: .89px;
            color: $color-font-main;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $media-xs) {
  .mobile-education-section-container {
    width: 100%;

    .empty-section {
      width: 100%;
    }

    .education-item {
      border-bottom: 1px solid $color-muted;

      &:last-of-type {
        border-bottom: 0;
        margin-bottom: 6px;
      }
    }
  }
}
