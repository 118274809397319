@import '~common/styles/variables';

.skills-requirements-form {
  &.skills-count-error {
     & p,
     & .card span.skills-count {
       color: $color-danger;
     }
   }

  &.skills-count-success {
    & .card span.skills-count {
      color: $color-primary;
    }
  }
}


.skills-requirements-form.card-toolbar {
  & .card {
    max-width: 250px;
    min-width: 250px;
    position: absolute;
    z-index: 999;
    top: 30px;
    right: 0;
    height: 40px;
    box-shadow: 0 4px 12px 0 rgba(59, 71, 90, .21);

    & .skills-count {
      color: $color-accent;
    }

    & > p {
      margin: 0;
      padding: 10px 12px;
    }
  }

  .section-title {
    margin-top: 50px;
    text-transform: uppercase;
    font: bold 16px/1.5 'Lato', sans-serif;
  }

  & .languages-list,
  & .software-list,
  & .quialifications-list {
    max-width: 400px;
  }

  .form-buttons {
    margin-top: 40px;

    & .error-text {
      color: $color-danger;
    }
  }

  .element {
    padding: 8px 16px;
    margin-bottom: 20px;
  }

  & .btn.add-more {
    & > img {
      margin-right: 8px;
    }
  }

  .skills-list,
  .documents-list,
  .languages-list,
  .software-list,
  .quialifications-list {
    margin-bottom: 10px;
  }
}
