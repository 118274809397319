@import '~common/styles/variables';

.leadership-container {
  display: flex;
  margin: 80px 0 100px;

  .leadership-title {
    font: normal 34px/1.24 'Lato', sans-serif;
    color: $color-text-dark;
    margin-bottom: 15px;
  }

  .leadership-photo {
    width: 239px;
    position: relative;

    &.image-catherine {
      &::after {
        top: -37px;
        left: -13px;
        width: 91px;
        height: 129px;
        z-index: -1;
        content: '';
        position: absolute;
        background: url('/images/information/plant-right-copy.svg') no-repeat center/cover;
      }
    }

    &.image-joerg {
      &::after {
        bottom: 13px;
        right: -19px;
        width: 74px;
        height: 93px;
        z-index: -1;
        content: '';
        position: absolute;
        background: url('/images/information/humaaan.svg') no-repeat center/cover;
      }
    }
  }

  .leadership-name {
    font: bold 20px/1.24 'Lato', sans-serif;
    color: $color-text-dark;
    margin: 10px 0 5px;
  }

  .leadership-position {
    color: $color-font-main;
    font-size: 14px;
  }

  .leadership-description {
    font: normal 18px/1.33 'Lato', sans-serif;
    width: 80%;
    color: $color-font-main;
    margin-bottom: 60px;
  }

  .leadership-column {
    width: 33.3%;
    height: 620px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
