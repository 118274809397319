@import '~common/styles/variables';

$light-gray: #3b475a;

.statistics-poster {
  display: flex;
  padding: 20px;
  height: 80%;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .title {
    color: $color-text-dark;
    font-size: 24px;
    letter-spacing: 0.2px;
  }

  .description {
    color: $color-font-main;
    opacity: .6;
    font-size: 18px;
    width: 400px;
    text-align: center;
    margin-top: 20px;
    letter-spacing: .2px;
  }

  .poster {
    margin: 30px 0;
    width: 400px;

    img {
      width: 100%;
    }
  }
}
