@import '~common/styles/variables';

.modal {
  top: 0;
  right: 0;
  z-index: 1010;
  position: fixed;
  visibility: visible;
  transition: transform .6s;
  transform: translateX(100vw);

  &.fade {
    top: 40%;
    left: 50%;
    opacity: 0;
    position: fixed;
    max-width: 620px;
    transition: opacity .3s;
    transform: translate(0, 0);
  }

  &.open {
    &.slide {
      transform: translateX(0);
    }

    &.fade {
      opacity: 1;
    }
  }

  .modal-container {

    .modal-content {
      top: 50%;
      left: 50%;
      background: #fff;
      border-radius: 4px;
      position: absolute;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      box-shadow: 0 8px 16px 0 rgba(80, 95, 121, .3);
    }
  }

  &.default-modal {
    width: 90%;
    height: 100%;
    max-width: 1320px;
    background: $bg-color-default;
    padding: 16px 20px 0 20px;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  background: rgba(0, 0, 0, .5);
  cursor: pointer;
  transition: opacity .4s;

  &.overlayed {
    z-index: 1000;
    background: rgba(255, 255, 255, 0.5);
  }

  &.open {
    opacity: 1;
    pointer-events: auto;
  }
}
