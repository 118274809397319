@import '~common/styles/variables';

$search-modal-breakpoint-big: 1660px;
$search-modal-breakpoint-medium: 1160px;

.job-offer-preview-modal {
  display: flex;
  padding: 1rem 30px;
  height: calc(var(--vh, 1vh) * 100);
  background: $bg-color-default;
  width: 60vw;
  box-shadow: 0 5px 21px 0 rgba(66, 101, 159, .2);
  overflow: hidden;
  cursor: auto;
  flex-direction: column;

  & > .card {
    position: relative;
    overflow: hidden;

    &.job-offer-preview-container {
      height: 100%;
      max-width: 900px;

      .job-offer-preview-content {
        height: 100%;
        padding: 10px;

        .job-offer-preview {
          height: calc(100% - 44px);
          margin: 5px;
          display: flex;
        }
      }
    }
  }
}

@media all and (max-width: $search-modal-breakpoint-big) {
  .job-offer-preview-modal {
    width: 70vw;
  }
}

@media all and (max-width: $search-modal-breakpoint-medium) {
  .job-offer-preview-modal {
    width: 95vw;
  }
}
