@import '~common/styles/variables';

$btn-color-active: #fff;

@media screen and (max-width: $media-sm) {
  .tabs-head.mobile-job-offer {
    .tabs-list {
      height: auto;
      border-bottom: 0;
      margin-bottom: 7px;

      li, a {
        width: 50%;
        outline: 0;
        height: auto;
        padding: 10px;
        margin-left: 0;
        font-size: 1rem;
        min-width: 136px;
        font-weight: bold;
        text-align: center;
        border-radius: 4px;
        margin-right: 12px;
        display: inline-block;
        letter-spacing: .33px;
        color: $color-font-main;
        background-color: transparent;
        font-family: 'Lato', sans-serif;
        border: 1px solid $color-font-main;
        transition: background-color .15s;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

        &.active,
        &:hover {
          color: $btn-color-active;
          background: $color-font-main;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }

      &.primary {
        li.active,
        a.active {
          &:not(:only-child) {
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $media-xs) {
  .tabs-head.mobile-job-offer {
    .tabs-list {
      li, a {
        padding: 9px 3px;
        margin-right: 8px;
      }
    }
  }
}
