@import '~common/styles/variables';

$sidebar-color-caption: #7a8cab;
$menu-bg-active: #e5eefc;

.profile-sidebar {
  min-width: 176px;
  margin-right: 20px;

  & > .profile-sidebar-caption {
    text-align: center;
    color: $sidebar-color-caption;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: .89px;
    line-height: 19px;
    width: 100%;
    margin: 22px auto;
  }

  & .profile-menu-list {
    padding: 0;
    margin: 0;
    list-style: none;

    & > li {
      color: $color-accent;
      font-size: 1rem;
      transition: background-color .3s ease;
      padding: 12px;
      border-radius: 5px;
      margin-bottom: 5px;
      cursor: pointer;

      &:hover {
        background: $menu-bg-active;
      }

      &.active {
        background: $menu-bg-active;
      }
    }

    & .signout {
      margin-top: 30px;
    }
  }
}

.profile-modal-content {
  padding: 28px 40px;
  max-width: 700px;
  min-width: 700px;
}
