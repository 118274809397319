@import '~common/styles/variables';

.start-now-container {
  margin: 80px 0;
  height: 376px;
  display: flex;
  position: relative;
  background: $color-muted;
  align-items: center;

  .start-now-content {
    display: flex;
    align-items: center;
    flex-direction: column;

    &::after {
      left: 32%;
      width: 121px;
      height: 163px;
      bottom: 0;
      content: '';
      position: absolute;
      background: url('/images/information/plant-left.svg') no-repeat center/cover;
    }
  }

  .start-now-title {
    font: normal 34px/1 'Lato', sans-serif;
    color: $color-text-dark;
    margin-bottom: 20px;
  }

  .start-now-description {
    font: normal 18px/1.33 'Lato', sans-serif;
    color: $color-font-main;
    width: 40%;
    text-align: center;
    letter-spacing: .4px;
  }

  .start-now-control {
    margin-top: 30px;

    .btn {
      text-align: center;
      width: 184px;
    }
  }
}
