@import '~common/styles/variables';

@media screen and (max-width: $media-xl) {
  .mobile-balance-board-suggest-modal.fade,
  .mobile-balance-board-skill-modal.fade {
    left: 0;
    max-width: 100%;
  }
}

@media screen and (max-width: $media-md) {
  .mobile-balance-board-skill-modal.fade {
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 100%;
    z-index: 10001;

    .modal-content {
      top: auto;
      bottom: 0;
      display: flex;
      flex-direction: column;
      transform: translate(-50%, 0);
      height: calc(var(--vh, 1vh) * 80);
    }

    .overflow-modal-content {
      flex-grow: 1;
    }

    .add-skills-modal-form {
      padding: 20px 20px 10px;
    }

    .add-skills-action {
      padding: 11px 20px;
      height: auto;
      flex-direction: row-reverse;

      .primary {
        width: 156px;
        min-width: auto;
      }

      .grey {
        width: 116px;
        min-width: auto;
        margin-right: 8px;
      }
    }
  }

  .candidate-balance-board-form {
    &.mobile-balance-board-acordeon {
      padding: 0 0 100px;

      .form-buttons {
        width: calc(100% + 40px);
        left: -20px;
        position: fixed;
        bottom: 0;
        margin: 0;
        background: #fff;
        padding: 10px 40px;
        height: 64px;
        box-shadow: none;

        .grey {
          margin-right: 8px;
          min-width: 116px;
        }

        .btn[type='submit'] {
          max-width: 156px;
          padding: 11px 10px;
        }
      }

      .rc-collapse {
        .rc-collapse-item {
          width: calc(100% + 40px);
          position: relative;
          left: -20px;
          padding: 10px 0;
        }

        .rc-collapse-header {
          letter-spacing: .89px;
          color: $color-font-dark;
          margin: 0;
          padding: 10px 20px;
        }

        .add-more {
          margin: 0 12px 12px 0;
        }

        .skills-list {
          margin: 0 0 20px;

          .btn {
            text-align: left;
            margin: 0 8px 8px 0;
          }
        }
      }
    }
  }

  .mobile-balance-board-suggest-modal.fade {
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 100%;
    z-index: 10001;

    .modal-content {
      height: 260px;
      top: auto;
      bottom: 0;
      transform: translate(-50%, 0);
      display: flex;
      flex-direction: column;
    }

    .suggest-skill-modal-form {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    .modal-caption {
      line-height: 1.43;
      letter-spacing: .29px;
      font-size: 14px;
    }

    .form-group {
      flex-grow: 1;
      padding: 20px 20px 0;
    }

    .suggest-skill-modal-form-action {
      padding: 11px 20px;
      height: 64px;
      flex-direction: row-reverse;
      justify-content: flex-end;
      border: none;

      .primary {
        max-width: 300px;
        min-width: auto;
        padding: 11px 10px;
      }

      .grey {
        width: 100%;
        max-width: 116px;
        min-width: auto;
        margin-right: 8px;
        padding: 11px 10px;
      }
    }
  }
}

@media screen and (max-width: $media-sm) {
  .mobile-balance-board-suggest-modal.fade {
    .modal-content {
      width: 100%;
      min-width: auto;
      height: calc(var(--vh, 1vh) * 100);
    }

    .suggest-skill-modal-form-action {
      height: auto;
      flex-wrap: wrap;

      .primary {
        max-width: 100%;
      }
    }
  }
}

@media screen and (max-width: $media-xs) {
  .candidate-balance-board-form {
    &.mobile-balance-board-acordeon {
      .form-buttons {
        height: auto;
        display: flex;
        padding: 10px 36px;
        border-top: 1px solid $color-muted;

        .btn {
          width: 100%;
        }
      }
    }
  }

  .mobile-balance-board-suggest-modal.fade {
    .suggest-skill-modal-form-action {
      border-top: 1px solid $color-muted;

      .primary {
        margin-bottom: 10px;
      }

      .grey {
        max-width: 100%;
        margin-right: 0;
      }
    }
  }
}
