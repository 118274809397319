@import '~common/styles/variables';

.candidate-history-container {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: .29px;

  .content {
    color: $color-font-main;
  }

  .success {
    font-size: 1rem;
    color: $color-primary;
    padding-top: 18px;
  }

  .error {
    color: $color-badge;
  }

  .history-actions {
    padding-top: 2rem;

    p {
      max-width: 240px;
      color: $soft-dark-text;
    }
  }
}