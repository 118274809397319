@import '~common/styles/variables';

@media screen and (max-width: $media-sm) {
  .job-offer-company-info-preview.mobile-job-offer-company-info-preview {
    .restricted {
      img {
        width: 100%;
        height: 100%;
        display: inline-block;
      }

      .cost-job-offer {
        left: 50%;
      }
    }

    .job-address {
      .address {
        padding: 0 21px 0 41px;

        &::before {
          left: 21px;
        }
      }
    }

    .logo-container {
      .hotel-logo {
        margin: 0 21px;
      }
    }

    .gallery-container {
      margin: 0 21px;
    }

    .job-employer-description {
      padding: 0 21px 76px;
    }
  }
}
