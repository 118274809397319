@import '~common/styles/variables';

$item-color: #bdc9df;
$point-background: #EFF9DF;
$chip-employed-background: #d7f0ae;

.empty-placeholder {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  opacity: .7;
  user-select: none;
}

.candidate-row {
  .item-title {
    margin: 0;
    font-size: 1rem;
  }

  .item-description {
    font-size: .875rem;
    color: $item-color;
  }

  .status {
    width: 20%;
  }

  .candidate-avatar {
    width: 10%;
  }

  .full-name,
  .branch {
    width: 29%;
  }

  .chip {
    text-align: center;
    text-transform: capitalize;
    width: auto !important;
    padding: 5px 20px;

    &.invited {
      color: $color-accent;
      background-color: $color-muted;
    }

    &.accepted,
    &.employed {
      color: $color-primary;
      background-color: rgba($chip-employed-background, .4);
    }

    &.interviewed {
      color: $color-font-main;
      background-color: $bg-color-default;
    }
  }
}

.candidate {
  display: inline-block;
  width: 28px !important;

  & > img,
  .more {
    width: 44px;
    height: 44px;
    border: 1px solid $color-muted;
    border-radius: 50%;
  }

  .more {
    color: $color-accent;
    float: left;
    display: inline-flex;
    background: $color-muted;
    align-items: center;
    justify-content: center;
    font: normal 14px/1.43 'Lato', sans-serif;
  }
}
