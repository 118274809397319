@import '~common/styles/variables';

$background-white: #fff;
$muted-input-color: #bdc9df;
$input-color-muted: #bdc9df;

.form-control {
  &:not([type='checkbox']) {
    &:not([type='radio']) {
      position: relative;
      border-radius: 4px;
      background-color: $background-white;
      border: solid 1px $border-light-color;
      width: 100%;
      padding: 12px 16px;
      font-size: 1rem;
      color: $color-font-dark;
      font-weight: 600;
      font-family: 'Lato', sans-serif;
      transition: border-color .15s;

      &::placeholder {
        font-weight: normal;
        color: $muted-input-color;
        opacity: 1;
      }

      &:hover,
      &:focus {
        border-color: $color-accent;
        outline: none;
      }
    }
  }

  &.textarea {
    resize: none;
    width: 100%;
  }
}

.form-label {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  position: relative;

  &.disabled {
    opacity: .6;
    pointer-events: none;
  }

  .form-label-title {
    line-height: 1.5;
    vertical-align: middle;
    margin-right: 8px;
    margin-bottom: 10px;
    min-height: 24px;
  }

  .notes {
    line-height: 1.5;
    color: $input-color-muted;
    margin-bottom: 10px;

    &.sticky-notes {
      position: absolute;
      z-index: 99;
      left: 1rem;
      top: 45px;
    }
  }

  .label-error {
    width: 100%;
    color: $color-danger;
    display: none;
    font-size: .875rem;

    span {
      position: absolute;
      left: 0;
      right: 0;
    }
  }

  &.error {
    .label-error {
      display: block;
    }

    input {
      &:not([type='checkbox']) {
        &:not([type='radio']) {
          border-color: $color-danger;
        }
      }
    }
  }

  &.icon {
    &::after {
      content: url('/images/shared/done-icon.png');
      display: inline-block;
      width: 24px;
      height: 24px;
      position: absolute;
      right: 46px;
      top: 48px;
    }
  }

  & .eye-icon > img {
    width: 19px;
    position: absolute;
    top: 47px;
    right: 1rem;
    cursor: pointer;
  }
}
