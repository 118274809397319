@import '~common/styles/variables';

@media screen and (max-width: $media-lg) {
  .jumbotron-container {
    .information-container {
      .title {
        font-size: 36px;
      }

      .description {
        font-size: 20px;
      }
    }

    .search-container {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: $media-sm) {
  .jumbotron-container {
    .information-container {
      .title {
        font-size: 32px;
      }

      .description {
        font-size: 18px;
      }
    }

    .search-container {
      .search-tabs {
        .tab {
          font-size: 18px;
          height: 48px;

          &.active {
            height: 52px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $media-xs) {
  .jumbotron-container {
    height: auto;
    background: url('/images/landing/landing-mobile.jpg') no-repeat top 20% right 30%/auto 50%;

    & > .container {
      padding: 0;
    }

    .information-container {
      padding: 77px 0 0;

      .title {
        font-size: 28px;
        padding: 0 20px;
      }

      .description {
        width: 70%;
        padding: 0 20px;
      }
    }

    .search-container {
      padding: 65px 0 0;

      .form-label {
        margin-bottom: 8px;
      }

      .search-content {
        padding: 28px 12px 20px;
      }
    }
  }
}
