@import '~common/styles/variables';

@media screen and (max-width: $media-md) {
  .mobile-chat-user {
    .information {
      order: 1;
      text-align: left;
      margin-left: 20px;
    }
  }
}
