.post-list-container {

  .post-list {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
  }

  .list-placeholder-container {
    width: 100%;
    font-size: 1.4rem;
    text-align: center;
  }
}
