@import '~common/styles/variables';

.cv-section-container {
  margin-bottom: 1rem;

  .section-title {
    margin: 0;
    line-height: 2.5;
  }
  .cv-section-content {
    display: flex;
    padding: 12px;
    border-radius: 4px;
    align-items: flex-start;
    justify-content: space-between;
    border: 1px solid $color-muted;
  }

  .edit-icon-container {
    width: 32px;
    height: 32px;
    position: relative;
    border-radius: 4px;
    display: inline-block;
    cursor: pointer;
    border: 1px solid $color-muted;

    &::after {
      top: 0;
      left: 3px;
      position: absolute;
      display: inline-block;
      content: url('/images/shared/edit.svg');
    }
  }
}
