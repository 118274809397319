@import '~common/styles/variables';

.apply-btn-container {
  width: 100%;
  display: flex;
  margin-right: 10px;
  margin-bottom: 10px;

  .custom-btn {
    min-width: 40px;
  }
}

.btn {
  text-align: center;

  &.applied {
    color: #70b53a;
    opacity: 1;
    background: rgba(215, 240, 174, .4);
  }
}

.interact-button {
  flex-basis: 100%;
  padding: 11px;
}

.rc-tooltip.apply-blocked-info {
  max-width: 350px;
}
