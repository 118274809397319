@import '~common/styles/variables';

.pwa-container {
  display: flex;
  padding: 50px 0;
  border-bottom: 1px solid #bdc9df;

  .pwa-left-container {
    width: 60%;
    padding-top: 50px;
    padding-right: 10%;
  }

  .pwa-right-container {
    width: 40%;

    img {
      width: 100%;
    }
  }

  .pwa-title {
    font: normal 34px/1.24 'Lato', sans-serif;
    color: $color-text-dark;
    margin-bottom: 15px;
  }

  .pwa-description {
    font: normal 18px/1.33 'Lato', sans-serif;
    color: $color-font-main;
    margin-bottom: 60px;
  }

  .pwa-steps {
    font: normal 18px/1.56 'Lato', sans-serif;

    .pwa-step {
      position: relative;
      padding-left: 40px;
      margin-bottom: 20px;

      &::before {
        top: 2px;
        left: 0;
        width: 26px;
        height: 26px;
        display: flex;
        position: absolute;
        font-size: 12px;
        background: $color-muted;
        align-items: center;
        border-radius: 50%;
        justify-content: center;
      }

      @for $i from 1 through 4 {
        &:nth-child(#{$i}) {
          &::before {
            content: $i + '.';
          }
        }
      }

      &:last-child {
        &::before {
          background: #d7f0ae;
        }
      }
    }
  }
}
