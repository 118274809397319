@import '~common/styles/variables';

$menu-bg-active-accent: #e5eefc;
$menu-bg-active-primary: rgba(112, 181, 58, .08);

.sidebar-item {
  font-size: 1rem;
  transition: background-color .3s ease;
  padding: 12px;
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  display: block;

  &:hover {
    text-decoration: none;
  }

  &.primary {
    color: $color-primary;

    &:hover,
    &.active {
      background-color: $menu-bg-active-primary;
    }
  }

  &.accent {
    color: $color-accent;

    &:hover,
    &.active {
      background-color: $menu-bg-active-accent;
    }
  }
}
