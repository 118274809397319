@import '~common/styles/variables';

.black-list-container {
  margin-top: 40px;

  & .black-list {
    padding: 0;
    margin: 0;
    list-style: none;

    & > .black-list-item {
      width: 100%;
      display: flex;
      padding: 11px 0;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $color-muted;

      & > .inline {
        font-weight: normal;
      }
    }

    & > .black-list-item:first-of-type {
      border-top: 1px solid $color-muted;
    }

    & .empty-list {
      margin-top: 5rem;
      text-align: center;
    }
  }
}
