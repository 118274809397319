@import '~common/styles/variables';

@media screen and (max-width: $media-xl) {
  .mobile-cv-modal {
    width: 70vw;
  }
}

@media screen and (max-width: $media-lg) {
  .mobile-cv-modal {
    width: 95vw;
  }
}

@media screen and (max-width: $media-md) {
  .mobile-cv-modal {
    width: 100vw;

    .cv-content {
      max-width: 100%;
    }

    .content {
      width: 100%;
      min-width: auto;
      max-width: 100%;
    }
  }
}

@media screen and (max-width: $media-sm) {
  .mobile-cv-modal {
    padding: 0;

    .cv-content {
      margin-bottom: 0;
    }

    .card {
      min-height: auto;
    }
  }
}

@media screen and (max-width: $media-xs) {
  .mobile-daily-modal-container {
    .daily-content {
      padding-top: 0;
    }

    .lifestyle-caption {
      h4 {
        font-size: 18px;
        line-height: 1.33;
        letter-spacing: 1px;
      }
    }

    .lifestyle-field {
      flex-direction: column;

      .form-group {
        margin-bottom: 10px;
      }

      .points {
        margin: 0 0 24px;
      }
    }

    .mobile-actions {
      bottom: 0;
      display: flex;
      box-shadow: none;
      position: relative;
      padding: 20px 18px 0;
      margin: 10px 0 0 -20px;
      min-width: calc(100% + 40px);
      border-top: 1px solid $color-muted;

      .btn {
        white-space: nowrap;

        &:not(.grey) {
          width: 100%;
          padding: 11px 7px;
        }

        &.grey {
          order: 1;
          padding: 11px 5px;
          max-width: 31%;
          margin-right: 10px;
        }
      }
    }
  }
}
