@import '~common/styles/variables';

.cv-modal {
  width: 60vw;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  cursor: auto;
  overflow: hidden;
  padding: 18px 2rem;
  background: $bg-color-default;
  box-shadow: 0 5px 21px 0 rgba(66, 101, 159, .2);

  .content {
    height: 100%;
    max-width: 700px;
    min-width: 700px;
  }

  .cv-content {
    height: auto;
    max-width: 792px;
    margin-bottom: 1rem;
  }

  .cv-modal-actions {
    display: flex;
    padding: 1rem;
  }

  .personal-info-content {
    height: auto;
    width: 100%;
    padding: 24px 28px;
    margin-bottom: 1rem;

    .board-form-field {
      .form-label {
        margin-bottom: 14px;
      }
    }
  }

  .personal-info-form-fields {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }


  .job-title-field {
    max-width: 400px;
  }

  .add-more {
    margin-right: 10px;
  }
}
