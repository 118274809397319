@import '~common/styles/variables';

.plans-container {
  display: flex;
  padding: 100px 0;
  align-items: center;
  flex-direction: column;

  .plans-title {
    font: normal 42px/1.24 'Lato', sans-serif;
    color: $color-text-dark;
    margin-bottom: 15px;
    letter-spacing: .9px;
  }

  .plans-description {
    font: normal 24px/1 'Lato', sans-serif;
    color: $color-font-main;
    width: 45%;
    text-align: center;
    letter-spacing: .5px;
    margin-bottom: 80px;
  }

  .plans-collection {
    display: flex;

    .plan {
      width: 360px;
      height: 497px;
      margin: 0 20px;
      border: 1px solid $color-muted;
      position: relative;
      border-radius: 4px;

      .plan-header {
        font: normal 20px/1 'Lato', sans-serif;
        color: $color-text-dark;
        padding: 17px 0;
        background: $color-muted;
        text-align: center;
        text-transform: uppercase;
      }

      .plan-content {
        .plan-control {
          padding: 20px;
          text-align: center;

          .btn {
            width: 100%;
          }
        }

        .plan-price {
          font: normal 12px/1.71 'Lato', sans-serif;
          color: $color-font-dark;
          margin: 10px;
          text-align: center;
          font-weight: 300;
          letter-spacing: .3px;

          .per {
            left: 5px;
            position: relative;
          }

          .price {
            padding: 0;
            font-size: 36px;
            line-height: 1;
          }

          .currency {
            position: relative;
            top: -18px;
            left: -5px;
          }
        }

        .plan-points {
          font: normal 14px/1.71 'Lato', sans-serif;
          height: 300px;
          padding: 26px;

          .plan-point {
            color: $color-font-dark;
            margin-bottom: 3px;

            &.disabled {
              &::before {
                opacity: .3;
              }
            }

            &::before {
              top: 1px;
              content: url('/images/landing/checkbox-blue.svg');
              position: relative;
              margin-right: 10px;
            }

            &.opacity {
              &::before {
                opacity: .2;
              }
            }
          }
        }
      }
    }
  }
}
