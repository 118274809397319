@import '~common/styles/variables';

.contact-us-modal-container {
  &.modal {
    top: 50%;
  }

  .modal-caption {
    border-radius: 4px 4px 0 0;
  }

  .modal-container {
    .modal-content {
      left: auto;
      min-width: 470px;
    }
  }

  .contact-information {
    display: flex;
    justify-content: space-between;
  }

  .contact-us-description {
    font: 300 20px/1.42 'Lato', sans-serif;
    color: $soft-dark-text;
    width: 75%;
    padding: 0 0 20px;
    display: block;
  }

  .contact-us-modal-form {
    padding: 20px;
  }

  .attachment-files-container {
    .attach-file {
      cursor: pointer;
      font: normal 16px/1 'Lato', sans-serif;
      width: 142px;
      color: $color-font-main;
      height: 36px;
      padding: 8px 30px;
      box-shadow: none;
      border-radius: 4px;
      letter-spacing: .33px;
      background-color: $color-muted;

      &.disabled {
        background: #f4f5f7;
        pointer-events: none;
      }

      &:hover {
        opacity: .7;
      }

      input {
        width: 0;
        height: 0;
        opacity: 0;
      }
    }

    .file-list {
      padding: 20px 0 0;

      .file {
        display: inline-block;
        padding-right: 10px;

        .name {
          color: $color-text-dark;
          display: inline-block;
          overflow: hidden;
          max-width: 220px;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .control {
          top: 5px;
          width: 24px;
          height: 24px;
          display: inline-block;
          position: relative;
          background: url('/images/shared/cancel-s.svg') no-repeat;
        }
      }
    }
  }
}

.contact-us-modal-form-action {
  display: flex;
  padding-top: 14px;

  .btn {
    &.medium {
      min-width: 170px;
    }
  }
}
