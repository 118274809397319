@import '~common/styles/variables';

$status-border-color: #fff;

.avatar-container {
  height: 43px;
  position: relative;
  margin-right: 20px;
  cursor: pointer;

  .avatar {
    width: 44px;
    height: 44px;
    overflow: hidden;
    display: block;
    margin-right: 0;
    border-radius: 50%;
    border: 1px solid $color-muted;
  }

  .status {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    position: absolute;
    bottom: 2px;
    right: 2px;
    background: $soft-dark-text;
    border: 1px solid $status-border-color;

    &.online {
      background: $color-primary;
    }
  }
}
