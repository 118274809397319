@import '~common/styles/variables';

.cookies-modal {
  position: fixed;
  bottom: 0;
  z-index: 1012;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 90px;
  background-color: $color-accent;
  display: none;

  &.flex {
    display: flex;
  }

  .cookies-modal-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    max-width: 1212px;
    padding-right: 16px;
    padding-left: 16px;
    min-width: 768px;

    .cookies-modal-inscription {
      color: #FFFFFF;

      :first-child {
        margin-left: 5px;
        color: #FFFFFF;
        border-bottom: 1px solid #FFFFFF;
      }

      :first-child:hover {
        text-decoration: none;
      }
    }
  }

  .accept-cookies-button {
    border: 1px solid #FFFFFF;
    margin-right: initial;
    margin-left: 20px;
  }
}

@media screen and (max-width: $media-sm) {

  .cookies-modal {
    height: 110px;

    .cookies-modal-container {
      justify-content: center;
      flex-direction: column;
      min-width: initial;

      .cookies-modal-inscription {
        padding-top: 5px;
        text-align: center;
      }

      .accept-cookies-button {
        margin-top: 5px;
        margin-bottom: 5px;
        margin-left: initial;
      }
    }
  }

  @media screen and (max-width: $media-xs) {

    .cookies-modal {
      height: 150px;
    }
  }
}
