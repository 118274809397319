@import '~common/styles/variables';

@media screen and (max-width: $media-sm) {
  .mobile-job-offer-apply-buttons {
    right: 0;
    bottom: 0;
    margin: 0;
    display: flex;
    position: fixed;
    padding: 12px 20px;
    background-color: #fff;
    border-top: 1px solid $color-muted;

    .btn {
      max-width: 280px;
    }
  }

  .mobile-job-offer-skill-button {
    display: none;
  }
}

@media screen and (max-width: $media-xs) {
  .mobile-job-offer-apply-buttons {
    .btn {
      order: 0;
      max-width: 100%;

      &.primary {
        margin-right: 10px;
      }
    }
  }
}

