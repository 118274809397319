@import '~common/styles/variables';

@media screen and (max-width: $media-md) {
  .mobile-modal-header {
    position: relative;

    .modal-header-back-link {
      top: 20px;
      right: 20px;
      font-size: 0;
      position: absolute;
    }

    .mobile-close-btn {
      display: block;
      font-size: 24px;
      line-height: 14px;
      color: $color-font-main;
    }

    h4 {
      margin: 0;
    }

    &.card {
      min-height: auto;
    }
  }
}

@media screen and (max-width: $media-md) {
  .mobile-modal-header {
    &.mobile-no-space {
      padding: 32px 20px 0;
      margin-bottom: 0;
      box-shadow: none;
    }

    &.mobile-work-experience {
      .modal-header-caption {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        h4 {
          margin-bottom: 15px;
        }

        .btn {
          display: flex;
          white-space: nowrap;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .modal-header-caption {
      font-size: 24px;
      line-height: 1.17;
      letter-spacing: .2px;
      color: $color-text-dark;

      h4 {
        font-weight: 400;
      }
    }
  }
}

@media screen and (max-width: $media-xs) {
  .mobile-modal-header {
    padding: 20px;

    &.mobile-work-experience {
      .modal-header-caption {
        .btn {
          width: 100%;
        }
      }
    }

    &.mobile-no-margin {
      margin-bottom: 0;
      box-shadow: none;
    }
  }
}
