@import '~common/styles/variables';

.init-password-footer {
  padding: 11px 0;
  border-top: 1px solid #e5eefc;
}

.reset-instructions {
  margin-bottom: 54px;

  & > .email {
    font-weight: bold;
  }
}

.send-again-button {
  &.primary {
    color: $color-primary;
  }

  &.accent {
    color: $color-accent;
  }
}
