@import '~common/styles/variables';

$header-background-color: #fff;

.mobile-lg-hide,
.mobile-md-hide,
.mobile-sm-hide,
.mobile-xs-hide {
  display: block;
}

.mobile-md-show,
.mobile-chat-channel-back {
  display: none;
}

@media screen and (max-width: $media-md) {
  .mobile-md-hide {
    display: none;
  }

  .mobile-md-show {
    display: block;
  }

  .mobile-portlet-navs-tabs {
    .btn {
      border: 1px solid $color-font-main;
      color: $color-font-main;
      letter-spacing: .33px;
      padding: 10px;
      min-width: 136px;

      &.active,
      &:hover {
        background: $color-font-main;
        color: #fff;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .mobile-chat-channel-back {
    display: block;
    font-size: 24px;
    line-height: 14px;
    color: $color-font-main;
  }
}

@media screen and (max-width: $media-sm) {
  .portlet-head {
    padding: 30px 20px 0;
    border: 0 none;
    min-height: 60px;
    height: auto;
  }

  .portlet-title {
    h2 {
      font-size: 24px;
      line-height: 1.17;
      letter-spacing: .2px;
      color: $color-text-dark;
      text-transform: none;
      margin: 0;
    }
  }

  .mobile-portlet-header-tabs {
    flex-wrap: wrap;
    min-height: auto;
    padding-bottom: 30px;
    box-shadow: inset 0 -1px 0 0 $color-muted;
  }

  .mobile-portlet-navs-tabs {
    width: 100%;
    padding-top: 20px;
    display: flex;
    white-space: nowrap;

    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .mobile-channel-head {
    top: -52px;
    z-index: 2;
    width: 100%;
    position: absolute;
    padding: 10px 20px 8px;
    justify-content: space-between;
    box-shadow: $box-shadow-mobile;
    background-color: $header-background-color;
  }

  .mobile-sm-hide,
  .mobile-filter-hide {
    display: none;
  }
}

@media screen and (max-width: $media-xs) {
  .mobile-portlet-navs-tabs.german {
    display: flex;
    flex-direction: column;

    .mobile-link-btn {
      margin: 0 0 12px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
