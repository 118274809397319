@import '~common/styles/variables';

@media screen and (max-width: $media-sm) {
  .change-password-form {
    .form-btn-submit {
      padding-top: 0;
    }

    & > .half-width {
      width: 100%;
      max-width: 340px;
    }
  }
}

@media screen and (max-width: $media-xs) {
  .change-password-form {
    .form-btn-submit {
      .btn {
        width: 280px;
        max-width: 100%;
        margin-right: 0;
      }
    }
  }
}
