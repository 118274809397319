@import '~common/styles/variables';

.main-cv-container {
  .content {
    .ps__thumb-y {
      width: 4px;
      background-color: $color-font-main;
    }

    & > .title {
      font: bold 16px/1.25 'Lato', sans-serif;
      margin: 16px 0 24px;
    }
  }
}
