@import '~common/styles/variables';

$border-color: #6f6f71;
$spinner-border-width: 4px;
$spinner-border-color: #f4f5f7;
$spinner-active-border-color: #858688;


@keyframes spin {
  from { transform: rotate(0); }
  to { transform: rotate(360deg); }
}

.avatar {
  width: 92px;
  height: 92px;
  display: block;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 28px;
  border: 1px solid $border-color;

  & > img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
  }

  &.loading {
    opacity: .5;
    display: flex;
    position: relative;
    align-items: center;
    pointer-events: none;
    justify-content: center;

    &:after {
      content: '';
      width: 20px;
      height: 20px;
      display: block;
      position: absolute;
      border-radius: 50%;
      animation: spin 1s linear infinite;
      border: $spinner-border-width solid $spinner-border-color;
      border-top: $spinner-border-width solid $spinner-active-border-color;
      border-bottom: $spinner-border-width solid $spinner-active-border-color;
    }
  }
}
