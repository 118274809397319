@import '~common/styles/variables';

.preview-item {
  width: 100px;
  margin-right: 12px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  &-close-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &-img {
    width: 76px;
    overflow: hidden;
    opacity: .5;
    border-radius: 5px;
    cursor: pointer;

    &.active {
      opacity: 1;
    }

    & > img {
      width: 100%;
      height: 60px;
      border-radius: 11px;
      max-width: 100%;
      object-fit: contain;
      transition: opacity .2s;

      &:hover {
        opacity: .4;
      }
    }
  }

  &.loading > &-img > img {
    opacity: .4;
  }

  &-loaded {
    font-size: 10px;
    font-weight: normal;
    display: block;
    float: right;
  }
}

.image-error {
  display: block;
  font-size: 14px;
  color: $color-danger;
}
