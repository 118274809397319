.candidate-info-form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  & > .form-btn-submit {
    width: 100%;
    padding-top: 60px;
    display: flex;
    justify-content: space-between;
  }

  .profile-activity-container {
    .radio {
      padding-right: 10px;
    }

    p {
      margin: 0;
      line-height: 1.4;
    }
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #b2cbf4;
}

.zip-code {
  padding-top: 1rem;
}

.restriction-info {
  position: absolute;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.43;
  letter-spacing: .4px;
  margin: 12px 0 0;
  color: #7a8cab;
}

.candidate-avatar.candidate-content {
  .upload-avatar-container > .avatar {
    border: none;
  }

  div {
    padding-top: 0;

    & .avatar-error {
      top: 75px;
    }
  }
}

.candidate-birthday {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div {
    flex: 3;
    margin-right: 12px;
  }

  & > label.form-label {
    flex: 2;

    &:nth-last-of-type(2) {
      flex: 1;
      min-width: 60px;
      margin-right: 12px;
    }
  }

  & .label-error {
    font-size: 12px;
  }
}
