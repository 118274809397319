@import '~common/styles/variables';

@media screen and (max-width: $media-sm) {
  .start-now-container {
    margin: 30px 0;
    min-height: 260px;
    height: auto;
    padding: 30px 0;
    overflow: hidden;

    .start-now-content {
      &::after {
        z-index: 1;
        bottom: -5px;
        left: 28%;
        width: 94px;
        height: 111px;
      }
    }

    .start-now-title {
      font-size: 28px;
      line-height: 1.14;
      letter-spacing: .6px;
      margin-bottom: 10px;
    }

    .start-now-description {
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: .3px;
      width: 100%;
      max-width: 280px;
    }

    .start-now-control {
      .btn {
        position: relative;
        z-index: 2;
      }
    }
  }
}

@media screen and (max-width: $media-xs) {
  .start-now-container {
    .start-now-title {
      margin-bottom: 15px;
    }

    .start-now-control {
      .btn {
        margin: 0 auto;
        min-width: 260px;
        max-width: 100%;
        width: auto;
      }
    }
  }
}
