@import '~common/styles/variables';
@import '~react-datepicker/dist/react-datepicker.css';

$background-white: #fff;
$muted-input-color: #bdc9df;
$datepicker-border-color: #e5eefc;
$datepicker-day-names-bg: #f4f5f7;
$datepicker-day-names-color: #3b475a;
$datepicker-current-month-bg: #505f79;
$datepicker-shadow-color: rgba(80, 95, 121, .3);

.date-picker {
  input {
    border-radius: 4px;
    background-color: $background-white;
    border: solid 1px $border-light-color;
    width: 100%;
    padding: 12px 16px;
    font-size: 1rem;
    color: $color-font-dark;
    font-weight: 600;
    font-family: 'Lato', sans-serif;
    transition: border-color .15s;

    &::placeholder {
      font-weight: normal;
      color: $muted-input-color;
      opacity: 1;
    }

    &:hover,
    &:focus {
      border-color: $color-accent;
      outline: none;
    }
  }

  .popper-container {
    z-index: 999 !important;
  }
}

.react-datepicker {
  display: flex;
  z-index: 9999;
  border-radius: 4px;
  font-family: "Lato", sans-serif;
  background-color: $background-white;
  border: 1px solid $datepicker-border-color;
  box-shadow: 0 8px 16px 0 $datepicker-shadow-color;

  .react-datepicker__header {
    padding: 0;
    border: none;

    .react-datepicker__year-dropdown-container {
      top: 18px;
      right: 106px;
      color: #B2CBF4;
      position: absolute;
      font-size: 16px;

      .react-datepicker__year-dropdown {
        left: -40px;
        min-width: 100px;
        border-radius: 0;
        background: $datepicker-current-month-bg;
        border: 1px solid $datepicker-border-color;
        box-shadow: 0 8px 16px $datepicker-shadow-color;

        .react-datepicker__year-option {
          padding: 5px 0;
          font-size: 14px;
          font-weight: normal;

          &:hover {
            background: darken($datepicker-current-month-bg, 5)
          }
        }

        .react-datepicker__navigation--years-upcoming {
          top: 0;
          margin-bottom: 1rem;
        }
      }

      .react-datepicker__year-read-view--selected-year {
        opacity: 0;
      }

      .react-datepicker__year-read-view--down-arrow {
        display: none;
      }
    }

    .react-datepicker__current-month {
      color: #fff;
      padding: 20px 0;
      font-weight: normal;
      font-family: Lato, sans-serif;
      background: $datepicker-current-month-bg;
    }

    .react-datepicker__day-names {
      padding: 9px 30px;
      color: $datepicker-day-names-color;
      background-color: $datepicker-day-names-bg;

      .react-datepicker__day-name {
        font-size: 16px;
        color: $datepicker-day-names-color;
      }
    }
  }

  .react-datepicker__month {
    .react-datepicker__week {
      .react-datepicker__week-number {
        color: $color-font-main;
      }

      .react-datepicker__day {
        font-weight: normal;
        color: $datepicker-current-month-bg;

        &:hover {
          border-radius: 50%;
        }

        &.react-datepicker__day--today {
          border-radius: 50%;
          background: $color-muted;
        }

        &.react-datepicker__day--selected {
          color:#fff;
          border-radius: 50%;
          background: $color-primary;
        }
      }
    }
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__navigation {
    top: 20px;
  }
}

.date-picker.recruiter {
  .react-datepicker {
    .react-datepicker__month {
      .react-datepicker__week {
        .react-datepicker__day {
          &.react-datepicker__day--selected {
            background: $color-accent;
          }
        }
      }
    }
  }
}

.react-datepicker__input-container {
  &::after {
    position: absolute;
    right: 14px;
    bottom: 10px;
    content: url('/images/shared/calendar.svg');
  }
}
