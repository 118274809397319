@import '~common/styles/variables';

.match-chart-container {
  position: relative;
  margin-top: 30px;
  min-height: 265px;

  .chart-container {
    height: 250px;
    display: flex;
    align-items: center;
    font-weight: bolder;
    justify-content: center;
  }

  .title-values {
    top: 0;
    position: absolute;
    z-index: 10;
    width: 58px;
    font-size: 12px;
    opacity: 0.4;
    left: -22px;
    text-align: right;
  }

  .title-points {
    bottom: -13px;
    z-index: 10;
    width: 51px;
    font-size: 12px;
    opacity: 0.4;
    position: absolute;
    right: -20px;
  }

  .highcharts-tooltip > span {
    padding: 3px 5px;
    font-size: 16px;
  }

  .rc-slider-handle {
    width: 16px;
    height: 16px;
    border: solid 5px $color-accent;
    margin-top: -6px;

    &:hover,
    &:focus,
    &:active {
      border-color: $color-accent;
      box-shadow: none;
    }
  }

  .rc-slider-track {
    background-color: $border-light-color;
  }

  .slider-container {
    top: -5px;
    width: 83%;
    padding: 0 4px;
    position: relative;
    min-height: 14px;
    margin-left: 51px;

    .rc-slider-step {
      background-color: #B7CBF0;
    }

    .rc-slider-dot {
      display: none;
    }

    .rc-slider-mark-text {
      color: $soft-dark-text;

      &.rc-slider-mark-text-active {
        color: $color-accent;

        &::after {
          content: ' %';
        }
      }
    }
  }
}
