@import '~common/styles/variables';

.application-row {
  td .cell.action-cell {
    display: flex;
    justify-content: flex-end;
  }

  .menu-button {
    width: 34px;
    height: 34px;
    border: 1px solid $border-light-color;
    padding: 0;

    & > img {
      top: 1px;
      width: 18px;
      height: 18px;
      position: relative;
    }
  }

  .item-cell {
    padding: 10px;

    .item-text {
      font-size: 14px;
    }

    .item-title {
      font-weight: bold;
      margin-bottom: 4px;
      color: $color-font-dark;
    }
  }
}
