@import '~common/styles/variables';

.mobile-delete-btn-show {
  display: none;
}

@media screen and (max-width: $media-md) {
  .mobile-workspace-container {
    .workspace-form-content {
      .job-title-field {
        max-width: calc(50% - 10px);
      }
    }
  }
}

@media screen and (max-width: $media-xs) {
  .mobile-workspace-container {
    .workplace-caption {
      h4 {
        font-size: 18px;
        line-height: 1.33;
        letter-spacing: 1px;
      }
    }

    .workspace-form-content {
      .job-title-field {
        max-width: 100%;
      }
    }

    .from-date {
      flex-direction: column;

      .from-date-field {
        width: 100%;

        &:last-of-type {
          margin-top: 24px;
        }
      }
    }

    .mobile-delete-btn-hide {
      display: none;
    }

    .mobile-delete-btn-show {
      display: block;
    }

    .delete-btn {
      padding: 7px 8px;
    }
  }
}
