@import '~common/styles/variables';

.mobile-btn-show,
.mobile-filter-show {
  display: none;
}

@media screen and (max-width: $media-sm) {
  .portlet {
    width: 100%;
    min-height: calc(var(--vh, 1vh) * 100 - 52px);
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .mobile-filter-show {
    display: flex;
    padding: 12px 20px;
    align-items: center;
    justify-content: center;

    .btn {
      min-width: 280px;
    }
  }

  .mobile-btn-show {
    display: flex;
    padding: 12px 20px;

    .btn {
      min-width: auto;
      white-space: nowrap;
      justify-content: flex-start;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: $media-xs) {
  .mobile-btn-show {
    .btn {
      width: 50%;
      padding: 11px;
    }
  }
}

@media screen and (max-width: $media-md) and (max-height: $media-xs) {
  .portlet {
    overflow: auto;
  }
}
