@import '~common/styles/variables';

.beta-intro-step1 {
  &-image {
    width: 293px;
    max-width: 100%;
    height: 240px;
    margin: 0 auto;
    margin-bottom: 18px;
  }

  &-title {
    margin-top: 0;
    margin-bottom: 8px;

    font-size: 28px;
    font-weight: normal;
    line-height: 1.14;
    letter-spacing: 0.6px;
    color: $color-text-dark;

    @media screen and (max-width: $media-xs) {
      font-size: 22px;
    }
  }

  &-subtitle {
    margin: 0;
    margin-bottom: 20px;
    line-height: 1.25;
    letter-spacing: 0.33px;
    color: $color-font-main;
  }

  .img-responsive {
    display: block;
    width: 100%;
    height: 100%;
  }
}
