@import '~common/styles/variables';

$link-color-active: #fff;
$colot-setting-icon: #7a8caa;

.recruiter-profile-nav-container {
  height: 100%;
  padding: 16px 20px;

  .profile-nav-header {
    display: flex;
    justify-content: space-between;

    .person-container {
      display: flex;
      align-items: center;
      cursor: pointer;

      .avatar-wrapper {
        width: 64px;
        height: 64px;
        border-radius: 32px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      .name-wrapper {
        margin-left: 16px;

        .user-name {
          font-size: 1.125rem;
          margin: 0;
        }

        .user-role {
          font-size: .875rem;
        }
      }
    }

    .icon-settings {
      display: block;
      width: 36px;
      border: none;
      height: 36px;
      border-radius: 4px;
      background-color: $color-muted;
      color: $colot-setting-icon;
      text-align: center;
      line-height: 34px;
      font-size: 20px;
      text-decoration: none;
      cursor: pointer;
      outline: none;
    }
  }

  .profile-nav-body {
    .profile-nav-links.recruiter {
      margin-left: -4px;
      margin-right: -4px;
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      height: 188px;
      align-content: space-between;

      .profile-nav-link {
        margin-left: 4px;
        margin-right: 4px;
        display: block;
        width: 64px;
        text-decoration: none;

        .tile {
          height: 64px;
          border-radius: 4px;
          border: solid 2px $color-muted;
          position: relative;
          text-align: center;
          transition: color .15s, border-color .15s, background-color .15s;

          span {
            font-size: 1.5rem;
            line-height: 64px;
            transition: color .15s;
          }

          .badge {
            top: 2px;
            width: 20px;
            right: 2px;
            color: $link-color-active;
            height: 20px;
            display: flex;
            font-size: 13px;
            overflow: hidden;
            border-radius: 50%;
            text-align: center;
            position: absolute;
            align-items: center;
            letter-spacing: .3px;
            justify-content: center;
            background: $color-badge;
          }
        }

        .tile-title {
          padding: 4px 2px;
          font-size: .75rem;
          text-align: center;
          line-height: 1;
          transition: color .15s;
        }

        &:hover {
          .tile {
            background: $color-muted;
          }
        }

        &:active,
        &.active {
          .tile {
            background: $color-accent;
            border-color: $color-accent;

            span {
              color: $link-color-active;
            }
          }

          .tile-title {
            color: $color-accent;
          }
        }
      }
    }
  }
}
