@import '~common/styles/variables';

@media screen and (max-width: $media-lg) {
  .platform-container {
    .platform-title,
    .platform-colons-title {
      font-size: 34px;
    }

    .platform-colons {
      margin-top: 35px;

      &-item {
        &:first-of-type {
          margin-bottom: 35px;
        }
      }
    }
  }
}

@media screen and (max-width: $media-md) {
  .platform-container {
    padding: 100px 0 70px;

    .platform-title,
    .platform-colons-title {
      font-size: 28px;
    }

    .platform-content {
      flex-wrap: wrap;
      max-width: 100%;

      .platform-image,
      .platform-description {
        width: 100%;
      }

      .platform-image {
        text-align: center;
        margin-bottom: 30px;

        img {
          max-width: 100%;
          height: auto;
          margin: 0 auto;
        }
      }
    }

    .platform-colons {
      flex-wrap: wrap;
      flex-direction: column-reverse;

      &-item {
        flex-basis: 100%;

        &:first-of-type {
          padding: 0 50px;
          margin-top: 35px;
          margin-bottom: 0;
        }

        &:nth-of-type(2n) {
          padding: 0 50px;
        }
      }
    }
  }
}

@media screen and (max-width: $media-sm) {
  .platform-container {
    padding: 90px 0 30px;

    .platform-title,
    .platform-colons-title {
      width: 100%;
      text-align: left;
      line-height: 1.33;
      letter-spacing: .5px;
    }

    .platform-description {
      padding: 0;
    }

    .platform-content {
      margin-top: 15px;

      .platform-image,
      .platform-description {
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: .3px;
      }
    }

    .platform-colons {
      .platform-description {
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: .3px;
      }

      &-item {
        padding: 0;

        &:first-of-type {
          padding: 0;
          margin-top: 40px;
        }

        &:nth-of-type(2n) {
          padding: 0;
        }
      }
    }
  }
}

@media screen and (max-width: $media-xs) {
  .about-us-container{
    .container {
      padding: 0;
    }
  }

  .platform-container {
    .platform-title,
    .platform-colons-title {
      font-size: 24px;
      padding-left: 20px;
      padding-right: 40px;
    }

    .platform-content {
      margin-top: 10px;
      padding-left: 20px;
      padding-right: 20px;

      .platform-image,
      .platform-description {
        color: $color-font-main;
      }

      .platform-image {
        margin-right: -12px;
        margin-left: -12px;
        width: auto;
      }
    }

    .platform-colons {
      .platform-description {
        color: $color-font-main;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}
