@import '~common/styles/variables';

.mobile-skills-requirements-second-b {
  .divider {
    display: none;
  }
}

@media screen and (max-width: $media-xl) {
  .mobile-balance-board-suggest-modal.fade,
  .mobile-balance-board-skill-modal.fade {
    left: 0;
    max-width: 100%;
  }
}

@media screen and (max-width: $media-md) {
  .mobile-balance-board-skill-modal.fade {
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 100%;
    z-index: 10001;

    .modal-content {
      height: calc(var(--vh, 1vh) * 80);
      top: auto;
      bottom: 0;
      transform: translate(-50%, 0);
      display: flex;
      flex-direction: column;
    }

    .overflow-modal-content {
      flex-grow: 1;
    }

    .modal-caption {
      line-height: 1.43;
      letter-spacing: .29px;
      font-size: 14px;
    }

    .add-skills-modal-form {
      padding: 20px 20px 10px;
    }

    .add-skills-action {
      padding: 11px 20px;
      height: 64px;
      flex-direction: row-reverse;
      justify-content: flex-end;

      .primary {
        width: 156px;
        min-width: auto;
      }

      .grey {
        width: 116px;
        min-width: auto;
        margin-right: 8px;
      }
    }
  }
}

@media screen and (max-width: $media-sm) {
  .mobile-balance-board-skill-modal.fade {
    .modal-content {
      min-width: auto;
      width: 100%;
      height: calc(var(--vh, 1vh) * 100);
    }
  }

  .skills-requirements-form {
    &.mobile-skills-requirements-second-b {
      padding: 0 0 100px;

      .divider {
        display: block;
        margin: 30px -20px 30px;
      }

      .section-title {
        line-height: 1.25;
        letter-spacing: .33px;
        margin-top: 0;
        margin-bottom: 25px;
      }

      .mobile-skill-list-buttons {
        max-width: 100%;
        width: 100%;
        margin: 0 0 30px;

        .btn {
          font-size: 14px;
          letter-spacing: 0.29px;
          padding: 8px 15px;
          margin: 0 8px 8px 0;
        }
      }

      .btn {
        padding: 11px 20px;
        white-space: nowrap;
        margin-bottom: 12px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      .form-buttons {
        width: calc(100% + 40px);
        left: -20px;
        bottom: 0;
        background: #fff;
        position: fixed;
        margin: 0;
        padding: 10px 36px 15px;
        box-shadow: none;
        display: flex;
        height: auto;
        flex-direction: row;

        .grey {
          min-width: 116px;
          margin: 0 8px 0 0;
        }

        .btn[type='submit'] {
          max-width: 156px;
          padding: 11px 10px;
        }
      }
    }
  }
}

@media screen and (max-width: $media-xs) {
  .skills-requirements-form {
    &.mobile-skills-requirements-second-b {
      .form-buttons {
        box-shadow: none;
        padding-bottom: 10px;
        border-top: 1px solid $color-muted;

        .btn {
          width: 100%;
        }
      }
    }
  }
}
