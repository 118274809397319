@import '~common/styles/variables';

.create-application-modal {
  display: flex;
  padding: 18px 2rem;
  height: calc(var(--vh, 1vh) * 100);
  background: $bg-color-default;
  width: 60vw;
  box-shadow: 0 5px 21px 0 rgba(66, 101, 159, .2);
  overflow: hidden;
  cursor: auto;

  .content {
    height: 100%;
    max-width: 700px;
  }

  .modal-header {
    display: flex;
    padding: 20px;
    height: 23%;
    margin-bottom: 1%;
    align-items: center;
    justify-content: space-between;

    .application-caption {
      display: flex;
    }

    & ~ .card {
      height: auto;
      min-height: 77%;
    }

    .chip.light-accent {
      background: rgba($color-accent, .1);
    }

    .left-side > a {
      padding: 0;
    }

    .right-action {
      .btn {
        display: block;
      }

      .btn:first-of-type {
        margin-bottom: 10px;
      }
    }

    .info-icon {
      display: inline-block;
      margin-left: 10px;
    }
  }

  .text-content {
    padding: 20px;
    max-width: 80%;
    font-size: 1rem;
    letter-spacing: .8px;
    line-height: 28px;
  }
}

.rc-tooltip.apply-info {
  max-width: 300px;
  margin-left: 10px;

  .rc-tooltip-content {
    margin-left: 10px;

    .rc-tooltip-arrow {
      top: 44%;
      left: -4px;
      transform: rotate(90deg);
    }
  }
}
