@import '~common/styles/variables';

$background-white: #fff;
$muted-input-color: #bdc9df;
$dropdown-shadow: rgba(80, 95, 121, .3);

.autosuggest-container {
  &.autosuggest-label {
    cursor: pointer;
    position: relative;

    .autosuggest-label-title-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .autosuggest-label-title {
      line-height: 1.5;
      display: inline-block;
      vertical-align: middle;
      margin-right: 8px;
      margin-bottom: 10px;
      min-height: 24px;

      &.error {
        color: $color-danger;
      }
    }
  }

  .react-autosuggest__input {
    &:not([type='checkbox']) {
      &:not([type='radio']) {
        position: relative;
        border-radius: 4px;
        background-color: $background-white;
        border: solid 1px $border-light-color;
        width: 100%;
        padding: 12px 16px;
        font-size: 1rem;
        color: $color-font-dark;
        font-weight: 600;
        font-family: 'Lato', sans-serif;
        transition: border-color .15s;

        &::placeholder {
          font-weight: normal;
          color: $muted-input-color;
          opacity: 1;
        }

        &:hover,
        &:focus {
          border-color: $color-accent;
          outline: none;
        }
      }
    }
  }

  .react-autosuggest__suggestions-list {
    padding: 0;
    margin: 0;
    width: 100%;

    li {
      list-style: none;
      padding: 11px 24px;
      font-weight: bold;
      cursor: pointer;
      color: $color-font-dark;

      &.active,
      &:hover {
        background-color: $color-muted;
      }
    }
  }

  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__suggestions-container--open {
    left: 0;
    right: 0;
    top: 110%;
    z-index: 990;
    padding: 12px 0;
    min-width: 200px;
    overflow-y: auto;
    position: absolute;
    max-height: 300px;
    border-radius: 4px;
    background: $background-white;
    border: solid 1px $color-muted;
    box-shadow: 0 8px 15px 0 $dropdown-shadow;
  }
}
