.auth-form-body {
  padding: 20px 80px;

  &:last-child {
    padding-bottom: 40px;
  }

  .auth-form-title {
    margin: 8px 0 22px;
    font-size: 1.125rem;
    text-transform: uppercase;
    font-weight: normal;
  }
}
