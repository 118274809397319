@import '~common/styles/variables';

.preview-candidate-info-container {
  height: calc(100% - 200px);

  .blured-info {
    height: calc(100% - 66px);
    background-size: contain;
    position: relative;

    &.full {
      background: url("/images/candidate/blur.png") no-repeat top center;

      .restricted-activity {
        top: 25%;
      }
    }

    &.half {
      background: url("/images/candidate/half-blur.png") no-repeat top center;

      .restricted-activity {
        top: 15%;
      }
    }

    .restricted-activity {
      display: inline-block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 80%;
      text-align: center;
      background: $bg-color-default;
      border-radius: 14px;
      padding: 4px 10px;
      color: $color-font-dark;
      box-shadow: 0 1px 3px 0 $box-shadow-color;
      font-size: .875rem;
    }
  }

  .actions-buttons {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    button {
      &:first-child {
        flex: 1;
      }
    }

    .invite-button {
      &:disabled {
        color: $color-font-main;
        opacity: 1;
        box-shadow: none;
        text-transform: capitalize;
        background-color: $color-muted;
      }
    }
  }

  .main-info {
    padding: 20px 0;
  }

  .set-contact-btns {
    display: flex;

    .btn {
      flex: 1;
      margin: 0 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        margin-right: 7px;
      }
    }
  }

  .contact-info {
    padding: 14px 0;
  }

  .extra-actions {
    padding: 16px 0;

    .preview-link {
      text-align: center;
      margin-bottom: 10px;
    }
  }

  .profile-details-modal {

    .full-info-container {
      .tabs-list {
        padding-left: 0;
        padding-right: 0;
      }

      .competencies-wrapper {
        padding: 0;
      }
    }

    .actions-buttons {
      display: flex;
      align-items: center;
      margin-bottom: 25px;

      .external-link {
        flex: 1;
        padding-right: 12px;
      }

      button {
        &:first-child {
          flex: 1;
        }
      }
    }

    .main-info {
      padding: 20px 0;

      h3 {
        margin: 0;
        font-size: 1.375rem;
        color: $color-text-dark;
      }

      .last-online {
        font-size: .875rem;
      }
    }

    .set-contact-btns {
      display: flex;
      margin: 0 -4px;
      padding: 4px 0;

      .btn-container {
        flex: 1;
        padding: 0 4px;

        .btn {
          width: 100%;
        }
      }
    }

    .contact-info {
      padding: 14px 0;

      .contact-item {
        padding: 6px 0;

        .contact-label {
          font-size: .875rem;
        }

        .contact-data {
          font-weight: bold;
          color: $color-text-dark;
        }
      }
    }

    .extra-actions {
      padding: 16px 0;

      & > .btn:not(:last-of-type) {
        margin-bottom: 10px;
      }

      .btn.grey {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

  .fake {
    height: 25%;
    position: relative;
    background: url("/images/shared/user-placeholder.svg") no-repeat top center;

    .restricted-activity {
      top: 50%;
      left: 50%;
      width: 80%;
      padding: 4px 10px;
      font-size: .875rem;
      position: absolute;
      text-align: center;
      border-radius: 14px;
      display: inline-block;
      color: $color-font-main;
      background: $color-muted;
      transform: translateX(-50%);
      box-shadow: 0 1px 3px 0 rgba(122, 140, 171, 0.2);
    }
  }
}
