@import '~common/styles/variables';

@media screen and (max-width: $media-sm) {
  .setting-container {
    max-width: calc(100% + 30px);
    margin: -16px -15px;
    border-radius: 0;
    padding: 25px 20px 40px;
  }

  .setting-caption {
    font-weight: normal;
    line-height: 1.17;
    letter-spacing: .2px;
  }

  .setting-description {
    line-height: 1.5;
    letter-spacing: .13px;
    margin: 0 0 25px;
  }
}
