@import '~common/styles/variables';

.statistics-container {
  font: normal 16px/1.25 'Lato', sans-serif;
  color: $color-font-main;
  margin: 0;
  height: calc(100% - 10px);
  overflow: hidden;

  .statistics-title {
    padding: 20px;
    border-bottom: 1px solid $border-light-color;
  }

  .statistics-items {
    height: calc(100% - 50px);
    padding: 10px 0;

    .ps__thumb-y {
      background-color: $border-light-color;
    }
  }
}
