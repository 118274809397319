@import '~common/styles/variables';

$search-modal-breakpoint-big: 1660px;
$search-modal-breakpoint-medium: 1160px;

.search-modal {
  display: flex;
  padding: 1rem 40px;
  height: calc(var(--vh, 1vh) * 100);
  background: $bg-color-default;
  width: 60vw;
  box-shadow: 0 5px 21px 0 rgba(66, 101, 159, .2);
  overflow: hidden;
  cursor: auto;

  & > .card {
    position: relative;
  }

  .search-modal-content {
    width: 550px;
  }
}

@media all and (max-width: $search-modal-breakpoint-big) {
  .search-modal {
    width: 70vw;
  }
}

@media all and (max-width: $search-modal-breakpoint-medium) {
  .search-modal {
    width: 95vw;
  }
}
