@import '~common/styles/variables';

.mobile-blog-post-count {
  display: none;
}

@media screen and (max-width: $media-xl) {
  .post-item {
    .post-content {
      & > p {
        max-height: 35px;
      }
    }
  }
}

@media screen and (max-width: $media-md) {
  .post-item {
    max-width: calc(50% - 13px);
    min-width: calc(50% - 13px);

    &:last-of-type {
      margin-right: 0;
    }
  }

  .mobile-portlet-body-blog {
    flex-grow: 1;
  }
}

@media screen and (max-width: $media-sm) {
  .portlet-body {
    &.mobile-portlet-body-blog {
      padding: 20px 0;
    }
  }

  .portlet-head {
    &.mobile-portlet-header-blog {
      display: block;

      .portlet-title {
        display: inline-block;
      }
    }
  }

  .mobile-blog-post-count {
    display: inline-block;
    letter-spacing: .2px;
    font-size: 24px;
    color: $color-text-dark;
    margin-left: 7px;
  }

  .post-item {
    max-width: 100%;
    overflow: hidden;
    border: 0 none;
    margin: 0 0 20px;
    padding: 0 20px;

    .post-item-gallery {
      width: 332px;
      flex-wrap: wrap;

      .post-gallery-item {
        width: 108px;
        flex: none;
      }

      .post-gallery-item > img {
        height: 76px;
        width: 100%;
      }

      .post-gallery-item:not(:last-child) {
        margin: 0 4px 0 0;
        flex: none;
      }
    }

    .post-content {
      & ~ .chip {
        position: relative;
        bottom: 0;
        padding: 6px 12px;
      }

      .post-title {
        color: $color-text-dark;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.5;
        letter-spacing: .33px;
        margin: 10px 0 0;
      }

      & > p {
        max-height: 40px;
        margin: 0 0 10px;
        line-height: 1.43;
        letter-spacing: .78px;
      }
    }
  }
}
