@import '~common/styles/variables';

$main-bg: #fff;
$modal-bg: #f4f5f7;

.mobile-company-description-btn {
  display: none;
}

@media screen and (max-width: $media-md) {
  .mobile-general-job-offer-modal-preview {
    .head-job-offer {
      padding: 0 21px 22px 21px;
      background-color: $main-bg;
    }

    .job-title {
      font-weight: 400;
      line-height: 1.17;
      letter-spacing: .2px;
    }

    .additional-info {
      display: block;
    }

    .job-summary-info {
      padding: 18px 21px;
      background-color: $main-bg;
    }
  }
}

@media screen and (max-width: $media-sm) {
  .mobile-general-job-offer-modal-preview {
    background-color: $modal-bg;

    .additional-info {
      display: flex;
    }
  }

  .mobile-company-description-btn {
    display: flex;

    .btn {
      width: 100%;
      margin-right: 0;
      max-width: 280px;
    }
  }
}

@media screen and (max-width: $media-xs) {
  .mobile-general-job-offer-modal-preview {
    .head-job-offer {
      margin-bottom: 8px;
      box-shadow: $box-shadow-mobile;
    }
  }

  .additional-info {
    flex-wrap: wrap;
  }

  .branches {
    &:last-of-type {
      width: 100%;
      margin-top: 6px;
    }
  }

  .mobile-company-description-btn {
    .btn {
      max-width: 100%;
    }
  }
}
