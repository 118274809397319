@import '~common/styles/variables';

.add-skills-modal {
  .modal-content {
    min-width: 620px;
  }

  .overflow-modal-content {
    height: 345px;

    .ps__rail-y {
      right: 10px !important;
    }

    .ps__thumb-y {
      background-color: $border-light-color;
    }
  }
}

.list-content {
  padding: 0;
  margin: 0;

  & .empty {
    margin: 1rem 1rem 2rem;
    text-align: center;
    color: $color-font-main;
  }

  & > .loader-container {
    position: absolute;
    background: transparent;
  }

  & .list-content-item {
    transition: .3s;

    &:hover {
      background: $color-muted;
    }

    & > .checkbox-inline {
      padding: 8px 20px;
    }
  }
}

.add-skills-modal-form {
  padding: 1rem 20px;

  & .form-label-title {
    display: none;
  }
}

.add-skills-action {
  display: flex;
  padding: 14px 20px;
  border-top: 1px solid $color-muted;
}
