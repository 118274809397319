@import '~common/styles/variables';

$counter-color: #fff;

.conversations {
  height: 100%;
}

.conversations-search {
  padding: 10px 20px;

  .form-label {
    .form-label-title {
      display: none;
    }
  }
}

.empty-conversation-list {
  padding: 10px 20px;
  font-size: .875rem;
  font-weight: bold;
}

.conversations-list {
  height: calc(100% - 65px) !important;

  .conversation {
    height: auto;
    padding: 10px 20px;
    display: flex;
    cursor: pointer;
    transition: .3s background;
    text-decoration: none;

    &:hover,
    &.active {
      background: $color-muted;
    }

    .avatar-container {
      margin-right: 10px;
    }

    .body {
      width: calc(75% - 40px);
      font-size: 14px;
      padding-top: 5px;

      .name {
        color: $color-text-dark;
        font-weight: bolder;
        padding-bottom: 5px;
      }

      .text {
        color: $soft-dark-text;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .date-time {
      width: 20%;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;

      .time {
        color: $color-font-main;
        padding-bottom: 5px;
      }

      .counter {
        width: 20px;
        height: 20px;
        background-color: $color-badge;
        border-radius: 50%;
        text-align: center;
        color: $counter-color;
        line-height: 1.4;
        box-shadow: 0 1px 2px 0 rgba(59, 71, 90, .42);
      }
    }
  }
}

.candidate-page {
  .conversations-list {
    .conversation {
      &:hover,
      &.active {
        background: rgba($color-primary, .08);
      }
    }
  }
}
