@import '~common/styles/variables';

$item-description: #bdc9df;
$point-background: #e5eefc;
$link-color-active: #fff;

.empty-placeholder {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  opacity: .7;
  user-select: none;
}

.job-offer-row {
  .item-description {
    color: $item-description;
  }

  .job-title,
  .candidates-list {
    width: 30%;
  }

  .branch,
  .match-points {
    width: 20%;
  }

  .points {
    width: 48px;
    height: 28px;
    color: $color-accent;
    padding: 5px 10px;
    border-radius: 14px;
    background-color: $point-background;
    font: normal 14px/1.43 'Lato', sans-serif;
  }

  .badge {
    width: 20px;
    color: $link-color-active;
    height: 20px;
    display: inline-flex;
    font-size: 13px;
    margin-left: 5px;
    overflow: hidden;
    border-radius: 50%;
    text-align: center;
    align-items: center;
    letter-spacing: .3px;
    justify-content: center;
    background: $color-badge;
  }
}

.candidate {
  display: inline-block;
  width: 28px !important;

  & > img,
  .more {
    width: 44px;
    height: 44px;
    object-fit: cover;
    border: 1px solid $color-muted;
    border-radius: 50%;
  }

  .more {
    color: $color-accent;
    float: left;
    display: inline-flex;
    background: $color-muted;
    align-items: center;
    justify-content: center;
    font: normal 14px/1.43 'Lato', sans-serif;
  }
}
