@import '~common/styles/variables';

@media screen and (max-width: $media-md) {
  .questions-container {
    &::after {
      right: 0;
    }

    &::before {
      left: 0;
    }
  }
}

@media screen and (max-width: $media-sm) {
  .questions-container {
    overflow: hidden;
    height: auto;
    padding: 60px 30px 55px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 35px;

    &::after {
      right: -9px;
      bottom: -12px;
    }

    &::before {
      left: -54px;
    }

    .title {
      line-height: 1.14;
      letter-spacing: .6px;
    }

    .description {
      font: normal 18px/2 'Lato', sans-serif;
      color: $color-font-main;
      opacity: .6;
      letter-spacing: .6px;
    }
  }
}

@media screen and (max-width: $media-xs) {
  .questions-container {
    margin: 30px -15px 35px;
    border-radius: 0;

    .btn {
      width: 100%;
    }
  }
}
