.gallery-container {
  img {
    max-width: 100%;
    display: block;
    height: 100%;
    width: 100%;
    object-position: center;
    object-fit: cover;
    border-radius: 4px;
  }

  .primary-photo-wrapper {
    margin: 4px 0;
    overflow: hidden;
    height: 240px;
  }

  .gallery-thumbnails {
    display: flex;
    margin-right: -4px;
    margin-left: -4px;

    .thumbnail-photo-wrapper {
      height: 72px;
      padding: 4px;
      overflow: hidden;
      position: relative;

      &.active {
        pointer-events: none;
      }

      &:not(.active) {
        &::before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, .66);
          cursor: pointer;
        }
      }
    }
  }
}
