@import '~common/styles/variables';

.profile-details-modal {
  .full-info-container {
    .tabs-list {
      padding-left: 0;
      padding-right: 0;
    }

    .competencies-wrapper {
      padding: 0;
    }
  }

  .actions-buttons {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    justify-content: space-between;

    .invite-button {
      width: 100%;

      &:last-of-type {
        margin-right: 0;
      }
    }

    .external-link {
      flex: 1;
      padding-right: 12px;
    }

    button {
      &:first-child {
        flex: 1;
      }
    }
  }

  .main-info {
    padding: 20px 0;

    h3 {
      margin: 0;
      font-size: 1.375rem;
      color: $color-text-dark;
    }

    .last-online {
      font-size: .875rem;
    }
  }

  .set-contact-btns {
    display: flex;
    margin: 0 -4px;
    padding: 4px 0;

    .btn-container {
      flex: 1;
      padding: 0 4px;

      .btn {
        width: 100%;
      }
    }
  }

  .contact-info {
    padding: 14px 0;

    .contact-item {
      padding: 6px 0;

      .contact-label {
        font-size: .875rem;
      }

      .contact-data {
        font-weight: bold;
        color: $color-text-dark;
      }
    }
  }

  .extra-actions {
    padding: 16px 0;

    & > .btn:not(:last-of-type) {
      margin-bottom: 10px;
    }

    .btn.grey {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
