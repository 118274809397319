@import '~common/styles/variables';

@media screen and (max-width: $media-xs) {
  .filter-container {
    .filter-options.mobile-right {
      right: 0;
      left: auto;
    }
  }
}
