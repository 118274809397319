@import '~common/styles/variables';

@media screen and (max-width: $media-xl) {
  .modal.application-delete-modal {
    z-index: 10001;

    .modal-content {
      left: 0;
      transform: translate(-50%, -50%);
    }
  }
}

@media screen and (max-width: $media-sm) {
  .modal.application-delete-modal {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .modal-content {
      height: 200px;
      position: static;
      overflow: hidden;
      transform: translate(0, 0);
    }

    .delete-action {
      bottom: 0;
      width: 100%;
      height: 64px;
      align-items: center;
      position: fixed;
      padding: 10px 20px;
      margin-left: -16px;
      background-color: #fff;
      box-shadow: 0 -1px 0 0 $color-muted;
    }
  }
}

@media screen and (max-width: $media-xs) {
  .modal.application-delete-modal {
    .delete-modal-content {
      width: 100%;
      min-width: 1px;
    }

    .modal-content {
      width: 90%;
      top: auto;
      bottom: 0;
      height: 260px;
    }

    .delete-action {
      height: 120px;
    }
  }
}
