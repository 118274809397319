.salary-range-container {
  width: 100%;
  display: flex;
  align-items: center;

  .salary-field-width {
    flex-basis: calc(25% - 10px);
  }

  .working-time-field-width {
    flex-basis: calc(35% - 10px);
  }

  .form-group {
    margin-right: 12px;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .min-salary .form-label input {
    padding-left: 55px;
  }

  .max-salary .form-label input {
    padding-left: 40px;
  }
}
