@import '~common/styles/variables';

.mobile-title-text-show {
  display: none;
}

@media screen and (max-width: $media-md) {
  .mobile-sidebar-item {
    padding: 10px;
    min-width: 136px;
    text-align: center;
    margin-right: 10px;
    letter-spacing: .33px;
    background-color: transparent;
    border: 1px solid $color-font-main;

    &.primary {
      color: $color-font-main;

      &.active,
      &:hover {
        color: #fff;
        background: $color-font-main;
      }
    }

    &:last-of-type {
      margin-right: 0;
    }

    .mobile-title-text-hide {
      display: none;
    }

    .mobile-title-text-show {
      display: block;
    }
  }
}

@media screen and (max-width: $media-sm) {
  .mobile-sidebar-item {
    width: 50%;
    text-align: center;
  }
}
