@import '~common/styles/variables';

@media screen and (max-width: $media-md) {
  .job-offer-preview-modal {
    &.mobile-job-offer-preview-modal {
      width: 100vw;

      & > .card {
        &.job-offer-preview-container {
          .job-offer-preview-content {
            padding: 0;

            .job-offer-preview {
              margin: 0;
            }
          }
        }
      }
    }
  }
}


@media screen and (max-width: $media-sm) {
  .job-offer-preview-modal {
    &.mobile-job-offer-preview-modal {
      padding: 0;
    }
  }
}
