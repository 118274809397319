@import '~common/styles/variables';

$color-policy: #7a8cab;
$caption-color: #505f79;
$color-card: #fff;
$color-border-caption: #e5eefc;

.payment {
  & .card {
    position: relative;
    margin: 0 10px;
    text-align: center;
    border: 1px solid $border-light-color;
    background-color: transparent;
    border-radius: 4px;
    font-weight: 300;
    cursor: pointer;
    transition: all .2s;

    & .card-caption {
      margin: 0;
      padding: 13px;
      font-size: 20px;
      line-height: 16px;
      text-align: center;
      font-weight: normal;
      letter-spacing: .3px;
      text-transform: uppercase;
      color: $color-font-main;
      border-bottom: 1px solid $color-border-caption;
    }

    &.selected {
      color: $color-card;
      background-color: $color-accent;

      & .card-caption {
        color: $color-card;
        border-bottom: 1px solid $border-light-color;
      }

      & .card-content {
        & .card-button {
          color: $color-card;
          background: transparent;
          border: 1px solid $color-card;

          &:hover {
            background: rgba(255, 255, 255, .2);
          }
        }
      }
    }

    & > .card-content {
      display: flex;
      flex-direction: column;
      height: calc(100% - 42px);
      justify-content: space-between;

      & > .card-button {
        margin: 0 24px 20px;
      }
    }
  }
}

.selected-title {
  left: 50%;
  padding: 1rem;
  font-size: 14px;
  position: absolute;
  transform: translateX(-50%);
  font-weight: normal;
  letter-spacing: .1px;
  color: $color-accent;
}

.card-feature-list {
  list-style: none;
  text-align: left;
  line-height: 1.2;
  font-size: 14px;
  margin-top: 18px;

  & > li {
    list-style-image: url('/images/landing/checkbox-blue.svg');
    line-height: 22px;
  }
}

.price {
  padding: 1rem;
  font-size: 14px;
  line-height: 15px;

  & > p {
    position: relative;
  }

  & .euro-sign {
    position: absolute;
    top: -10px;
    left: 70px;
  }

  & .price-count {
    font-size: 36px;
  }
}
