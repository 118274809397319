@import '~react-perfect-scrollbar/dist/css/styles.css';
@import '~common/styles/variables';
@import '~common/styles/tooltips';
@import '~common/styles/icons';

$background-gray: #f4f5f7;

* {
  box-sizing: border-box;
}

body {
  color: $color-font-main;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-family: 'Lato', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $color-font-dark;
}

a {
  text-decoration: none;
  color: $color-font-main;

  &:hover {
    text-decoration: underline;
  }
}

.auth-container,
.portal-container,
.landing-container {
  &.default {
    background-color: $background-gray;
    min-height: calc(var(--vh, 1vh) * 100);
  }
}
