@import '~common/styles/variables';

.profile-sidebar-close,
.profile-sidebar .main-header,
.portal-sidebar-mobile-title {
  display: none;
}

.mobile-sidebar-close {
  margin-bottom: 20px;
}

@media screen and (max-width: $media-md) {
  .profile-sidebar {
    width: 100%;
    margin: 0;

    .main-header {
      display: block;
      width: calc(100% + 40px);
      margin: 0 0 25px;
      position: relative;
      left: -20px;
    }

    .portal-sidebar-mobile-title {
      display: block;
      font-family: 'Lato', sans-serif;
      font-size: 24px;
      line-height: 1.17;
      letter-spacing: .2px;
      color: $color-text-dark;
    }

    .profile-sidebar-close {
      display: block;
      color: $color-font-main;
      font-size: 24px;
      line-height: 14px;
    }

    & .profile-sidebar-caption {
      order: 1;
      margin: 0;
      height: 14px;
      width: 14px;
      padding: 0;
      font-size: 0;
      line-height: 0;
    }

    & .profile-menu-list {
      max-width: 280px;
      padding-right: 5px;

      & > li {
        letter-spacing: .54px;
        font-size: 14px;
        font-weight: bold;
        padding: 0 0;
        border-radius: 0;
        margin-bottom: 20px;
        position: relative;

        &:before {
          content: '';
          border-style: solid;
          border-color: $color-font-main;
          border-width: 2px 2px 0 0;
          display: inline-block;
          height: 5px;
          width: 5px;
          position: absolute;
          top: 5px;
          right: 0;
          transform: rotate(45deg);
        }
      }

      & .signout {
        margin-top: 75px;
        margin-bottom: 0;

        &:before {
          content: none;
        }
      }
    }

    .mobile-sidebar-close {
      display: flex;
      margin: 0 0 30px;
      align-items: center;
      justify-content: space-between;
    }
  }
}


@media screen and (max-width: $media-sm) and (max-height: $media-xs) {
  .profile-sidebar {
    & .profile-menu-list {
      max-width: 180px;
    }
  }
}
