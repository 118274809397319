@import '~common/styles/variables';

.ask-me-modal {
  display: flex;
  padding: 1rem 40px;
  height: calc(var(--vh, 1vh) * 100);
  background: $bg-color-default;
  width: 60vw;
  box-shadow: 0 5px 21px 0 rgba(66, 101, 159, .2);
  overflow: hidden;
  cursor: auto;
  flex-direction: column;

  & > .card {
    position: relative;
    overflow: hidden;

    &.ask-me-container {
      height: 100%;
      max-width: 556px;

      .ask-me-content {
        height: 100%;
        padding: 10px;

        .ask-me-preview {
          height: calc(100% - 44px);
          margin: 10px;
          padding-right: 30px;
        }
      }
    }
  }

  .modal-btn {
    padding: 0;
  }

  .ask-me-image {
    margin: 20px 0;

    img {
      width: 100%;
      box-shadow: 0 2px 8px 0 rgba(59, 71, 90, .16);
    }
  }

  .ask-me-text {
    font: normal 14px/1.71 'Lato', sans-serif;
    line-height: 1.71;
    letter-spacing: 0.1px;

    & > p {
      margin-bottom: 20px;
      letter-spacing: 0.11px;
      line-height: 24px;
    }
  }

  .ask-me-footer {
    font: normal 14px/1.71 'Lato', sans-serif;
    color: $soft-dark-text;
    margin: 20px 0;
    padding: 10px 0;
    border-top: 1px solid $color-muted;
  }
}
