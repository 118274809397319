@import '~common/styles/variables';

.modal-columns-container {
  width: 100%;
  height: 100%;
  max-width: 930px;
  border-radius: 4px;
  background-color: #fff;
  padding: 12px 6px 12px 12px;
  box-shadow: 0 2px 3px 0 $box-shadow-color;

  .back-btn-container > a {
    padding: 0;
    color: $color-primary
  }

  .full-info-container {
    order: 2;
    width: 100%;
  }

  .restricted-company {
    position: relative;
    left: -15px;
  }

  .modal-columns {
    display: flex;
    flex-wrap: wrap;
    height: calc(100% - 43px);

    .general-info {
      padding: 1rem 0;
      flex: 1;
    }

    .aside-info {
      padding: 16px;
      width: 312px;
    }

    &.blur {
      .general-info,
      .aside-info {
        opacity: .4;
        pointer-events: none;
      }
    }
  }
}

.job-offer-modal {
  width: 100%;

  .actions-buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 1rem;
  }

  .full-info-container {
    .tabs-list {
      padding-left: 0;
      padding-right: 0;
    }

    .competencies-wrapper {
      padding: 0;
    }

    .company-description-caption {
      text-transform: uppercase;
    }
  }
}
