@import '~common/styles/variables';

$bg-color: #edf9da;
$text-dark: #505f79;

.description-container {
  width: 83%;
  padding: 13px;
  display: flex;
  border-radius: 4px;
  margin: 10px 0 30px;
  background-color: $bg-color;
}

.description-icon {
  margin-right: 13px;

  & > img {
    border-radius: 50%;
  }
}

.description-text {
  font-size: 14px;
  color: $text-dark;
}
