@import '~common/styles/variables';

.mobile-favorite-offer-total {
  display: none;
}

@media screen and (max-width: $media-sm) {
  .mobile-favorite-offers {
    display: block;

    .portlet-control {
      display: none;
    }

    .portlet-title,
    .mobile-favorite-offer-total {
      display: inline-block;
    }

    .mobile-favorite-offer-total {
      letter-spacing: .2px;
      color: $color-text-dark;
      font-size: 24px;
      margin-left: 5px;
    }
  }

  .mobile-favorite-offers-body {
    padding: 5px 20px 0;
  }
}

@media screen and (max-width: $media-xs) {
  .mobile-favorite-offers-body {
    padding: 5px 0 0;

    .job-offer-row {
      border-radius: 0;
    }
  }
}
