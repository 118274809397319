@import '~common/styles/variables';

.contact-us-modal-container {
  &.modal {
    top: 0;
  }
}

.contact-us-modal-container {
  &.modal.fade.open {
    height: calc(var(--vh, 1vh) * 100);
  }
}

@media screen and (max-width: $media-md) {
  .modal-overlay.overlayed {
    z-index: 10000;
  }

  .contact-us-modal-container {
    &.modal.fade.open {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      max-width: 100%;
      width: 100vw;
      height: calc(var(--vh, 1vh) * 100);
      z-index: 10001;
    }

    .modal-caption {
      p {
        line-height: 1.43;
        letter-spacing: .3px;
        font-size: 14px;
        font-weight: bold;
      }
    }

    .modal-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 10px;
      overflow: auto;

      .modal-content {
        position: relative;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translate(0, 0);
        max-width: 520px;
        min-width: 1px;
        margin: 0 auto;
      }
    }
  }
}

@media screen and (max-width: $media-xs) {
  .modal-overlay.overlayed {
    z-index: 10000;
  }

  .contact-us-modal-container {
    &.modal.fade.open {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100vw;
      height: calc(var(--vh, 1vh) * 100);
      z-index: 10001;
    }

    .modal-caption {
      p {
        line-height: 1.43;
        letter-spacing: .3px;
        font-size: 14px;
        font-weight: bold;
      }
    }

    .modal-container {
      .modal-content {
        max-width: 100%;
      }
    }

    .contact-information {
      flex-wrap: wrap;

      .form-group.half-width {
        flex-basis: 100%;
      }

      .form-group {
        margin: 0 0 18px;
      }
    }

    .form-control.textarea {
      height: 84px;
    }

    .contact-us-description {
      font-size: 18px;
      line-height: 1.33;
      letter-spacing: .4px;
      width: 100%;
    }

    .contact-us-modal-form {
      padding: 25px 20px 20px;
    }

    .attachment-files-container {
      .attach-file {
        padding: 8px 24px;
      }

      .file-list {
        padding: 25px 0 0;

        .file {
          .control {
            top: 3px;
            margin: 0 0 0 2px;
          }
        }
      }
    }
  }

  .contact-us-modal-form-action {
    padding-top: 0;
    flex-direction: column;
    flex-wrap: wrap;

    .btn {
      &.medium {
        width: 260px;
        max-width: 100%;
        margin: 0 0 12px;
      }
    }
  }
}

@media screen and (max-width: $media-md) and (max-height: $media-sm) {
  .contact-us-modal-container {
    .modal-container {
      .modal-content {
        margin-top: 50vh;
      }
    }
  }
}

@media screen and (max-width: $media-md) and (max-height: $media-xs) {
  .contact-us-modal-container {
    .modal-container {
      .modal-content {
        margin-top: 65%;
      }
    }
  }
}

