@import '~common/styles/variables';

.mobile-create-post-btn {
  display: none;
}

@media screen and (max-width: $media-md) {
  .portlet-body {
    padding: 10px;

    &.mobile-portlet-cv {
      padding: 20px 0;
    }
  }

  .mobile-create-template-hide {
    display: none;
  }
}

@media screen and (max-width: $media-sm) {
  .portlet-body {
    flex-grow: 1;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
  }

  .portlet-body-scroll {
    &.scrollbar-container{
      flex-grow: 1;
      padding: 0;

      .ps__rail-y {
        z-index: 1;
      }
    }
  }

  .web-create-post-btn {
    display: none;
  }

  .mobile-create-post-btn {
    display: block;
    box-shadow: 0 -1px 0 0 $color-muted;
    padding: 12px 20px;
  }
}

@media screen and (max-width: $media-xs) {
  .mobile-portlet-poster {
    padding: 0;
  }

  .mobile-create-post-btn {
    .btn {
      width: 100%;
    }
  }

  .portlet-body {
    &.mobile-portlet-cv {
      padding: 0;
      margin-top: 20px;
      border-top: 1px solid #e5eefc;
      border-bottom: 1px solid #e5eefc;
    }
  }
}

@media screen and (max-width: $media-md) and (max-height: $media-xs) {
  .portlet-body {
    height: 100%;

    &.mobile-portlet-cv,
    &.mobile-portlet-body-blog {
      height: auto;
    }
  }

  .portlet-body-scroll {
    &.scrollbar-container{
      height: auto;
    }
  }

  .mobile-applications-portlet-body {
    height: calc(100% + 64px + 84px);
  }
}
