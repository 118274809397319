@import '~common/styles/variables';

@media screen and (max-width: $media-sm) {
  .empty-section {
    width: 100%;
    padding: 0;

    .empty-section-title {
      line-height: 1.5;
      letter-spacing: .89px;
      color: $color-font-dark;
    }

    .empty-section-description {
      font-size: 14px;
      margin: 0 0 16px;
      line-height: 1.71;
      letter-spacing: .29px;
    }
  }
}

@media screen and (max-width: $media-xs) {
  .mobile-competencies-section-container {
    width: 100%;
    flex-wrap: nowrap;
    flex-direction: column;

    .competencies-item {
      display: flex;
      margin: 0 0 8px;
      font-size: 14px;
      min-height: 36px;
      align-items: center;
      line-height: 1.71;
      letter-spacing: .29px;
    }
  }

  .empty-section {
    width: 100%;

    .btn {
      width: 100%;
    }
  }
}
