@import '~common/styles/variables';

.multi-select-container {
  min-width: 100%;
  outline: none;

  .select__menu {
    .select__option {
      &.select__option--is-selected {
        background: #B2D4FF;
      }
    }
  }

  .select__value-container {
    padding: 2px;

    &.select__value-container--is-multi {
      &:not(.select__value-container--has-value) {
        .select__input {
          padding-left: 15px;
        }
      }
    }

    &:not(.select__value-container--is-multi) {
      .select__input {
        padding-left: 12px;
      }

      .select__single-value {
        padding-left: 12px;
      }
    }
  }

  .multi-select-container {
    .select__value-container {

    }
  }

  .form-label-error {
    color: $color-danger;
    font-size: .875rem;
    margin-bottom: 10px;
  }

  .select__control {
    min-height: 45px;
    border-color: $border-light-color;

    &:hover,
    &:focus {
      border: 1px solid $color-accent;
    }

    .select__placeholder {
      margin: 0;
      padding-left: 1rem;
      color: $color-font-dark;
    }

    .select__multi-value {
      padding: 5px 8px;
      align-items: center;
      border-radius: 3px;
      display: inline-flex;
      background: $color-muted;
      border: 1px solid $border-light-color;

      .select__multi-value__remove {
        & > svg {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }

        &:hover {
          background-color: transparent;
          color: $color-accent;
        }
      }
    }

    .select__indicators {
      .select__clear-indicator,
      .select__dropdown-indicator {
        & > svg {
          color: $color-font-dark;
          cursor: pointer;
        }
      }
    }
  }

  .select__menu {
    padding: 12px 0;
    z-index: 999;
    height: 400px;
    overflow-y: scroll;

    .select__option {
      padding: 10px 24px;
      font-weight: bold;
      cursor: pointer;
      color: $color-font-dark;
    }
  }
}
