@import '~common/styles/variables';

@media screen and (max-width: $media-xs) {
  .candidate-history-container {
    padding-top: 10px;

    .history-actions {
      padding-top: 5px;

      .btn {
        width: 280px;
        max-width: 100%;
        margin-right: 0;
      }
    }
  }
}
