@import '~common/styles/variables';

.cv-sidebar-container {
  .statistics-items {
    padding: 3px 0;
  }

  .statistics-item {
    &::after {
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: inline-block;
      background: $color-primary;
    }
  }
}