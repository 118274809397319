@import '~common/styles/variables';

.mobile-close-btn,
.mobile-interact-button,
.mobile-company-info-show,
.mobile-job-offer-skills-list-show {
  display: none;
}

.job-offer-modal {
  &.mobile-job-offer-modal {
    .full-info-container {
      .job-description {
        font-size: 14px;
        line-height: 1.71;
        letter-spacing: .29px;
      }
    }
  }
}

@media screen and (max-width: $media-md) {
  .modal-columns-container {
    &.mobile-modal-columns-container {
      .back-btn-container {
        text-align: right;

        & > a {
          font-size: 0;
          margin: 2px 19px 0 0;
        }
      }

      .mobile-close-btn {
        display: block;
        font-size: 24px;
        line-height: 14px;
        color: $color-font-main;
      }
    }
  }

  .job-offer-modal {
    &.mobile-job-offer-modal {
      .full-info-container {
        padding: 0 21px;
      }
    }
  }
}

@media screen and (max-width: $media-sm) {
  .modal-columns-container {
    &.mobile-modal-columns-container {
      padding: 12px 0;
      border-radius: 0;

      .mobile-jobs-list {
        height: 98%;
      }

      .modal-columns {
        flex-wrap: nowrap;
        flex-direction: column;

        .general-info {
          padding-top: 0;
        }

        .aside-info {
          padding: 0;
        }
      }

      .mobile-close-btn {
        display: block;
        font-size: 24px;
        line-height: 14px;
        color: $color-font-main;
      }

      .mobile-interact-button {
        display: block;
        margin-top: 28px;

        .btn {
          margin-right: 0;
          max-width: 280px;
        }
      }
    }
  }

  .job-offer-modal {
    &.mobile-job-offer-modal {
      .actions-buttons {
        margin: 0;
      }

      .full-info-container {
        .company-description-caption {
          text-transform: initial;
        }
      }

      .mobile-company-info-hide {
        display: none;
      }

      .mobile-company-info-show {
        display: block;
      }
    }
  }

  .scrollbar-container.mobile-interact {
    height: calc(100% - 47px - 64px);
  }

  .mobile-job-offer-skills-list-hide {
    display: none;
  }

  .mobile-job-offer-skills-list-show {
    display: block;
  }
}

@media screen and (max-width: $media-xs) {
  .modal-columns-container {
    &.mobile-modal-columns-container {
      .mobile-interact-button {
        .btn {
          max-width: 100%;
        }
      }
    }
  }
}
