@import '~common/styles/variables';

.sepa-payment-modal + .modal-overlay.overlayed {
  background: rgba(60, 70, 90, 0.4);
}

.sepa-payment-modal {
  .modal-content {
    width: 770px;
    height: auto;
    padding: 35px 45px;

    text-align: center;
  }

  &-title {
    margin-bottom: 10px;

    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    color: $color-font-dark;
  }

  &-subtitle {
    width: 470px;
    margin: 0 auto;
    margin-bottom: 20px;

    line-height: 1.5;
    letter-spacing: 0.4px;
    color: $color-font-main;
  }

  &-policy {
    font-size: 12px;
    line-height: 1.3;
    letter-spacing: 0.3px;
    text-align: justify;
    color: $color-font-main;
  }
}
