.vdk-container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 50px;

  .vdk-information-container {
    display: flex;
    align-items: center;
    width: 60%;

    .logo {
      max-width: 100%;
      width: 200px;
      margin-right: 30px;
    }
  }

  .vdk-video-container {
    width: 35%;
    display: flex;
    align-items: center;

    .vdk-video {
      video {
        width: 100%;
        height: auto;
      }
    }
  }
}