.personal-info-form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  & > .form-btn-submit.form-actions {
    width: 100%;
    display: flex;
    padding-top: 64px;
    justify-content: space-between;

    .hidden {
      display: none;
    }
  }
}
