@import '~common/styles/variables';

$background-color: #fff;

.guest-job-offer-payment-container {
  max-width: 820px;
  margin: 0 auto 1rem;
  padding: 10px 40px 20px;
  background: $background-color;

  .title {
    font-size: 1rem;
    color: $color-font-dark;
  }
}

.payment {
  .btn {
    &.large {
      min-width: 224px;
    }

    &.medium {
      min-width: 176px;
    }

    &.small {
      min-width: 120px;
    }
  }

  .vat-id-form {
    margin-bottom: 24px;
  }

  .label-error span {
    left: 17px;
    bottom: -20px;
  }
}

.payment-action {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 48px;

  &.half-width {
    width: calc(50% - 10px);
  }

  & > .confirm-btn {
    display: inline-block;
    text-decoration: none;
  }
}

.payment-options {
  margin-bottom: 40px;

  &.disabled {
    pointer-events: none;
    opacity: .5;
  }

  & > .radio-group {
    padding: 0;
  }
}

.payment-caption {
  color: $color-font-dark;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: .23px;
  line-height: 28px;
  margin-bottom: 12px;
}

.payment-description {
  padding: 24px 0 32px;
  margin: 0;
}

.card-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 56px;
}

.payment-policy {
  color: $color-font-main;
  font-size: 14px;
  letter-spacing: .35px;
  line-height: 20px;
  margin: 1rem 0 0;
}

.subscription-plan {
  display: flex;
  padding: 14px 0;
  max-width: 340px;
  color: $color-font-dark;
  justify-content: space-between;
  border-top: 1px solid $color-muted;
  border-bottom: 1px solid $color-muted;
}
