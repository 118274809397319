@import '~common/styles/variables';

.mobile-new-template-show {
  display: none;
}

@media screen and (max-width: $media-xl) {
  .modal.apply-modal {
    z-index: 10001;

    .modal-content {
      top: auto;
      left: auto;
      width: 65vw;
    }

    .apply-content {
      height: 100%;
    }

    .content {
      height: calc(100% - 64px);
    }
  }
}

@media screen and (max-width: $media-md){
  .modal.apply-modal {
    .modal-content {
      height: calc(var(--vh, 1vh) * 80);
    }
  }
}

@media screen and (max-width: $media-sm) {
  .modal.apply-modal {
    .modal-content {
      margin: 0 auto;
      position: static;
      transform: translate(0, 0);
      height: calc(var(--vh, 1vh) * 100);
      width: 100vw;
      min-width: auto;
    }

    .content {
      margin-bottom: 0;
      height: calc(100% - 47px - 64px);
    }

    .applications-action {
      bottom: 0;
      width: 100%;
      height: 64px;
      display: flex;
      position: fixed;
      padding: 10px 20px;
      margin-left: -20px;
      background-color: #fff;
      box-shadow: 0 -1px 0 0 $color-muted;
    }
  }

  .mobile-new-template-hide {
    white-space: nowrap;
  }
}

@media screen and (max-width: $media-xs) {
  .modal.apply-modal {
    .modal-content {
      width: 100vw;
    }

    .applications-action {
      .btn {
        width: 50%;

        &:not(:last-of-type) {
          margin-right: 10px;
        }
      }

      .checkbox-inline {
        bottom: 0;
      }
    }
  }

  .mobile-new-template-hide {
    display: none;
  }

  .mobile-new-template-show {
    margin-top: 20px;
    display: inline-block;
  }
}
