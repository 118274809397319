@media screen and (max-width: 1080px) {
  .vdk-container {
    display: block;

    .vdk-information-container {
      width: 100%;
      justify-content: center;
      margin-bottom: 40px;
    }

    .vdk-video-container {
      width: 100%;

      .vdk-video {
        width: 100%;

        video {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .vdk-container {
    .vdk-information-container {
      .logo {
        width: 100px;
      }
    }
  }
}