@import '~common/styles/variables';

.mobile-delete-btn-show {
  display: none;
}

@media screen and (max-width: $media-xs) {
  .mobile-study-place-container {
    .study-place-caption {
      h4 {
        font-size: 18px;
        line-height: 1.33;
        letter-spacing: 1px;
      }
    }

    .from-date {
      flex-direction: column;

      .from-date-field {
        width: 100%;

        &:nth-child(2) {
          margin-top: 24px;
        }
      }
    }

    .mobile-delete-btn-hide {
      display: none;
    }

    .mobile-delete-btn-show {
      display: block;
    }

    .delete-btn {
      padding: 7px 8px;
    }
  }
}
