@import '~common/styles/variables';

$link-color-active: #fff;

.candidate-profile-nav-container .profile-nav-body .tile-title .badge,
.close-portal-nav,
.setting-mobile-cation {
  display: none;
}

@media screen and (max-width: $media-lg) {
  .candidate-profile-nav-container {
    .profile-nav-body {
      .profile-nav-links {
        .profile-nav-link {
          .tile-title {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            align-items: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $media-md) {
  .candidate-profile-nav-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    left: -100%;
    top: 0;
    width: 275px;
    padding: 0;
    transition: left .5s;
    z-index: 1;
    overflow-y: scroll;

    .mobile-candidate-profile-nav-overlay,
    &:after {
      content: '';
      position: fixed;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: -100%;
      transition: all .5s;
      width: 100vw;
      height: calc(var(--vh, 1vh) * 100);
      background: $color-font-dark;
    }

    .mobile-candidate-profile-nav-overlay {
      opacity: 0;
      transition: all 0s;
    }

    &:after {
      z-index: 2;
      transition: left .5s;
      width: 275px;
      background: $bg-color-default;
    }

    &.show {
      left: 0;

      .mobile-candidate-profile-nav-overlay {
        left: 0;
        opacity: .4;
      }

      &:after {
        left: 0;
      }
    }

    .profile-nav-header {
      display: block;
      padding: 35px 20px 15px;
      position: relative;
      background: #fff;
      box-shadow: $box-shadow-mobile;
      z-index: 3;

      .person-container {
        .avatar-wrapper {
          width: 52px;
          height: 52px;
        }

        .name-wrapper {
          .user-name {
            font-size: 16px;
            line-height: 1.75;
            letter-spacing: .3px;
          }

          .user-role {
            font-size: 14px;
            line-height: 1.43;
            letter-spacing: .3px;
          }
        }
      }

      .icon-settings {
        margin-top: 15px;
        height: 40px;
        width: 100%;
        background: $bg-color-default;
        color: $soft-dark-text;
        display: flex;
        justify-content: center;
        align-items: center;

        .setting-mobile-cation {
          font-family: 'Lato', sans-serif;
          font-size: 16px;
          font-weight: bold;
          letter-spacing: .3px;
          color: $color-font-dark;
          display: inline-block;
          margin-left: 10px;
        }
      }

      .close-portal-nav {
        display: block;
        font-size: 32px;
        position: absolute;
        height: 15px;
        line-height: 12px;
        top: 20px;
        right: 20px;
      }
    }

    .profile-nav-body {
      flex-grow: 1;
      position: relative;
      margin-top: 8px;
      background: #fff;
      box-shadow: $box-shadow-mobile;
      padding: 5px 20px 25px;
      z-index: 3;

      .profile-nav-links {
        display: block;
        height: auto;

        .profile-nav-link {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          width: 100%;
          margin-bottom: 15px;

          .tile {
            height: auto;
            border: 0 none;
            margin-right: 18px;

            .badge {
              display: none;
            }

            span {
              line-height: 1;
              color: $soft-dark-text;
            }
          }

          .tile-title {
            letter-spacing: .5px;
            font-size: 14px;
            color: $color-font-dark;
            font-weight: bold;

            .badge {
              display: inline-block;
              width: 20px;
              color: $link-color-active;
              height: 20px;
              font-size: 14px;
              line-height: 20px;
              overflow: hidden;
              border-radius: 50%;
              text-align: center;
              align-items: center;
              letter-spacing: .3px;
              justify-content: center;
              background: $color-badge;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
