@import '~common/styles/variables';

$date-separator-color: #7daeff;
$message-attachment-background: #fff;

.conversation {
  height: calc(100% - 60px);
  overflow: hidden;

  &.attachment {
    .scrollbar-container {
      height: calc(100% - 120px);
    }
  }

  .scrollbar-container {
    height: calc(100% - 76px);
    margin: 10px 10px;
    display: flex;
  }

  .block-message {
    align-items: center;
    width: 100%;
    display: flex;
    padding: 10px 15px;
    min-height: 65px;

    .lock-avatar {
      height: 45px;
      width: 45px;
      display: flex;
      align-items: center;
      margin-right: 20px;
      justify-content: center;
      border: 1px solid #bdc9df;

      & img {
        width: 1rem;
        height: 1rem;
      }
    }

    h4 {
      font-style: 1rem;
    }
  }
}

.conversation-messages {
  padding: 10px;
  width: 100%;
  margin-top: auto;
}

.conversation-controls-panel {
  height: 100px;
  display: flex;
  padding: 3px 20px;
  background: $color-muted;
  width: 100%;

  .upload-file {
    width: 40px;
    padding: 10px 5px;
  }

  .message-input {
    width: calc(88% - 40px);
    margin: 7px 0;

    input {
      padding: 8px 15px !important;
    }

    .form-label-title {
      display: none;
    }

    .attachment {
      display: flex;

      .file {
        color: $color-font-main;
        margin-top: 10px;
        border: 1px solid $border-light-color;
        font-size: 14px;
        border-radius: 4px;
        background-color: $message-attachment-background;
        display: flex;
        align-items: center;
        padding: 7px 5px 7px 20px;

        .title {
          padding-right: 20px;
        }

        .close {
          width: 16px;
        }
      }

      .size {
        font-size: 12px;
        display: flex;
        align-items: center;
        padding: 10px 7px 0;
      }
    }
  }

  .upload-button {
    input {
      width: 0;
      height: 0;
      opacity: 0;
    }

    img {
      cursor: pointer;
      
      &:hover {
        opacity: .7;
      }
    }
  }

  .send-message {
    .send-message-button {
      margin: 7px 10px;
      padding: 9px 40px;
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: .4;
  }
}

.candidate-page {
  .conversation-controls-panel {
    .send-message-button {
      background-color: $color-primary;
    }
  }
}
