@import '~common/styles/variables';

@media screen and (max-width: $media-sm) {
  .balance-board-container {
    padding: 20px 4px 40px;

    .balance-board-title {
      font-size: 28px;
      width: 80%;
    }

    .balance-board-description {
      width: 80%;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: .3px;
      margin-bottom: 25px;
    }
  }
}

@media screen and (max-width: $media-xs) {
  .balance-board-container {
    padding: 20px 4px 25px;

    .balance-board-title {
      font-size: 24px;
      line-height: 1.33;
      letter-spacing: .5px;
      text-align: left;
      width: 100%;
      margin-bottom: 10px;
    }

    .balance-board-description {
      width: 100%;
      text-align: left;
    }

    .balance-board-image {
      margin: 0 -12px;
    }
  }
}
