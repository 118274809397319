@import '~common/styles/variables';

@media screen and (max-width: $media-md) {
  .card {
    &.mobile-not-found-card {
      height: 100%;
      min-height: auto;
    }
  }
}

@media screen and (max-width: $media-sm) {
  .candidate-page {
    .card {
      width: calc(100% + 30px);
      position: relative;
      left: -15px;
    }
  }
}

@media screen and (max-width: $media-xs) {
  .card {
    min-height: 465px;
  }

  .mobile-sidebar-card {
    min-height: auto;
  }

  .candidate-page {
    .card {
      min-height: 1px;
    }
  }

  .mobile-landing-carousel-card {
    min-height: auto;
  }
}
