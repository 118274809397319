@import '~common/styles/variables';

.candidate-profile-preview {
  width: 100%;
  height: 100%;
  padding: 10px 0;

  .profile-breadcrumbs {
    background-color: $color-muted;
    width: 100%;
    padding: 10px 20px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    align-items: center;

    .actions {
      display: flex;
      cursor: pointer;
    }
  }

  .profile-preview {
    height: calc(100% - 44px);
    margin: 5px;
    display: flex;
    padding-right: 5px;

    .general-info {
      padding: 16px;
      flex: 1 1;
    }

    .aside-info {
      padding: 16px;
      width: 312px;
    }
  }
}
