@import '~common/styles/variables';

$background-white: #fff;
$muted-input-color: #bdc9df;
$input-color-muted: #bdc9df;
$dropdown-shadow: rgba(80, 95, 121, .3);

.phone-input-container {

  .phone-input-control.form-control:not([type='checkbox']):not([type='radio']) {
    height: 45px;
    padding-left: 60px;
    background-color: #fff !important;
    border: solid 1px $border-light-color !important;
  }

  .code-action-button {
    border: 1px solid #b2cbf4;
    border-right: none;
    border-radius: 5px 0 0 5px;
    background-color: rgba(224, 228, 235, .22);

    &:hover {
      background: transparent;
    }

    .selected-flag {
      padding: 0;
      width: 50px;
      display: flex;
      justify-content: center;
      border-right: 1px solid #b2cbf4;

      .flag {
        left: 30%;
      }

      &[title=""] {
        .arrow {
          border:  none;

          &::after {
            content: url(/images/shared/arrow-down.svg);
            position: absolute;
            right: 4px;
            top: -6px;
          }
        }
      }
    }
  }

  .search-code-container {
    padding: 10px 10px;

    .search-emoji.search-code-container-emoji {
      display: none;
    }

    .search-box.search-code-container-box {
      width: 100%;
      margin: 0;
      font-size: 1rem;
      font-weight: 600;
      position: relative;
      border-radius: 4px;
      padding: 12px 16px;
      color: $color-font-dark;
      transition: border-color .15s;
      font-family: 'Lato', sans-serif;
      background-color: $background-white;
      border: solid 1px $border-light-color;

      &::placeholder {
        opacity: 1;
        font-weight: normal;
        color: $muted-input-color;
      }

      &:hover,
      &:focus {
        outline: none;
        border-color: $color-accent;
      }
    }
  }

  .options-list {
    left: 0;
    top: 110%;
    z-index: 990;
    min-width: 200px;
    overflow-y: auto;
    padding: 0 0 12px;
    max-height: 300px;
    border-radius: 4px;
    position: absolute;
    background: $background-white;
    border: solid 1px $color-muted;
    box-shadow: 0 8px 15px 0 $dropdown-shadow;

    &.country-list {
      min-height: 250px;
    }

    .search.search-code-container {
      display: flex;
      padding: 10px 0;
      align-items: center;
      justify-content: center;

      label {
        width: 90%;
      }
    }

    li.country {
      padding: 11px 24px;
      font-weight: bold;
      cursor: pointer;
      color: $color-font-dark;

      .dial-code {
        color: inherit;
      }

      .flag {
        display: none;
      }

      &:hover {
        background-color: $color-muted;
      }
    }
  }
}
