@import '~common/styles/variables';

.menu-video-modal + .modal-overlay.overlayed {
  background: rgba(122, 140, 170, .4);
}

.menu-video-modal {
  &.open {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
    max-width: 100%;
    height: 61%;
    max-height: 100%;
    z-index: 1500;  
  }

  .modal-content {
    display: block;
    width: 100%;
    // height: 100%;
    text-align: center;
    //padding: 10px 2px 0;
    padding: 2px;
    flex-direction: column;
  }
  .dontShowMeagainCSS {
    color: #000; 
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: #4183c4;
  }
  a:hover {
    color: #4183c4;
  }
}

