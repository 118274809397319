@import '~common/styles/variables';

@media screen and (max-width: $media-xs) {
  .mobile-applications-portlet-body {
    .ex-list-xl {
      tr {
        td {
          .item-cell {
            &.mobile-application-sent-letter-cell {
              padding-top: 0;
            }
          }
        }
      }
    }
  }
}
