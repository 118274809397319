@import '~common/styles/variables';

.forgot-link-holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  .forgot-link {
    text-decoration: underline;
  }
}
