@import '~common/styles/variables';

@media screen and (max-width: $media-lg) {
  .inside-container {
    .inside-content {
      &::after {
        right: 29%;
      }
    }
  }
}

@media screen and (max-width: $media-md) {
  .inside-container {
    .inside-content {
      &::after {
        right: 20%;
      }
    }
  }
}

@media screen and (max-width: $media-sm) {
  .inside-container {
    .inside-content {
      &::after {
        right: 0;
      }
    }

    .inside-title {
      font-size: 28px;
      line-height: 1.43;
      letter-spacing: .6px;
    }

    .inside-description {
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: .3px;
    }
  }
}

@media screen and (max-width: $media-sm) {
  .inside-container {
    .inside-description {
      width: 65%;
    }
  }
}

@media screen and (max-width: $media-xs) {
  .inside-container {
    height: 334px;

    .inside-content {
      &::after {
        z-index: 1;
        right: -51px;
        bottom: -16px;
      }
    }

    .inside-title {
      max-width: 80%;
      margin: 0 auto 10px;
      text-align: center;
    }

    .inside-description {
      width: 85%;
    }

    .inside-control {
      .btn {
        width: 265px;
        max-width: 100%;
        margin: 0 auto;
        position: relative;
        z-index: 2;
      }
    }
  }
}
