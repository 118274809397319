@import '~common/styles/variables';

.education-section-container {
  .education-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;

    .left-info {
      max-width: 130px;
      padding: 8px 5px;

      p {
        font-size: 14px;
        margin: 3px 0;
        letter-spacing: 0.9px;
      }

      .duration {
        color: $color-primary;
      }
    }

    .right-info {
      margin-left: 26px;

      .degree {
        margin: 5px 0;
        font-size: 20px;
      }

      .school {
        margin: 8px 0;

        &.link {
          color: $color-accent;
        }
      }
    }
  }
}
