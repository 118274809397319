$header-height: 52px;
$widget-height: 300px;
$container-margin-bottom: 24px;

.page {
  padding: 16px 0;
  min-height: 340px;
  height: calc(var(--vh, 1vh) * 100 - #{$header-height} - #{$widget-height} - #{$container-margin-bottom});

  &.auto {
    height: auto;
  }

  &.full-height {
    height: calc(var(--vh, 1vh) * 100 - #{$header-height} - #{$container-margin-bottom});

    .not-found-poster {
      justify-content: center;

      &::before,
      &::after {
        top: 190px;
      }
    }
  }
}
