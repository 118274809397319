@import '~common/styles/variables';

.modal-balance-board-back {
  font-size: 0;
}

@media screen and (max-width: $media-xl) {
  .preview-balance-board-modal-mobile {
    width: 70vw;

    .right-action {
      min-width: 246px;

      .btn {
        white-space: nowrap;
      }
    }
  }
}

@media screen and (max-width: $media-lg) {
  .preview-balance-board-modal-mobile {
    width: 100vw;

    .scrollbar-container {
      min-width: auto;
      max-width: 100%;
      width: 100%;
    }
  }
}

@media screen and (max-width: $media-sm) {
  .preview-balance-board-modal-mobile {
    padding: 0;

    .modal-balance-board-back {
      display: block;
      font-size: 24px;
      line-height: 14px;
      color: $color-font-main;
    }

    .balance-board-back-link {
      position: absolute;
      top: 20px;
      right: 20px;
      margin: 0;
      height: 14px;
      width: 14px;
      padding: 0;
      font-size: 0;
      line-height: 0;
    }

    .mobile-job-title {
      width: 90%;
      font-size: 28px;
      margin: 10px 0 15px;
    }

    .card {
      min-height: auto;
    }

    .modal-header {
      flex-wrap: wrap;
      margin-bottom: 8px;

      .left-side {
        width: 100%;

        .branches-container {
          margin-bottom: 10px;
        }

        & > a {
          line-height: 1.43;
          padding: 7px 11px;
          margin-right: 14px;
          letter-spacing: .11px;
        }
      }

      .right-action > .btn {
        min-width: 280px;
      }
    }
  }

  .competencies-wrapper {
    .balance-board-info {
      margin: 0 0 25px;
    }
  }
}
