@import '~common/styles/variables';

.daily-modal-container {
  .lifestyle-caption {
    display: flex;
    justify-content: space-between;

    .error-points {
      color: $color-danger;
    }
  }

  .lifestyle-field {
    display: flex;

    .points {
      display: flex;
      max-width: 130px;
      margin-left: 1rem;
      align-items: center;

      .form-control.input-time {
        min-width: 100px !important;
        margin-top: 9px;
      }
    }
  }

  .daily-content {
    padding: 24px;
  }
}
