@import '~common/styles/variables';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

.companies-container {
  .companies-list {
    padding: 0 0 20px;

    .company {
      margin-right: 10px;
    }

    .slick-slider {
      width: 100%;
    }

    .slick-list {
      padding: 10px 5px;
      margin: 0 -5px;
    }

    .slick-dots {
      bottom: -12px;

      li {
        margin: 0 4px;
        width: auto;
        height: auto;

        &.slick-active {
          button {
            background: $color-font-main;
            border-color: $color-font-main;
          }
        }
      }

      button {
        padding: 0;
        width: 8px;
        height: 8px;
        border: solid 1px $soft-dark-text;
        border-radius: 50%;

        &:before {
          content: none;
        }
      }
    }
  }
}

@media screen and (max-width: $media-lg) {
  .companies-container {
    .companies-list {
      .slick-list {
        margin: 0 0 0 -5px;
      }
    }
  }
}

@media screen and (max-width: $media-sm) {
  .companies-container {
    .title {
      font-size: 22px;
    }
  }
}

@media screen and (max-width: $media-xs) {
  .companies-container {
    margin: 0 -15px;

    .title {
      font-size: 18px;
      line-height: 1.33;
      letter-spacing: .4px;
      margin: 25px 0 5px;
      padding: 0 12px;
    }

    .companies-list {
      .company {
        height: 120px;
        padding: 10px 15px;

        &:after {
          right: 20px;
          bottom: 20px;
        }

        .image-container {
          height: 59px;
          max-width: 100%;
          margin: 0 0 10px;
        }

        .offers {
          position: absolute;
          top: 12px;
          right: 12px;
          font-size: 12px;
          line-height: 1.67;
          letter-spacing: .2px;
          color: $color-font-main;
          border-radius: 14px;
          background-color: $bg-color-default;
          padding: 2px 5px;
          min-width: 67px;
          text-align: center;
        }
      }

      .slick-list {
        padding: 10px 5px 10px 13px;
      }
    }
  }
}
