@import '~common/styles/variables';

.add-documents-modal {
  .scrollbar-container {
    height: calc(var(--vh, 1vh) * 37);
  }
}


@media screen and (max-width: $media-sm) {
  .add-documents-modal {
    .scrollbar-container {
      height: 100%
    }
  }
}
