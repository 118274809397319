@import '~common/styles/variables';

@media screen and (max-width: $media-md) {
  .mobile-personal-info-section-container {

    .avatar-wrapper > img {
      width: 92px;
      height: 92px;
    }

    .row-section {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

@media screen and (max-width: $media-sm) {
  .mobile-personal-info-section-container {
    width: 80%;

    .row-section {
      width: 100%;
    }

    .person-info {
      .person-name {
        margin: 0;
        font-size: 16px;
        line-height: 1.75;
        letter-spacing: .73px;
        color: $color-text-dark;
      }

      .job-title-name {
        margin: 0;
        font-size: 14px;
        line-height: 1.71;
        letter-spacing: .64px;
      }

      .personal-address {
        line-height: 1.43;
        letter-spacing: .64px;
        color: $color-font-main;
      }
    }

    .personal-additional-info {
      flex-direction: column;

      .personal-address {
        line-height: 1.71;
        letter-spacing: .29px;
        color: $color-font-main;
      }
    }
  }
}

@media screen and (max-width: $media-xs) {
  .mobile-personal-info-section-container {
    padding: 0;
    width: 100%;
    margin-bottom: 15px;

    .avatar-info {
      width: 100%;
      margin-top: 5px;
      flex-direction: column;
      align-items: flex-start;
      box-shadow: inset 0 -1px 0 0 $color-muted;
    }

    .person-info {
      padding: 0;

      .person-name {
        margin-top: 3px;
      }

      .personal-address {
        margin-bottom: 15px;
      }
    }

    .personal-additional-info {
      min-width: auto;

      .personal-address {
        margin-bottom: 2px;
      }
    }
  }
}
