@import '~common/styles/variables';

.inside-container {
  margin: 30px 0;
  height: 312px;
  display: flex;
  overflow: hidden;
  position: relative;
  background: $color-muted;
  align-items: center;

  .inside-content {
    display: flex;
    align-items: center;
    flex-direction: column;

    &::after {
      right: 32%;
      width: 98px;
      height: 126px;
      bottom: -32px;
      content: '';
      position: absolute;
      background: url('/images/information/plant-left.svg') no-repeat center/cover;
    }
  }

  .inside-title {
    font: normal 34px/1 'Lato', sans-serif;
    color: $color-text-dark;
    margin-bottom: 20px;
  }

  .inside-description {
    font: normal 18px/1.33 'Lato', sans-serif;
    color: $color-font-main;
    width: 40%;
    text-align: center;
    letter-spacing: .4px;
  }

  .inside-control {
    margin-top: 30px;

    .btn {
      text-align: center;
      width: 184px;
    }
  }
}
