@import '~common/styles/variables';

@media screen and (max-width: $media-sm) {
  .empty-search-container {
    &.mobile-empty-jobs-list {
      padding-top: 50px;

      .image-content {
        min-width: calc(100vw - 60px);

        .btn {
          display: none;
        }
      }
    }
  }
}
