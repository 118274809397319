@import '~common/styles/variables';

.questions-container {
  height: 248px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  &::after {
    bottom: 0;
    content: '';
    position: absolute;
    right: 32%;
    width: 89px;
    height: 68px;
    background: url('/images/landing/humaaan.svg') no-repeat center/cover;
  }

  &::before {
    bottom: 0;
    content: '';
    position: absolute;
    left: 32%;
    width: 101px;
    height: 112px;
    background: url('/images/landing/group-4.svg') no-repeat center/cover;
  }

  .btn {
    width: 200px;
    text-align: center;
    margin-top: 20px;
    margin-right: 0;
  }

  .title {
    font: normal 28px/1.8 'Lato', sans-serif;
    color: $color-text-dark;
    letter-spacing: 0.6px;
  }

  .description {
    font: normal 18px/2 'Lato', sans-serif;
    color: $color-font-main;
    opacity: .6;
    letter-spacing: .6px;
  }
}
