@import '~common/styles/variables';

.jumbotron-container {
  height: 560px;
  background: url('/images/landing/landing.jpg') no-repeat center/cover;

  & > .container {
    height: 100%;
    position: relative;
  }

  .candidates-search,
  .job-search {
    .notes {
      top: 13px;
      color: transparent;
      width: 21px;
      height: 21px;
      display: inline-block;
    }

    .country {
      .notes {
        background: url('/images/landing/icon-location.svg') no-repeat center/cover;
      }
    }

    .brand {
      .notes {
        background: url('/images/landing/search.svg') no-repeat center/cover;
      }
    }

    .form-control {
      padding-left: 50px;
    }
  }

  .information-container {
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;

    .background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      filter: blur(5px);
    }

    .title {
      font: normal 42px/1.05 'Lato', sans-serif;
      color: #fff;
      margin: 0;
      letter-spacing: 0.9px;
    }

    .description {
      font: normal 24px/1.17 'Lato', sans-serif;
      color: #fff;
      opacity: 0.6;
      letter-spacing: 0.5px;
      margin: 10px 0 30px;
    }
  }

  .search-container {
    width: 480px;

    .btn {
      width: 100%;
      margin-top: 15px;
    }

    .btn[disabled] {
      margin-bottom: 0.5rem;
    }

    .disable-msg {
      position: relative;
      left: 20%
    }

    .form-label-title {
      display: none;
    }

    .form-label {
      margin-bottom: 20px;
    }

    .multi-select-container {
      .select__control {
        position: relative;
        padding-left: 50px;

        .select__input,
        .select__single-value {
          padding-left: 0;
        }

        &::before {
          left: 15px;
          width: 21px;
          height: 21px;
          content: '';
          position: absolute;
          background: url('/images/landing/search.svg') no-repeat center/cover;
        }
      }

      .select__placeholder {
        padding-left: 2px;
      }
    }

    .search-content {
      padding: 30px 25px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .3);
      background: $color-muted;
      border-radius: 0 0 4px 4px;
    }

    .search-tabs {
      display: flex;
      justify-content: space-between;

      .tab {
        width: calc(50% - 4px);
        height: 44px;
        color: #fff;
        cursor: pointer;
        background: rgba(#bdc9df, .4);
        text-align: center;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background: rgba(#bdc9df, .7);
          transition: .3s background-color;
        }

        &.active {
          color: $color-text-dark;
          width: 50%;
          height: 48px;
          border-radius: 4px 4px 0 0;
          background: $color-muted;
        }
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .jumbotron-container .search-container .disable-msg {
    left: 0;
  }
}
