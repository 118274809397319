@import '~common/styles/variables';

.ex-list-xl {
  width: 100%;
  border-spacing: 0 8px;

  tr {
    position: relative;
    box-shadow: 0 0 0 0 $box-shadow-color;
    border-radius: 4px;
    transition: box-shadow .15s;
    cursor: pointer;

    td {
      border-top: solid 1px $color-muted;
      border-bottom: solid 1px $color-muted;
      transition: border-color .15s;
      border-left: 0;
      border-right: 0;

      &:first-child {
        border-left: solid 1px $color-muted;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        padding-left: 16px;
      }

      &:last-child {
        border-right: solid 1px $color-muted;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        padding-right: 12px;
      }

      .cell {
        display: flex;
        padding: 8px;
        flex-wrap: wrap;
        min-height: 60px;
        align-items: center;
        align-content: center;

        div {
          width: 100%;
        }
      }
    }

    &:hover {
      text-decoration: none;
      box-shadow: 0 2px 4px 0 $box-shadow-color;
    }

    &:active {
      box-shadow: 0 0 0 0 $box-shadow-color;

      td {
        border-color: $color-accent;
      }
    }

    .actions {
      width: 1%;
    }
  }
}
