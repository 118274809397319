@import '~common/styles/variables';

.banners-container {
  display: flex;
  justify-content: space-between;

  .recruiters-container {
    &::after {
      background: url('/images/landing/recruiters.png') no-repeat center/cover;
    }
  }

  .candidates-container {
    &::after {
      background: url('/images/landing/candidates.png') no-repeat center/cover;
    }
  }

  .candidates-container {
    .items {
      .item {
        &:last-child {
          width: 100%;
        }
      }
    }
  }

  .recruiters-container,
  .candidates-container {
    width: calc(50% - 10px);
    margin: 20px 0;
    height: 460px;
    padding: 35px;
    display: flex;
    position: relative;
    flex-direction: column;

    &::after {
      top: 60px;
      content: '';
      position: absolute;
      width: 192px;
      height: 181px;
      right: 0;
    }

    .title {
      font: normal 28px/1.14 'Lato', sans-serif;
      color: $color-text-dark;
      letter-spacing: 0.6px;
    }

    .items {
      font: normal 14px/1.5 'Lato', sans-serif;
      opacity: .8;
      margin-top: 25px;
      letter-spacing: 0.3px;

      .item {
        width: 70%;
        position: relative;
        margin-bottom: 25px;

        &::before {
          top: 2px;
          width: 17px;
          height: 10px;
          display: inline-block;
          content: url('/images/landing/checkbox-blue.svg');
          margin-right: 10px;
          position: absolute;
        }

        .item-content {
          margin: 0;
          padding-left: 20px;
        }
      }
    }

    .banner-information {
      display: flex;
      margin-top: auto;
      align-items: center;

      .control-container {
        margin-right: 40px;

        .btn {
          width: 200px;
          text-align: center;
        }
      }
    }
  }

  .candidates-container {
    .items {
      .item {
        &::before {
          top: 2px;
          width: 17px;
          height: 10px;
          display: inline-block;
          content: url('/images/landing/checkbox-green.svg');
        }
      }
    }
  }
}
