*,
*::after,
*::before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.gdpr-main {
    position: relative;
    padding-top: 5rem;
    z-index: 0;
}

.main-content {
    padding: 0;
}

.main-content .main-title {
    position: sticky;
    top: 0;
    z-index: 99999;
    background: #fff;
    padding: 2rem 0;
}

.main-content .skill-item {
    margin-bottom: .5rem;
}

.main-content .back-link {
    position: relative;
    padding-left: 38px;
}

.main-content .back-link::before {
    top: 9px;
    left: 11px;
    width: 17px;
    position: absolute;
    margin-right: 15px;
    display: inline-block;
    content: url("../icons/left-arrow.svg");
}

.main-content .ui.card {
    height: 100%;
    width: 100%;
}

.main-content .ui.card  .logo-container {
    position: relative;
    text-align: center;
    margin-bottom: 1rem;
}

.main-content .ui.card  .logo-container .icon {
    width: 70px;
    height: 70px;
    display: inline-block;
    background-size: cover;
}

.personal-info .logo-container .icon {
    background-image: url(../icons/user.svg);
}

.job-offers .logo-container .icon {
    background-image: url(../icons/job.svg);
}

.purchases .logo-container .icon {
    background-image: url(../icons/shopping-cart.svg);
}

.invited-recruiters .logo-container .icon {
    background-image: url(../icons/invitation.svg);
}

.invites .logo-container .icon,
.invited-candidates .logo-container .icon {
    background-image: url(../icons/invitation.svg);
}

.candidate-followed .logo-container .icon {
    background-image: url(../icons/job.svg);
}

.favorite-companies .logo-container .icon,
.job-offer-following .logo-container .icon {
    background-image: url(../icons/follow.svg);
}

.company-following .logo-container .icon,
.companies-following-candidate .logo-container .icon,
.favorite-job-offers .logo-container .icon {
    background-image: url(../icons/follow-company.svg);
}

.purchases-companies .logo-container .icon,
.black-list .logo-container .icon {
    background-image: url(../icons/list.svg);
}

.chats .logo-container .icon {
    background-image: url(../icons/chat.svg);
}

.cv .logo-container .icon {
    background-image: url(../icons/resume.svg);
}

.job-offer-viewed .logo-container .icon,
.companies-viewed-profile .logo-container .icon {
    background-image: url(../icons/eye.svg);
}

.candidate-posts .logo-container .icon {
    background-image: url(../icons/risky-content.svg);
}

.candidate-application .logo-container .icon,
.candidate-application-template .logo-container .icon {
    background-image: url(../icons/risky-content.svg);
}

.main-title {
    text-align: center;
    margin-bottom: 3rem !important;
}