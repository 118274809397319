@import '~common/styles/variables';

.match-candidates-list {
  height: 100%;

  .title {
    display: flex;
    align-items: center;
  }

  .candidates-header {
    display: flex;
    padding: 10px 20px 10px;
    font-size: 18px;
    border-bottom: 1px solid $border-light-color;
    justify-content: space-between;
  }

  .filter-container {
    .filter-label {
      font-size: 16px;
      padding: 8px 16px;
    }
  }

  .candidates-items {
    height: calc(100% - 78px);
  }
}
