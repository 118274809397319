@import '~common/styles/variables';

@media screen and (max-width: $media-xs) {
  .init-password-footer {
    padding: 25px 0 65px;

    span {
      display: block;
    }

    .send-again-button {
      padding: 0;
      margin: 15px 0 0;
    }
  }

  .reset-instructions {
    margin-top: 20px;
    margin-bottom: 40px;
  }
}
