@import '~common/styles/variables';

@import '~common/styles/variables';

$profile-modal-breakpoint-medium: 1160px;
$profile-modal-breakpoint-big: 1660px;

.setting-modal-container {
  width: 60vw;
  cursor: auto;
  display: flex;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  padding: 1rem 40px;
  background: $bg-color-default;
  box-shadow: 0 5px 21px 0 rgba(66, 101, 159, 0.2);

  .card {
    overflow: auto;
  }
}

.profile-modal {
  .card {
    position: relative;
    overflow-y: scroll;
  }
}

.profile-tab-caption {
  font-size: 24px;
  margin: 0;
}

@media all and (max-width: $profile-modal-breakpoint-big) {
  .profile-modal {
    width: 70vw;
  }
}

@media all and (max-width: $profile-modal-breakpoint-medium) {
  .profile-modal {
    width: 95vw;
  }
}

.candidate-profile-modal {
  z-index: 999;

  & .profile-sidebar {
    & .profile-menu-list > li {
      color: $color-primary;

      &:hover,
      &.active {
        background: rgba(112, 181, 58, .07);
      }
    }
  }
}
