@import '~common/styles/variables';

.personal-info-section-container {
  width: 100%;
  padding: 6px;

  .avatar-wrapper > img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid $color-muted;
  }

  .row-section {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .avatar-info {
    display: flex;
    align-items: center;
  }

  .person-info {
    padding-left: 1rem;

    .person-name {
      margin: 0 0 5px;
      font-size: 22px;
      font-weight: bold;
    }

    .job-title-name {
      margin: 0 0 5px;
      color: $color-primary;
      font-size: 20px;
    }

    .personal-address {
      margin: 0;
      color: #505F79;
      font-size: 14px;
    }
  }

  .personal-additional-info {
    display: flex;
    min-width: 450px;
    justify-content: space-between;

    .personal-address {
      color: #505F79;
      font-size: 14px;
      line-height: 10px;
      margin: 17px 0 8px 0;
    }

    .info-value {
      margin: 0;
      font-size: 1rem;
    }
  }
}
