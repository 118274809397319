@import '~common/styles/variables';

.company-info-form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.upload-avatar-container .avatar-error {
  top: 95px;
}

.zip-code > label {
  width: 160px;
}

.billing-number > label {
  width: 150px;
}

.upload-btn-container {
  margin-bottom: 8px;

  & > label {
    line-height: 2.5;

    & > span {
      color: #bdc9df;
    }
  }

  & > .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    position: relative;

    & > img {
      margin-right: 12px;
    }

    &.disabled {
      pointer-events: none;
      opacity: .5;
      cursor: not-allowed;
    }
  }

  & .image-size-error {
    font-size: 14px;
    color: $color-danger;
  }

  .avatar-error {
    top: 97px;
  }
}
