@import '~common/styles/variables';

$light-gray: #3b475a;

.balance-board-poster {
  display: flex;
  padding: 20px;
  height: 80%;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .title {
    color: $color-text-dark;
    font-size: 24px;
    letter-spacing: 0.2px;
  }

  .poster {
    margin: 30px 0;
  }

  .actions {
    margin-top: 20px;
  }
}
