@import '~common/styles/variables';

@media screen and (max-width: $media-sm) {
  .auth-form-body {
    padding: 10px 20px;

    .auth-form-title {
      max-width: 57%;
      line-height: 1.35;
      margin: 8px 0 15px;
      letter-spacing: .7px;
    }
  }
}

@media screen and (max-width: $media-xs) {
  .auth-form-body {
    .auth-form-title {
      max-width: 87%;
    }
  }
}
