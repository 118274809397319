@import '~common/styles/variables';

.beta-intro-modal + .modal-overlay.overlayed {
  background: rgba(122, 140, 170, 0.4);

  @media screen and (max-width: $media-sm) {
    z-index: 0;
  }
}

.beta-intro-modal {
  &.open {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;

    width: 810px;
    max-width: 100%;
    height: 510px;
    max-height: 100%;

    @media screen and (max-width: $media-sm) {
      height: 600px;
      padding: 10px;
    }

    @media screen and (max-width: $media-xs) {
      height: 100%;
    }
  }

  .modal-container {
    @media screen and (min-width: $media-sm) and (max-width: $media-md) {
      margin: 0 30px;
    }
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 30px;
    text-align: center;
    overflow-y: scroll;

    @media screen and (max-width: $media-xs) {
      padding: calc((100vh - 520px) / 2) 10px;
    }
  }
}

.beta-intro-header {
  margin-bottom: 20px;

  @media screen and (max-width: $media-xs) {
    margin-bottom: 35px;
  }

  .beta-intro-header-logo {
    display: inline-flex;
    align-items: center;

    .logo {
      width: 210px;
      height: 40px;
    }
  }

  .modal-label {
    display: inline-block;
    width: 47px;
    height: 20px;

    font-size: 14px;
    font-weight: 300;
    line-height: 1.4;
    letter-spacing: 0.3px;
    text-align: center;
    text-transform: uppercase;

    color: $color-font-main;
    border-radius: 4px;
    background-color: $bg-color-default;
  }
}

.beta-intro-body {
  @media screen and (min-width: $media-xs) {
    flex-grow: 1;
  }
}

.beta-intro-controls {
  .btn.large {
    min-width: 200px;
    height: 44px;
  }
}
