@import '~common/styles/variables';

.modal-ask-me-back {
  display: none;
}

@media screen and (max-width: $media-xl) {
  .mobile-ask-me-modal {
    width: 70vw;
  }
}

@media screen and (max-width: $media-lg) {
  .mobile-ask-me-modal {
    width: 95vw;
  }
}

@media screen and (max-width: $media-md) {
  .mobile-ask-me-modal {
    width: 100vw;

    .content {
      width: 100%;
      min-width: auto;
      max-width: 100%;
    }

    .modal-btn {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      font-size: 0;
    }

    .card.ask-me-container .ask-me-content .ask-me-preview {
      padding: 20px 0 0 0;
    }

    .modal-ask-me-back {
      display: block;
      font-size: 24px;
      line-height: 14px;
      margin-right: 10px;
      color: $color-font-main;
    }
  }
}

@media screen and (max-width: $media-sm) {
  .mobile-ask-me-modal {
    padding: 0;

    & .card.ask-me-container {
      max-width: 100%;
    }
  }
}
