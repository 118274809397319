@import '~common/styles/variables';

@media screen and (max-width: $media-xs) {
  .image-crop-modal {
    overflow: auto;
    min-width: 100vw;
    max-width: 100vw;
    max-height: calc(100vh - 47px);
    min-height: calc(100vh - 47px);

    .image-crop-container {
      .button-container {
        justify-content: center;
      }
    }
  }
}
