@import '~common/styles/variables';

.mobile-count,
.mobile-filter-buttons-show {
  display: none;
}

@media screen and (max-width: $media-md) {
  .default-filter-title.mobile-filter-title {
    font-size: 0;
    padding-bottom: 0;
  }

  .default-filter.mobile-filter {
    &.scrollbar-container {
      height: calc(var(--vh, 1vh) * 100 - 32px - 150px - 28px - 93px);
      padding-bottom: 70px;
    }
  }

  .default-filter-buttons.mobile-filter-buttons {
    padding: 10px 30px;
    justify-content: flex-start;

    .apply {
      width: auto;
    }

    .reset {
      width: auto;
    }
  }
}


@media screen and (max-width: $media-sm) {
  .default-filter.mobile-filter {
    margin-right: 0;

    &.scrollbar-container {
      box-shadow: none;
      height: calc(var(--vh, 1vh) * 100 - 150px - 73px);
    }
  }

  .mobile-filter-buttons-hide {
    display: none;
  }

  .mobile-filter-buttons-show {
    display: block;
  }

  .mobile-count {
    display: inline;
  }
}

@media screen and (max-width: $media-xs) {
  .default-filter.mobile-filter {
    padding: 0;

    &.scrollbar-container {
      box-shadow: none;
      height: calc(var(--vh, 1vh) * 100 - 150px - 73px);
    }

    .title {
      padding: 23px 30px 0;
    }

    .general-filters {
      padding: 0 21px;
      margin-bottom: 8px;
      box-shadow: $box-shadow-mobile;
    }

    .additional-filter {
      padding: 10px 21px 0;
    }

    .react-datepicker {
      .react-datepicker__header {
        .react-datepicker__day-names {
          padding: 9px 0;
        }
      }
    }
  }

  .default-filter-buttons.mobile-filter-buttons {
    box-shadow: none;
    padding: 10px 20px;

    .btn {
      order: 0;
      width: 100%;
      padding: 11px 0;
      white-space: nowrap;
    }

    .apply {
      order: 1;
      margin-right: 0;
    }

    .reset {
      max-width: 112px;
      margin-right: 8px;
    }
  }
}

@media screen and (max-width: $media-md) and (max-height: $media-xs) {
  .default-filter.mobile-filter {
    background-color: #fff;

    &.scrollbar-container {
      height: 100%;
    }
  }

  .default-filter-buttons.mobile-filter-buttons {
    background-color: #fff;
  }
}
