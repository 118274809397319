.general-info-form {
  width: 80%;
  min-width: 700px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  .general-info-select-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 38px;
  }

  .salary-type {
    margin-top: 9px;
  }

  .form-buttons {
    bottom: 0;
    z-index: 100;
    position: sticky;
    background: #fff;
    padding-top: 10px;
    box-shadow: 0 -23px 20px 0 #fff;
  }

  .board-form-field > label {
    line-height: 1.5;
    vertical-align: middle;
    margin-right: 8px;
    margin-bottom: 10px;
    min-height: 24px;
  }

  .available-from {
    max-width: 220px;

    .react-datepicker-popper {
      z-index: 100;
    }
  }
}
