@import '~common/styles/variables';

@media screen and (max-width: $media-lg) {
  .plans-container {
    padding: 100px 0 70px;

    .plans-title {
      font-size: 36px;
    }

    .plans-description {
      margin-bottom: 50px;
    }
  }
}

@media screen and (max-width: $media-md) {
  .pricing-container {
    .customers-container,
    .comment-container {
      display: none;
    }
  }

  .plans-container {
    .plans-title {
      font-size: 28px;
      line-height: 1.14;
      letter-spacing: .6px;
    }

    .plans-collection {
      .plan {
        width: calc(50% - 40px);
      }
    }
  }
}

@media screen and (max-width: $media-sm) {
  .plans-container {
    padding: 90px 0 55px;

    .plans-description {
      margin-bottom: 10px;
    }

    .plans-collection {
      flex-wrap: wrap;
      justify-content: center;

      .plan {
        width: 296px;
        max-width: 100%;
        margin: 0 20px;

        &.premium {
          margin-bottom: 14px;
        }
      }
    }
  }
}

@media screen and (max-width: $media-xs) {
  .pricing-container {
    box-shadow: $box-shadow-mobile;
  }

  .plans-container {
    padding: 90px 0 40px;

    .plans-title {
      text-align: center;
    }

    .plans-collection {
      .plan {
        height: auto;
        margin: 0 auto;

        .plan-header {
          line-height: .8;
          letter-spacing: .5px;
        }

        .plan-content {
          .plan-control {
            padding: 13px 16px 20px;
          }

          .plan-points {
            padding: 35px 15px 0;
            height: auto;
            margin: 0 0 30px;

            .plan-point {
              line-height: 1.71;
              letter-spacing: .1px;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
