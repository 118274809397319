@import '~common/styles/variables';


@media screen and (max-width: $media-xs) {
  .switch-control {
    display: flex;
    flex-direction: column;

    .switch-control-item {
      margin: 0 0 12px;
      text-align: center;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
