@import '~common/styles/variables';

.modal-create-blog-title,
.modal-post-back {
  display: none;
}

@media screen and (max-width: $media-lg) {
  .create-post-modal {
    .card {
      max-width: 100%;
      min-width: 1px;
    }

    .content {
      width: 100%;
    }
  }
}

@media screen and (max-width: $media-md) {
  .create-post-modal {
    .modal-create-blog-title {
      display: block;
      font-size: 24px;
      line-height: 1.17;
      letter-spacing: .2px;
      color: $color-text-dark;
      padding: 30px 20px 0;
    }

    .modal-post-back {
      display: block;
      font-size: 24px;
      line-height: 14px;
      color: $color-font-main;
    }

    .posts-back-link {
      position: absolute;
      top: 20px;
      right: 20px;
      margin: 0;
      height: 14px;
      width: 14px;
      padding: 0;
      font-size: 0;
      line-height: 0;
    }

    .content {
      height: 100%;

      .card {
        height: auto;
        min-height: 100%;
      }
    }
  }

  .create-post-form {
    padding: 30px 20px;
  }
}

@media screen and (max-width: $media-xs) {
  .create-post-modal {
    .card {
      overflow-y: scroll;
      position: relative;
    }

    .form-btn-submit {
      height: 64px;
      width: calc(100% + 40px);
      position: relative;
      left: -20px;
      padding: 12px 20px;
      box-shadow: 0 -1px 0 0 #e5eefc;
      margin: 35px 0 0;

      a.btn {
        display: none;
      }
    }
  }

  .create-post-form {
    padding: 30px 20px 0;
  }
}
