@import '~common/styles/variables';

.iban-form {
  margin-bottom: 12px;

  .form-group-row {
    display: flex;
    justify-content: space-between;
  }

  .form-row {
    margin-bottom: 12px;
    text-align: left;
  }

  .form-col {
    width: calc(50% - 10px);
  }

  .form-col-label {
    line-height: 2.5;
    letter-spacing: 0.12px;
    color: $color-font-dark;
    white-space: nowrap;
  }

  .form-col-input,
  .form-col-element {
    width: 100%;
    padding: 10px 16px;
    font-size: 1rem;
    font-weight: 600;
    color: $color-font-dark;
    border: 1px solid #b2cbf4;
    background-color: #fff;
    border-radius: 4px;
    transition: border-color .2s;
  }

  .success,
  .error {
    margin-bottom: 12px;
  }

  .error-text {
    color: $color-danger;
    font-size: 18px;
  }

  .success-text {
    color: $color-primary;
    font-size: 18px;
  }

  .btn {
    margin-top: 20px;
  }
}
