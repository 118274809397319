.general-info-form {
  width: 80%;
  display: flex;
  min-width: 700px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  .form-group.reference-number {
    max-width: 300px;
  }

  .form-buttons {
    margin-top: 40px;
  }
}
