@import '~common/styles/variables';

$slot-border: #E7EEFB;

.modal.invite-candidate-modal {

  .modal-content {
    min-width: 472px;
  }

  .job-offer-list {
    padding: 20px 0;
  }

  & + .modal-wrapper {
    z-index: 1002;
    background: rgba(255, 255, 255, .5);
  }

  .invites-modal {
    min-width: 480px;
  }

  .invite-candidate-modal-content {
    min-height: 345px;
    padding: 20px;

    .add-more {
      img {
        position: relative;
        right: 10px;
        top: 2px;
      }
    }

    .description {
      font-size: 14px;
      line-height: 1.5;
    }
  }

  .modal-caption {
    font-weight: bolder;
    border-radius: 4px 4px 0 0;
  }

  .time-slots {
    padding: 0 0 20px;

    .react-datepicker-wrapper,
    .react-datepicker__input-container {
      display: block;
      width: 100%;
    }

    .slot-header {
      & + .slot {
        padding-top: 12px;
      }
    }

    .slot {
      padding: 15px 0;
      border-bottom: 1px solid $slot-border;
    }

    .slot,
    .slot-header {
      display: flex;

      .date {
        width: 55%;
        padding-right: 10px;
      }

      .time {
        width: 35%;
      }

      .action {
        width: 10%;
        text-align: right;
        padding-top: 3px;

        .close {
          opacity: .3;
        }
      }

      .form-label-title,
      .notes {
        display: none;
      }
    }
  }
}

.modal-actions {
  padding: 14px 20px;
}
