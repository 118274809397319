@import '~common/styles/variables';

.payment-invoice-details {
  margin-bottom: 50px;

  &-title {
    font-weight: bold;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    color: $color-font-dark;
  }

  .company-info-default {
    margin-bottom: 40px;
  }

  .company-info-default-title {
    font-size: 14px;
    letter-spacing: 0.3px;
    color: $color-font-main;
  }

  .company-info-form {
    margin-bottom: 45px;
    flex-direction: column;
    align-items: flex-start;
  }

  .form-group.half-width {
    width: calc(50% - 10px);
  }

  .form-group-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .payment-action {
    width: 300px;
    padding-top: 16px;
  }

  .payment-action.half-width {
    width: calc(50% - 10px);
  }

  &.disabled,
  & .disabled {
    pointer-events: none;
    opacity: .5;
  }

  .btn.large {
    width: 200px;
    max-width: 100%;
  }
}

.payment-footer {
  &.disabled {
    pointer-events: none;
    opacity: .5;
  }
}

.flex-basis-auto {
  flex: 1 0 auto;
}

.m-0 {
  margin: 0;
}
