@import '~common/styles/variables';


@media screen and (max-width: $media-xl) {
  .cv-section-container {
    .edit-icon-container {
      &::after {
        top: 3px;
        left: 4px;
      }
    }
  }
}

@media screen and (max-width: $media-sm) {
  .mobile-cv-section-container {
    .section-title {
      font-size: 14px;
      line-height: 1.71;
      margin: 20px 0 15px;
      letter-spacing: .29px;
    }

    .edit-icon-container {
      border: none;
      background-color: $bg-color-default;

      &::after {
        top: 4px;
      }
    }
  }
}

@media screen and (max-width: $media-xs) {
  .mobile-cv-section-container {
    position: relative;

    .cv-section-content {
      border: 0;
      border-radius: 0;
      margin-left: -20px;
      margin-right: -20px;
      padding: 0 20px 14px;
      border-bottom: 1px solid $color-muted;
    }

    .mobile-edit-icon {
      top: 0;
      right: 0;
      position: absolute;
    }

    .edit-icon-container {
      border: none;
      background-color: $bg-color-default;

      &::after {
        top: 4px;
      }
    }
  }
}
