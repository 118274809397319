@import '~common/styles/variables';

@media screen and (max-width: $media-xl) {
  .modal {
    &.mobile-delete-post {
      transform: translate(0, 0);
    }
  }
}

@media screen and (max-width: $media-sm) {
  .modal {
    &.mobile-delete-post {
      z-index: 10001;
      transform: translate(0, 0);
    }
  }
}

@media screen and (max-width: $media-xs) {
  .modal {
    &.mobile-delete-post {
      z-index: 10001;
      width: 90%;
      margin: 0 auto;
    }
  }

  .delete-modal-content {
    max-width: 320px;
  }

  .delete-action {
    flex-direction: column;

    .btn:first-child {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }
}
