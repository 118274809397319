@import '~common/styles/variables';

.menu-item {
  padding: 11px 24px;
  font-weight: bold;
  cursor: pointer;
  color: $color-font-dark;

  &.active,
  &:hover {
    background-color: $color-muted;
  }

  &.disabled {
    cursor: auto;
    opacity: .4;
    pointer-events: none;
  }
}
