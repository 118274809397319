@import '~common/styles/variables';

@media screen and (max-width: $media-sm) {
  .auth-wrapper {
    min-height: calc(var(--vh, 1vh) * 100);
    height: auto;

    .card {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .logo-container {
      text-align: left;
      padding: 0 10px;
      background: #fff;
      box-shadow: $box-shadow-mobile;

      img {
        margin: 28px auto 20px;
      }
    }
  }
}
