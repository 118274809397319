@import '~common/styles/variables';

.post-item {
  padding: 1rem 1rem 2rem;
  cursor: pointer;
  max-width: 360px;
  min-width: 360px;
  border-radius: 4px;
  position: relative;
  transition: box-shadow .3s;
  border: 1px solid $color-muted;
  margin: 0 13px 1rem 0;

  &:hover {
    box-shadow: 0 0 0 0 rgba(122, 140, 171, .2);
  }

  .post-item-gallery {
    display: flex;

    .post-gallery-item > img {
      height: 90px;
      max-width: 100%;
      border-radius: 5px;
      object-fit: cover;
      width: calc((360px - 10px) / 3);
    }

    .post-gallery-item:not(:last-child) {
      flex: 1;
      margin-right: 5px;
    }
  }

  .post-content {
    & ~ .chip {
      bottom: 13px;
      position: absolute;
    }

    .post-title {
      font-size: 1rem;
      font-weight: bold;
      line-height: 24px;
      color: $color-font-dark;
    }

    & > p {
      font-size: 14px;
      color: $color-font-main;
      letter-spacing: .67px;
      margin-bottom: 24px;
      max-height: 150px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
