@import '~common/styles/variables';

@media screen and (max-width: $media-md) {
  .skills-filter.mobile-filter {
    .skills-container {
      height: calc(var(--vh, 1vh) * 100 - 32px - 150px - 28px - 105px - 20px - 93px);
    }

    .title {
      font-size: 0;
      padding-bottom: 0;
    }

    .form-buttons.mobile-filter-buttons {
      padding: 10px 30px;
      justify-content: flex-start;

      .apply {
        width: auto;
      }

      .reset {
        width: auto;
      }
    }
  }
}

@media screen and (max-width: $media-sm) {
  .skills-filter.mobile-filter {
    .title {
      padding: 23px 30px 0;
    }

    .search-skills {
      padding: 0 21px;
    }

    .skills-container {
      box-shadow: none;
      height: calc(var(--vh, 1vh) * 100 - 150px - 100px - 93px);

      .skills-list {
        .checkbox-inline {
          padding: 8px 21px;
        }
      }
    }
  }
}


@media screen and (max-width: $media-xs) {
  .skills-filter.mobile-filter {
    .form-buttons.mobile-filter-buttons {
      box-shadow: none;
      padding: 10px 20px;

      .btn {
        order: 0;
        width: 100%;
        padding: 11px 0;
        white-space: nowrap;
      }

      .apply {
        order: 1;
        margin-right: 0;
        width: 100%;
      }

      .reset {
        max-width: 112px;
        margin-right: 8px;
      }
    }
  }
}

@media screen and (max-width: $media-md) and (max-height: $media-xs) {
  .skills-filter.mobile-filter {
    background-color: #fff;

    .skills-container {
      height: 100%;
    }
  }
}
