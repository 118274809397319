@import '~common/styles/variables';

.job-offer-company-info-preview {

  .restricted {
    position: relative;

    .cost-job-offer {
      top: 55%;
      display: inline-block;
      position: absolute;
      left: 43%;
      transform: translateX(-50%);
      width: 80%;
      text-align: center;
      background: $bg-color-default;
      border-radius: 14px;
      padding: 4px 10px;
      color: $color-font-dark;
      box-shadow: 0 1px 3px 0 $box-shadow-color;
      font-size: .875rem;
      z-index: 10;
    }
  }

  .job-address {
    margin: 32px 0;
    position: relative;

    .address {
      &::before {
        content: url('/images/shared/location.svg');
        position: absolute;
        left: 4px
      }
    }

    .name {
      margin: 0;
      color: $color-font-dark;
    }

    .address {
      font-size: 1rem;
      line-height: 24px;
      padding-left: 27px;
      font-style: normal;
      letter-spacing: .7px;
    }
  }

  .logo-container {
    margin: 32px 0 28px;

    .hotel-logo {
      overflow: hidden;
      text-align: center;
      padding: 1rem;
      border-radius: 4px;
      border: 1px solid $border-color;

      p {
        max-width: 80%;
        margin: 0 auto 20px;
        color: $color-font-dark;
      }

      .logo-wrapper {
        overflow: hidden;
        border-radius: 4px;
        display: inline-block;

        img {
          width: 100%;
          height: 210px;
          object-fit: cover;
        }
      }

      .btn > img {
        margin-right: 8px;
      }
    }
  }

  .job-employer-description {
    margin: 16px 0;

    .title {
      margin: 5px 0;
      font-weight: bold;
    }

    .description {
      font-size: .875rem;
      line-height: 1.71;
    }
  }
}
