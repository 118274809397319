@import '~common/styles/variables';

.account-setting-logo {
  display: none;
}

@media screen and (max-width: $media-md) {
  .account-setting-logo {
    display: block;
    background: #fff;
    padding: 0 0 25px;
  }

  .after-registration-page {
    margin: -68px auto 0;
    border-radius: 0;
    max-width: none;
    width: calc(100% + 32px);
    position: relative;
    left: -16px;
  }
}

@media screen and (max-width: $media-sm) {
  .after-registration-page {
    margin: -52px auto 0;
  }

  .candidate-content > .upload-avatar-container {
    .avatar {
      border: 1px solid $color-muted;
    }
  }

  .form-group.birthday {
    & .date-picker {
      width: 100%;

      .react-datepicker-wrapper {
        width: 100%;

        .react-datepicker__input-container {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: $media-xs) {
  .candidate-content > .upload-avatar-container {
    .upload-btn {
      max-width: 100%;
      height: 40px;
      margin-right: 12px;
      padding: 9px 16px;
      width: calc(100% - 52px);
    }
  }
}
