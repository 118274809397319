@import '~common/styles/variables';

.competencies-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .card.competencies-sticky-box {
    top: 1rem;
    right: 2px;
    height: 40px;
    z-index: 999;
    max-width: 280px;
    min-width: 100px;
    position: sticky;
    margin-top: 1rem;
    box-shadow: 0 4px 12px 0 rgba(59, 71, 90, .21);

    & > p {
      margin: 0;
      padding: 10px 12px;
    }

    & .skills-count {
      color: $color-accent;
      padding-left: 5px;
    }

    &.skills-count-error {
      & p,
      span.skills-count {
        color: $color-danger;
      }
    }

    &.skills-count-success {
      span.skills-count {
        color: $color-primary;
      }
    }
  }
}

.modal.guest-skills-modal-container {
  top: 50%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, 0);
}

.skills-requirements-form {
  .section-title {
    margin-top: 50px;
    text-transform: uppercase;
    font: bold 16px/1.5 'Lato', sans-serif;
  }

  & .languages-list,
  & .software-list,
  & .quialifications-list {
    max-width: 400px;
  }

  .form-buttons {
    margin-top: 40px;

    & .error-text {
      color: $color-danger;
    }
  }

  .element {
    padding: 8px 16px;
    margin-bottom: 20px;
  }

  & .btn.add-more {
    & > img {
      margin-right: 8px;
    }
  }

  .skills-list,
  .documents-list,
  .languages-list,
  .software-list,
  .quialifications-list {
    margin-bottom: 10px;
  }
}
