@import '~common/styles/variables';

.search-candidate-row-action-menu {
  left: -150px !important;
}

.candidate-row {
  color: #bdc9df;
  font-size: 14px;

  .candidate-avatar {
    width: 55px;

    .avatar-wrapper {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      overflow: hidden;

      img {
        object-position: center;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .cell {
    strong {
      color: $color-text-dark;
      font-size: 16px;
    }
  }

  .actions {
    .action-menu-container {
      display: flex;
      justify-content: flex-end;

      .menu-button {
        width: 34px;
        height: 34px;
        border: 1px solid $border-light-color;
        padding: 0;

        & > img {
          top: 1px;
          width: 18px;
          height: 18px;
          position: relative;
        }
      }

      .select-menu-options {
        top: 34px;
        left: auto;
        right: 0;
      }
    }
  }
}
