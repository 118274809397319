@import '~common/styles/variables';

@media screen and (max-width: $media-xs) {
  .upload-avatar-container {
    flex-wrap: wrap;
    padding: 0 0 20px;

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      order: 2;
    }

    & > .delete-btn {
      margin-right: 0;
    }
  }

  .upload-avatar-container > .avatar {
    order: 1;
    width: 92px;
    height: 92px;
    margin-bottom: 25px;
    border: 1px solid $color-muted;
  }

  .btn {
    order: 3;

    &.upload-btn {
      height: 41px;
      margin-right: 10px;
      width: calc(100% - 52px);
    }
  }

  .avatar-modal-overlay {
    z-index: 10012;
  }
}
