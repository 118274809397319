@import '~common/styles/variables';

$background-white: #fff;
$dropdown-shadow: rgba(80, 95, 121, .3);

.select-menu-container {
  position: relative;

  .select-menu {
    z-index: 999;
    height: 100%;
  }

  .select-menu-options {
    position: absolute;
    top: 3px;
    background: $background-white;
    z-index: 990;
    min-width: 200px;
    width: 100%;
    left: -105%;
    padding: 12px 0;
    box-shadow: 0 8px 15px 0 $dropdown-shadow;
    border-radius: 4px;
    border: solid 1px $color-muted;
    max-height: 300px;
    overflow-y: auto;

    .options-list {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }
}
