@import '~common/styles/variables';

@media screen and (max-width: $media-md) {
  .notifications-settings-container {
    .caption {
      margin-top: 25px;
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: $media-sm) {
  .notifications-settings-container {
    .notification-settings-action {
      padding-top: 0;
    }
  }
}

@media screen and (max-width: $media-xs) {
  .notifications-settings-container {
    .btn {
      width: 280px;
      max-width: 100%;
      margin-right: 0;
    }
  }
}
