@import '~common/styles/variables';

.modal.delete-account-modal-container {
  .modal-content {
    min-width: 440px;
    transform: translate(-30%, -50%);
  }

  .delete-account-modal-form {
    padding: 0 20px 20px;
    margin: 0;

    .delete-description {
      margin-bottom: 3rem;
    }
  }
}
