@import '~common/styles/variables';

.promo-code-group {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  .form-label {
    justify-content: flex-start;
  }

  .form-control {
    margin-right: 12px;
  }

  &.success .form-control {
    border-color: $color-primary;
  }

  &.failed .form-control {
    border-color: $color-danger;
  }

  .btn {
    height: 45px;
  }

  .icon-success {
    display: inline-block;
    height: 45px;
    margin-left: 5px;
    min-width: 110px;
    flex: 0 0 110px;
  }

  .img-responsive {
    display: block;
    width: 20px;
    height: 100%;
  }
}
