@import '~common/styles/variables';

.notifications-settings-container {
  .caption {
    margin-top: 34px;
    font-size: 14px;
    line-height: 24px;
    color: $color-font-main;
  }

  .notification-settings-action {
    padding-top: 60px;
  }
}
