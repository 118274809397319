@import '~common/styles/variables';

.modal-overlay.overlayed.open {
  z-index: 0;
}

.modal.mobile-modal-job-offer {
  display: none;
}

@media screen and (max-width: $media-xl) {
  .modal {
    &.open {
      height: calc(var(--vh, 1vh) * 100);
    }

    &.fade {
      top: 0;
      left: 0;
      max-width: 100%;

      .modal-container {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .modal-content {
          position: static;
          max-height: 100vh;
          transform: translate(0, 0);
        }
      }
    }
  }
}

@media screen and (max-width: $media-sm) {
  .modal.fade {
    max-width: 100%;
  }

  .modal.mobile-modal-job-offer {
    display: block;
    z-index: 10001;

    .modal-container {
      .modal-content {
        width: 100vw;
        margin: 0 auto;
        position: static;
        overflow: hidden;
        transform: translate(0, 0);
        height: calc(var(--vh, 1vh) * 100);
      }
    }
  }

  .modal-overlay.overlayed.open {
    z-index: 10000;
  }
}
