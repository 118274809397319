.portlet-control {
  flex: 0 0 54%;
  display: flex;

  .portlet-option {
    padding: 0 8px;
    flex: 0 0 29%;
    display: flex;
  }
}
