.modal-header-container {
  padding: 28px;
  height: auto;
  margin-bottom: 12px;

  .modal-header-back-link {
    padding: 0;
    display: inline-block;
  }

  .modal-header-caption {
    margin: 0;
    display: flex;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    align-items: center;
    font-stretch: normal;
    justify-content: space-between;
  }
}
