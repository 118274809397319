@import '~common/styles/variables';

@media screen and (max-width: $media-lg) {
  .banners-container {
    .recruiters-container,
    .candidates-container {
      height: auto;

      &::after {
        width: 125px;
        height: 115px;
      }
    }
  }
}

@media screen and (max-width: $media-md) {
  .banners-container {
    .recruiters-container,
    .candidates-container {
      &::after {
        content: none;
      }

      .title {
        font-size: 24px;
      }

      .items {
        font-size: 16px;
        margin-top: 15px;

        .item {
          width: 100%
        }
      }
    }
  }
}

@media screen and (max-width: $media-sm) {
  .banners-container {
    flex-wrap: wrap;

    .recruiters-container,
    .candidates-container {
      width: 100%;
      padding: 40px 28px 28px;
    }

    .candidates-container {
      margin-top: 0;
    }
  }
}

@media screen and (max-width: $media-xs) {
  .banners-container {
    flex-direction: column-reverse;
    margin: 0 -15px;

    .recruiters-container,
    .candidates-container {
      margin: 0;
      border-radius: 0;

      .banner-information {
        .control-container {
          margin-right: 0;
          width: 100%;

          .btn {
            width: 100%;
          }
        }
      }
    }

    .candidates-container {
      padding-bottom: 0;
      box-shadow: none;
    }
  }
}
