@import '~common/styles/variables';

.auth-form-container {
  .agreement {
    text-align: center;
    font-size: .875rem;
    font-style: italic;
    padding: 0 40px;

    a {
      color: $color-accent;
    }
  }
}
