@import '~common/styles/variables';

.match-container {
  display: flex;
  padding: 60px 0;
  align-items: center;
  flex-direction: column;

  .match-title {
    font: normal 34px/1 'Lato', sans-serif;
    color: $color-text-dark;
    margin-bottom: 15px;
  }

  .match-description {
    font: normal 18px/1.33 'Lato', sans-serif;
    color: $color-font-main;
    width: 45%;
    text-align: center;
    letter-spacing: .4px;
    margin-bottom: 35px;
  }

  .match-image {
    box-shadow: 0 4px 16px 0 rgba(59, 71, 90, .21);;

    img {
      width: 100%;
      display: flex;
    }
  }
}
