@import '~common/styles/variables';

.start-as-container {
  display: flex;
  justify-content: space-between;
  max-width: 1000px;
  margin: 0 auto;

  .recruiters-container {
    &::after {
      background: url('/images/landing/leaf.svg') no-repeat center/cover;
    }
  }

  .candidates-container {
    &::after {
      background: url('/images/landing/combined-shape.svg') no-repeat center/cover;
    }
  }

  .recruiters-container,
  .candidates-container {
    width: calc(50% - 10px);
    margin: 20px 0;
    height: 296px;
    padding: 25px;
    display: flex;
    position: relative;
    flex-direction: column;

    &::after {
      top: 10px;
      content: '';
      position: absolute;
      right: 0;
      width: 40px;
      height: 66px;
    }

    .title {
      font: normal 28px/1.14 'Lato', sans-serif;
      color: $color-text-dark;
      letter-spacing: 0.6px;
    }

    .items {
      font: normal 14px/1.5 'Lato', sans-serif;
      width: 80%;
      opacity: .8;
      margin-top: 10px;
      letter-spacing: 0.3px;

      .item {
        margin-bottom: 10px;
      }
    }

    .banner-information {
      display: flex;
      margin-top: auto;
      align-items: center;

      .control-container {
        margin-right: 40px;

        .btn {
          width: 200px;
          display: block;
          text-align: center;
        }

        .login-link {
          display: inline-block;
          margin-top: 10px;
          font: bold 16px/1.75 'Lato', sans-serif;
          letter-spacing: 0.6px;
          text-decoration: none;
          color: $color-font-dark;
          border-bottom: 1px solid;
        }
      }
    }
  }
}
