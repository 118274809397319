@import '~common/styles/variables';

.general-job-offer-modal-preview {
  .job-title {
    margin: 0;
    font-size: 1.5rem;
    font-weight: bold;
    color: $color-text-dark;
  }

  .additional-info {
    padding: 14px 0;
    display: flex;
    align-items: center;
  }

  .branches {
    & span {
      font-size: 14px;
      line-height: 20px;
      margin-right: 12px;
      text-transform: uppercase;
      letter-spacing: 0.78px;
      color: $color-font-main;
    }
  }

  .job-summary-info {
    margin: 0;
    padding: 10px 0;
    list-style: none;

    li {
      padding: 4px 0;
      line-height: 1.5;

      b {
        margin-right: 10px;
        color: $color-text-dark;
      }
    }
  }
}
