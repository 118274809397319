@import '~common/styles/variables';

.preview-job-offer-modal {
  height: calc(var(--vh, 1vh) * 100);
  width: 65vw;
  cursor: auto;
  overflow: hidden;
  padding: 1rem 40px;
  background: #f4f5f7;
  box-shadow: 0 5px 21px 0 rgba(66, 101, 159, 0.2);

  .modal-columns-container .modal-columns .aside-info {
    width: 350px;
  }

  .modal .modal-container .modal-content {
    transform: translate(50%, -22%);
  }

  .job-title {
    margin: 0;
    font-size: 1.5rem;
    font-weight: bold;
    color: $color-text-dark;
  }

  .additional-info {
    padding: 14px 0;
    display: flex;
    align-items: center;
  }

  .branches {
    & span {
      font-size: 14px;
      line-height: 20px;
      margin-right: 12px;
      text-transform: uppercase;
      letter-spacing: 0.78px;
      color: $color-font-main;
    }
  }

  .job-summary-info {
    margin: 0;
    padding: 10px 0;
    list-style: none;

    li {
      padding: 4px 0;
      line-height: 1.5;

      b {
        margin-right: 10px;
        color: $color-text-dark;
      }
    }
  }

  .job-address {
    margin: 32px 0;
    position: relative;

    .address {
      &::before {
        content: url('/images/shared/location.svg');
        position: absolute;
        left: 4px
      }
    }

    .name {
      margin: 0;
      color: $color-font-dark;
    }

    .address {
      font-size: 1rem;
      line-height: 24px;
      padding-left: 27px;
      font-style: normal;
      letter-spacing: .7px;
    }
  }

  .logo-container {
    margin: 32px 0 28px;

    .hotel-logo {
      overflow: hidden;
      text-align: center;
      padding: 1rem;
      border-radius: 4px;
      border: 1px solid $border-color;

      p {
        max-width: 80%;
        margin: 0 auto 20px;
        color: $color-font-dark;
      }

      .logo-wrapper {
        overflow: hidden;
        border-radius: 4px;
        display: inline-block;

        img {
          width: 100%;
          height: 210px;
          object-fit: cover;
        }
      }

      .btn > img {
        margin-right: 8px;
      }
    }
  }

  .job-employer-description {
    margin: 16px 0;

    .title {
      margin: 5px 0;
      font-weight: bold;
    }

    .description {
      font-size: .875rem;
      line-height: 1.71;
    }
  }

  .edit-actions {
    flex-wrap: nowrap;

    .custom-btn {
      min-width: 40px;
    }
  }

  .branches .chip.accent {
    margin: 0 5px;
    color: $color-accent;
    background: rgba($color-accent, .2);
  }

  .modal-columns-container {
    padding: 28px;

    .general-info {
      padding-right: 20px;
    }
  }

  .back-btn-container {
    .inline {
      color: $color-font-main;
    }
  }

  & .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 28px 0;

    .draft-btn {
      padding: 11px;
      flex-basis: 100%;
    }

    .archive-btn {
      margin: 0;
    }
  }
}
