@import '~common/styles/variables';

.mobile-application-created-back,
.mobile-application-info-icon {
  display: none;
}

@media screen and (max-width: $media-xl) {
  .mobile-application-modal-created.create-application-modal {
    width: 95vw;
  }
}

@media screen and (max-width: $media-md) {
  .mobile-application-modal-created.create-application-modal {
    width: 100vw;

    .content {
      width: 100vw;
      max-width: 100%;
    }

    .card {
      height: auto;
      max-width: 100%;
      min-width: auto;
    }
  }
}

@media screen and (max-width: $media-sm) {
  .mobile-application-modal-created.create-application-modal {
    padding: 0;

    .card {
      min-height: 1px;
    }

    .application-back-link {
      padding: 0;
      width: 15px;
      height: 15px;
      font-size: 0;
      top: 8px;
      right: 16px;
      position: absolute;

      .mobile-application-created-back {
        display: block;
        font-size: 24px;
      }
    }

    .modal-header {
      flex-wrap: wrap;
      height: auto;
      padding: 20px 20px 15px;
      margin-bottom: 8px;

      .application-caption {
        line-height: 1.17;
        letter-spacing: .2px;
        font-weight: normal;
        width: 100%;
        margin: 10px 0 15px;
      }

      & ~ .card {
        min-height: auto;
      }

      .left-side {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
      }

      .right-action {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        padding: 10px 0 0;

        .btn {
          margin: 0;
          width: auto;
          min-width: 136px;
        }

        .btn:first-of-type {
          margin: 0 8px 0 0;
        }
      }

      .info-icon {
        display: none;
      }

      .mobile-application-info-icon {
        display: block;
      }
    }

    .text-content {
      word-break: break-word;
      margin: 0;
      max-width: 100%;
      padding: 25px 20px 90px;
      line-height: 1.5;
      letter-spacing: .89px;
      color: $color-font-dark;
    }
  }
}
