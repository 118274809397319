@import '~common/styles/variables';

$background-white: #fff;
$dropdown-shadow: rgba(80, 95, 121, .3);

.outside {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.filter-container {
  position: relative;

  .filter-label {
    padding: 12px 16px;
    white-space: nowrap;
    border-radius: 4px;
    border: solid 1px $border-light-color;
    cursor: pointer;
    transition: border-color .15s;
    position: relative;

    .filter-label-title {
      margin-right: 4px;
    }

    .filter-label-value {
      color: $color-font-dark;
    }
  }

  .filter-label-icon {
    &::after {
      top: 15px;
      right: 10px;
      position: absolute;
      content: url('/images/shared/arrow-down.svg');
    }
  }

  .filter-multiple {
    display: flex;
    min-height: 46px;
    align-items: center;
    white-space: pre-wrap;
    padding: 6px 35px 6px 6px;

    .chips-list {
      .default-label {
        padding-left: 16px;
      }
    }
  }

  &:hover {
    .filter-label {
      border-color: $color-accent;
    }
  }

  .filter-options {
    position: absolute;
    top: 110%;
    background: $background-white;
    z-index: 990;
    min-width: 200px;
    width: 100%;
    left: 0;
    padding: 12px 0;
    box-shadow: 0 8px 15px 0 $dropdown-shadow;
    border-radius: 4px;
    border: solid 1px $color-muted;
    max-height: 300px;
    overflow-y: auto;

    .options-list {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        padding: 11px 24px;
        font-weight: bold;
        cursor: pointer;
        color: $color-font-dark;

        &.active,
        &:hover {
          background-color: $color-muted;
        }
      }
    }
  }
}

.dropdown-inactive {
  opacity: .6;
  pointer-events: none;
}

.form-label-error {
  color: $color-danger;
  font-size: .875rem;
  margin-bottom: 10px;
}
