@import '~common/styles/variables';

@media screen and (max-width: $media-lg) {
  .mobile-search-container {
    width: 100vw;
  }
}

@media screen and (max-width: $media-md) {
  .mobile-search-container {
    flex-direction: column;

    .search-modal-content {
      width: 100%;
    }
  }
}

@media screen and (max-width: $media-sm) {
  .mobile-search-container {
    padding: 0;
  }
}

@media screen and (max-width: $media-md) and (max-height: $media-xs) {
  .mobile-search-container {
    overflow: auto;
  }

  .mobile-portlet-list {
    .portlet-body-scroll.scrollbar-container {
      height: auto;
    }
  }
}


