@import '~common/styles/variables';

$color-draft: #f5a623;
$color-archived: #7a8cab;

.job-offer-action-menu {
  left: -150px !important;
}

.job-offer-row {

  td .cell.action-cell {
    display: flex;
    justify-content: flex-end;
  }

  .job-offer-status {
    & > span {
      font-size: 14px;
      padding: 4px 24px;
      border-radius: 14px;
      color: $color-archived;
      text-transform: capitalize;
      background-color: rgba($color-archived, .2);

      &.published {
        color: $color-primary;
        background-color: rgba($color-primary, .2);
      }

      &.draft {
        color: $color-draft;
        background-color: rgba($color-draft, .2);
      }
    }
  }

  .menu-button {
    width: 34px;
    height: 34px;
    border: 1px solid $border-light-color;
    padding: 0;

    & > img {
      top: 1px;
      width: 18px;
      height: 18px;
      position: relative;
    }
  }
}
