@import '~common/styles/variables';

$sidebar-color-caption: #7a8cab;
$menu-bg-active: #e5eefc;

.sidebar-container {
  min-width: 176px;
  margin-right: 20px;

  & > .sidebar-caption {
    text-align: center;
    color: $sidebar-color-caption;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: .89px;
    line-height: 19px;
    width: 100%;
    margin: 22px auto;
    display: block;
    text-decoration: none;

    &:hover {
      opacity: .7;
    }
  }

  & .sidebar-menu-list {
    padding: 0;
    margin: 0;
    list-style: none;
  }
}

.profile-modal-content {
  padding: 28px 40px;
  max-width: 700px;
  min-width: 700px;

  .scrollbar-container {
    overflow: visible !important;
  }
}
