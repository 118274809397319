@import '~common/styles/variables';

@media screen and (max-width: $media-sm) {
  .priority-match-form {
    &.mobile-knowledge-level-form {
      display: block;
      padding: 0 0 100px;

      .row {
        padding: 0 20px;
        flex-wrap: wrap;
        position: relative;
        width: calc(100% + 40px);
        left: -20px;

        &.sticky {
          display: none;
        }

        .title {
          letter-spacing: .33px;
          color: $color-font-dark;
          font-size: 16px;
          font-weight: normal;
          flex-basis: 100%;
          margin: 15px 0 25px;
          padding: 0;
        }

        .level {
          min-width: auto;
          max-width: 225px;
          flex-basis: 100%;
        }

        &.skill-name {
          &:nth-child(2) {
            border-top: 1px solid #e5eefc;
          }
        }
      }

      .form-buttons {
        width: calc(100% + 40px);
        left: -20px;
        position: fixed;
        margin: 0;
        padding: 10px 40px;
        height: 64px;
        box-shadow: none;

        .grey {
          margin-right: 8px;
          min-width: 116px;
        }

        .btn[type='submit'] {
          max-width: 156px;
          padding: 11px 10px;
        }
      }

    }
  }
}

@media screen and (max-width: $media-xs) {
  .priority-match-form {
    &.mobile-knowledge-level-form {
      .form-buttons {
        display: flex;
        height: auto;
        padding: 10px 36px;
        flex-direction: row;
        border-top: 1px solid $color-muted;

        .btn {
          width: 100%;
        }
      }
    }
  }
}
