@import '~common/styles/variables';
@import '~react-rangeslider/lib/index.css';

$line-color: #0052dd;
$picker-color: #3b83ff;
$picker-color-primary: #70b53a;
$handle-shadow: rgba(0, 0, 0, .25);
$line-background: rgb(141, 175, 232);
$line-background-primary: #d7f0ae;
$line-empty-color: #e5eefc;

.knowledge-level-container {
  width: 90%;

  &.disabled {
    pointer-events: none;
    opacity: .5;
  }

  .rangeslider {
    box-shadow: none;
    height: 4px;
    background: $line-empty-color;

    .rangeslider__label-item {
      color: transparent;
      text-transform: capitalize;

      &::after {
        content: '';
        width: 8px;
        height: 8px;
        display: inline-block;
        position: absolute;
        top: 33px;
        left: 0;
        right: 0;
        margin: 0 auto;
        border-radius: 50%;
        background: $line-empty-color;
      }

      &:first-child {
        &::after {
          left: 10px;
        }
      }

      &:last-child {
        &::after {
          right: 10px;
        }
      }
    }

    @for $i from 1 through 4 {
      &[aria-valuenow="#{$i}"] {
        .rangeslider__label-item[data-value="#{$i}"] {
          color: $color-font-dark;
        }
      }
    }

    &[aria-valuenow="2"] {
      .rangeslider__label-item[data-value="1"] {
        &::after {
          background: $line-background;
        }
      }
    }

    &[aria-valuenow="3"] {
      .rangeslider__label-item[data-value="1"],
      .rangeslider__label-item[data-value="2"] {
        &::after {
          background: $line-background;
        }
      }
    }

    &[aria-valuenow="4"] {
      .rangeslider__label-item {
        &::after {
          background: $line-background;
        }
      }
    }

    .rangeslider__labels {
      top: -65px;
    }

    .rangeslider__fill {
      background: $line-background;
      box-shadow: none;
    }

    .rangeslider__handle {
      width: 16px;
      height: 16px;
      outline: none;
      border-radius: 30px;
      border: solid 5px $picker-color;
      box-shadow: 0 1px 2px 0 $handle-shadow;
      z-index: 10;

      &::after {
        display: none;
      }
    }
  }

  &.hidden-name {
    .rangeslider {
      height: 3px;

      .rangeslider__label-item {
        visibility: hidden;

        &::after {
          visibility: visible;
          width: 7px;
          height: 7px;
          top: 36px;
        }
      }
    }
  }

  &.primary {
    .rangeslider {
      &[aria-valuenow="2"] {
        .rangeslider__label-item[data-value="1"] {
          &::after {
            background: $line-background-primary;
          }
        }
      }

      &[aria-valuenow="3"] {
        .rangeslider__label-item[data-value="1"],
        .rangeslider__label-item[data-value="2"] {
          &::after {
            background: $line-background-primary;
          }
        }
      }

      &[aria-valuenow="4"] {
        .rangeslider__label-item {
          &::after {
            background: $line-background-primary;
          }
        }
      }

      .rangeslider__fill {
        background: $line-background-primary;
      }

      .rangeslider__handle {
        border: solid 5px $picker-color-primary;
      }
    }
  }
}
