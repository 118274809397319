.modal-routing {
  position: fixed;
  top: 0;
  right: 0;
  transition: transform .6s;
  transform: translateX(0);
  visibility: visible;
  z-index: 999;
  opacity: 1;
  animation: slide-up 0.4s ease;

  &.hide {
    transform: translateX(100vw);
  }
}

.modal-wrapper-routing {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  opacity: 1;
  background: rgba(0, 0, 0, .5);
  cursor: pointer;
  transition: opacity .4s;

  &.hide {
    opacity: 0;
  }
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateX(100vw);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
