@import '~common/styles/variables';

$light-gray: #3b475a;

.balance-board-candidate-poster {
  display: flex;
  padding: 20px;
  align-items: center;
  flex-direction: column;

  .title {
    color: $color-text-dark;
    font-size: 24px;
    letter-spacing: 0.2px;
  }

  .description {
    padding-top: 10px;
    font-weight: 300;
    color: $color-font-main;
    font-size: 18px;
    line-height: 1.4;
    letter-spacing: 0.37px;
    max-width: 500px;
    text-align: center;
  }

  .poster {
    margin: 10px 0;
  }

  .actions {
    margin-top: 6px;

    .btn {
      width: 200px;
    }
  }
}
