@import '~common/styles/variables';

$icon-highlight-color: #fff;

.job-offer-row {
  .list-badge {
    position: absolute;
    transform: translate(-26px, 20px);

    .new-job-offer {
      width: 10px !important;
      height: 10px;
      display: inline-flex;
      font-size: 13px;
      margin-left: 5px;
      overflow: hidden;
      border-radius: 50%;
      text-align: center;
      align-items: center;
      letter-spacing: .3px;
      justify-content: center;
      background: $color-badge;
      box-shadow: 0 0 0 3px rgba($color-badge, .12);
    }
  }

  .item-title {
    color: $color-font-dark;
    margin: 0;
    font-size: 1rem;
    font-weight: bolder;
  }

  .item-description {
    font-size: .875rem;
  }
}
