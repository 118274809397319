@import '~common/styles/variables';

.invite-recruiter-container {
  & > .upload-avatar-container {
    padding-top: 0;

    .avatar-error {
      top: 80px;
    }

    & > .upload-btn {
      padding: 11px 18px;
      margin-right: 12px;
    }
  }

  & .recruiter-invite-field {
    flex-basis: 100%;

    & > .form-label {
      width: calc(50% - 10px);
    }
  }
}

.invite-recruiter-form {
  & .form-btn-submit {
    padding-top: 40px;
    width: 100%;
    margin-bottom: 1rem;
  }

  & .agreement {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.43;
    letter-spacing: .4px;

    & > a {
      color: $color-accent;
    }
  }
}
