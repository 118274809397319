@import '~common/styles/variables';

@media screen and (max-width: $media-md) {
  .btn {
    &.primary {
      &:hover {
        background: $color-primary !important;
      }
    }

    &.accent {
      &:hover {
        background: $color-accent !important;
      }
    }

    &.default {
      &:hover {
        background: $color-font-main !important;
      }
    }

    &.grey {
      &:hover {
        background: $bg-color-default !important;
      }
    }

    &.outlined {
      &:hover {
        background: $color-muted !important;
      }
    }

    &.mobile-link-btn {
      span {
        margin-left: 4px;
      }
    }
  }
}

@media screen and (max-width: $media-xs) {
  .btn {
    &:last-of-type {
      margin-right: 0;
    }

    &.medium {
      width: 100%;
      margin-right: 0;
    }

    &.sign-in-button {
      width: 100%;
    }
    
    &.mobile-application-new-letter {
      width: 90%;
      padding: 11px 10px;
      white-space: nowrap;
    }

    &.mobile-delete-confirm {
      width: 100%;
      margin-right: 0;
    }
  }
}
