.success-reset-caption {
  letter-spacing: .1px;
  line-height: 1.5;
  margin-top: -10px;
  margin-bottom: 30px;
}

.sign-in-button > a {
  color: #fff;

  &:hover {
    text-decoration: none;
  }
}
