@import '~common/styles/variables';

.video-container {
  margin-top: 0px;

  .main-header {
    margin-top: 100px;
   }
	
  .video-content {
    display: contents;
    flex-direction: column;
    padding: 0;

    .video-title {
      font: normal 42px/1.24 'Lato', sans-serif;
      color: $color-text-dark;
      width: 62%;
      text-align: center;
      margin: 50px 0;
    }

    .video-spacer {
      font: normal 20px/1.24 'Lato', sans-serif;
      color: white;
      width: 62%;
      text-align: center;
      margin: 50px 0;
    }

    .video-player {
      box-shadow: 0 8px 16px 0 rgba(59, 71, 90, .21), 0 4px 4px 0 rgba(0, 0, 0, .12);;
      img {
        width: 100%;
        display: flex;
      }
    }
  }

  .background {
    top: 0;
    left: 0;
    width: 100%;
    height: 75%;
    z-index: -1;
    position: absolute;
    background-color: #ffffff;
  }
}

.video-container.on-boarding-container {
  margin-bottom: 0;

  .video-content {
    padding: 0 !important;
  }

  .video-player {
    box-shadow: none;
    padding: 0 !important;
  }
}

.video{
  width:100%;
  height: auto;
  margin: auto;
  position: relative;
  top: 0;
  left: 0;
}

.controlButton{
  display:inline-flex;
  padding:0.3em 1.2em;
  max-width:20em;
  max-height: 2em;
  white-space:nowrap;
  overflow:hidden;
  margin:0 0.3em 0.3em 0;
  border-radius:2em;
  box-sizing: border-box;
  border: none;
  text-decoration:none;
  font-family:'Roboto',sans-serif;
  font-weight:300;
  background-color:#FFFFFF;
  text-align:center;
  transition: all 0.2s;
}
.button3:hover{
  background-color:#4095c6;
}
@media all and (max-width:30em){
.button3{
  display:block;
  margin:0.2em auto;
  }
}

.player-wrapper {
  position: relative;/* Player ratio: 100 / (1280 / 720) */
}

@media ( min-width: 769px ) and ( max-width: 1023px ) {
  .video{
     max-width: 50%;
     max-height: 250px;
     height: calc(var(--vh, 1vh) * 100);
  }
}

.video-controls-container {
  display: flex;
  white-space: nowrap;
  overflow: none;
  align-items: center;
}

.video-controls-container > div {
  height: 100%;
  width: 100%;
  display: inline-block;
  //margin-left: -5px;
}

.controls {
  margin: 0;
  display: flex;
}