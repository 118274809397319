@import '~common/styles/variables';

.modal.existing-job-offers-modal {

  .modal-content {
    min-width: 616px;
    left: 0;
  }

  .job-offers-list-content {
    display: flex;
    padding: 20px;
    min-height: 450px;
    max-height: 450px;
    flex-direction: column;
    justify-content: space-between;

    .empty-list {
      text-align: center;
      padding: 2rem;
    }
  }

  .existing-job-offers-list {
    margin: 0;
    list-style: none;
    padding: 0 1rem 0 0;

    .existing-job-offers-item {
      display: flex;
      padding: 14px;
      align-items: center;
      border-radius: 5px;
      margin-bottom: 8px;
      cursor: pointer;
      justify-content: space-between;
      border: 1px solid $soft-dark-text;
      transition: all .3s;

      &:hover,
      &.active {
        border-color: $color-accent;
      }

      .job-title {
        display: flex;
        align-items: center;

        & > label {
          margin: 0;
        }

        .content {
          h4 {
            margin: 0;
            color: $color-text-dark;
            letter-spacing: .4px;
            line-height: 20px;
          }

          & span {
            color: $soft-dark-text;
            font-size: 12px;
            line-height: 15px;
          }
        }
      }
    }
  }

  .actions {
    padding: 1rem 0;
  }
}
