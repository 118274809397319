@import '~common/styles/variables';

$header-height: 52px;
$container-margin-bottom: 52px;
$container-margin-bottom-mobile: 119px;

.page {
  &.full-height {
    height: calc(var(--vh, 1vh) * 100 - #{$header-height} - #{$container-margin-bottom});
  }
}

@media screen and (max-width: $media-md) {
  .page {
    height: calc(var(--vh, 1vh) * 100 - 52px);

    &.full-height {
      .not-found-poster {
        &.mobile-not-found-poster {
          &::before,
          &::after {
            top: auto;
            bottom: 35px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $media-sm) {
  .page {
    padding: 0;
    min-height: auto;
  }

  .mobile-portal-not-found {
    z-index: 0;
    margin: 0 -16px;
    position: relative;
  }

  .mobile-guest-page {
    margin: 0 -16px;
  }
}

@media screen and (max-width: $media-xs) {
  .page {
    &.full-height {
      height: calc(var(--vh, 1vh) * 100 - #{$header-height} - #{$container-margin-bottom-mobile});
    }
  }
}

@media screen and (max-width: $media-xs) and (max-height: 600px) {
  .page {
    &.full-height {
      .not-found-poster {
        &.mobile-not-found-poster {
          &::before,
          &::after {
            bottom: 10px;
          }
        }
      }
    }
  }
}
