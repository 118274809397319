@import '~common/styles/variables';

@media screen and (max-width: $media-md) {
  .mobile-not-found-poster {
    height: 100%;
    padding: 50px 0;
    justify-content: center;

    &::before,
    &::after {
      top: auto;
      bottom: 35px;
      width: 100px;
      height: 121px;
    }

    &::before {
      background: url('/images/shared/not-found-woman.svg') no-repeat;
    }

    &::after {
      background: url('/images/shared/not-found-man.svg') no-repeat;
    }

    .title {
      font-size: 28px;
      line-height: 1.14;
      letter-spacing: .58px;
    }

    .description {
      font-size: 16px;
      margin-top: 13px;
      letter-spacing: .33px;
    }

    .actions {
      margin-top: 36px;

      .btn {
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: $media-xs) {
  .mobile-not-found-poster {
    padding: 0 0 100px 0;

    &::before {
      left: 25px;
    }

    &::after {
      right: 25px;
    }

    .actions {
      .btn {
        width: calc(100vw - 56px);
      }
    }
  }
}

@media screen and (max-width: $media-xs) and (max-height: 600px) {
  .mobile-not-found-poster {
    &::before,
    &::after {
      bottom: 10px;
    }
  }
}
