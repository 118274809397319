@import '~common/styles/variables';

@media screen and (max-width: $media-sm) {
  .job-offer-row {
    .list-badge {
      transform: translate(-15px, 20px);

      .new-job-offer {
        margin: 0;
        box-shadow: 0 0 0 2px rgba(253, 58, 87, .12);
      }
    }
  }
}

@media screen and (max-width: $media-xs) {
  .job-offer-row {
    position: relative;
    padding: 10px 20px;

    .list-badge {
      transform: translate(-15px, 4px);

      .new-job-offer {
        margin: 0;
      }
    }

    .item-title {
      line-height: 1.5;
      letter-spacing: .33px;
    }

    td:first-child {
      .item-title {
        font-size: 18px;
        line-height: 1.56;
        letter-spacing: .37px;
        color: $color-primary;
        padding-right: 25px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 275px;
      }
    }
  }
}
