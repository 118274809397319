@import '~common/styles/variables';

$color-table-head: #bdc9df;
$color-border-bottom: #e5eefc;

.profile-team-member {
  padding: 10px;
}

.members-table {
  width: 100%;
  position: relative;

  & tr {
    border-bottom: 1px solid $color-border-bottom;
    height: 55px;
    font-family: Lato, sans-serif;

    &::after {
      content: '';
      display: block;
      height: 1px;
      position: absolute;
      width: 100%;
      left: 0;
      background: #e5eefc;
    }

    & > td {
      max-width: 250px;

      &:nth-child(3) {
        text-align: center;
      }
    }
  }
}

.table-header {
  & > tr {
    &::after {
      content: none;
    }

    & > th {
      font-size: 12px;
      color: $color-table-head;
      font-weight: normal;
      padding: 11px 0;
      text-align: left;

      &:nth-of-type(3) {
        text-align: center;
      }
    }
  }
}

.invite-btn-container {
  border-bottom: 1px solid #e5eefc;
  border-top: 1px solid #e5eefc;
  padding: 16px 0;
}

.inline-btn {
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
  font-family: Lato, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  letter-spacing: .1px;
  color: $color-accent;
  outline: 0;
  margin-left: 15px;
  cursor: pointer;

  & > img {
    margin-right: 8px;
  }

  &[disabled] {
    pointer-events: none;
    color: $color-table-head;

    & > img {
      display: none;
    }
  }
}

.member-info {
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 14px;

  & > .avatar {
    width: 34px;
    height: 34px;
    overflow: hidden;
    margin-right: 13px;
    border: none;

    & > img {
      width: 100%;
      object-fit: contain;
    }
  }

  & .member-name {
    font-size: 14px;
    margin: 0;
    color: #505f79;
  }

  & .member-type {
    font-size: 10px;
    margin: 0;
    color: #7a8cab;
  }

  & .member-email {
    font-size: 14px;
    color: #505f79;
  }
}

.member-status {
  font-size: 14px;
  padding: 4px 14px;
  border-radius: 2px;
  background-color: #f4f5f7;
}
