@import '~common/styles/variables';

.balance-board-list-container {
  height: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
}

.balance-board-title {
  font-size: 24px;
  color: #3b475a;
  letter-spacing: 0.2px;
  font-weight: normal;
  text-align: center;
}

.create-balance-board-modal {
  display: flex;
  padding: 1rem 40px;
  height: calc(var(--vh, 1vh) * 100);
  background: $bg-color-default;
  width: 60vw;
  box-shadow: 0 5px 21px 0 rgba(66, 101, 159, .2);
  overflow: hidden;
  cursor: auto;

  .balance-board-back-link {
    display: inline-block;
    padding: 1rem 0;
  }

  .tabs-container {
    & .primary-tabs {
      .tabs-list {
        li:hover {
          color: #70b53a;
        }

        li.active:not(:only-child) {
          color: #70b53a;
        }
      }
    }
  }

  .card {
    width: 100%;
    min-width: 700px;
    max-width: 932px;
  }

  .content {
    height: 95%;
    padding: 0 20px;
    margin: 10px 10px;
    overflow: hidden;

    .ps__thumb-y {
      width: 4px;
      background-color: $color-font-main;
    }

    & > .title {
      font: bold 16px/1.25 'Lato', sans-serif;
      margin: 16px 0 24px;
    }
  }

  .tabs-container {
    .tabs-list {
      border-top: 1px solid;
      border-color: #e5eefc;
      padding: 0;
      height: 50px;
      display: flex;
      align-items: center;

      & > li {
        transition: color .3s;

        &:hover {
          cursor: pointer;
          color: $color-accent;
        }

        &.active {
          &:not(:only-child) {
            color: $color-accent;

            &::after {
              height: 0;
            }
          }
        }
      }
    }
  }
}
