@import '~common/styles/variables';

.competencies-interact-table {
  width: 100%;
  border-collapse: collapse;
  font-size: .875rem;

  tr:not(:last-of-type) {
    border-bottom: 1px solid $border-light-color;
  }

  th {
    padding: 8px 0;
    font-weight: 400;
    color: $color-font-main;
    text-align: left;
    font-size: .75rem;
    letter-spacing: .7px;
  }

  td {
    border-top: 1px solid $color-muted;

    &.skill-level {
      padding-right: 3rem;
      color: $light-color-accent;
      text-transform: capitalize;
      font-size: .625rem;

      &.primary {
        padding: 0;
        width: 80px;
        color: $color-primary;
      }

      div {
        margin: 0 6px;
      }
    }

    &.skill-name {
      width: 50%;
      padding: 6px 0;
    }
  }

  .empty-range {
    .knowledge-level-container {
      .rangeslider {
        .rangeslider__fill {
          background: transparent;
        }

        .rangeslider__handle {
          display: none;
        }

        .rangeslider__labels > li {
          &::after {
            background: $color-muted;
          }
        }
      }
    }
  }

  .progress-container {
    margin: 6px;
    display: flex;
    align-items: center;
    width: 100px;

    .join {
      flex: 1;
      background: $light-color-accent;
      height: 3px;
    }

    .progress-point {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: $light-color-accent;

      &.active ~ div {
        background: $color-muted;
      }
    }
  }

  .skill-required {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    &::before,
    &::after {
      width: 6px;
      height: 6px;
      border: 2px solid $soft-dark-text;
      content: '';
      transform: rotate(45deg);
      border-right: 0;
      border-bottom: 0;
      margin-bottom: -2px;
    }
  }

  &.recruiter {
    .skill-level {
      color: $light-color-accent;
    }

    .progress-container {
      .join {
        background: $light-color-accent;
      }

      .progress-point {
        background: $light-color-accent;

        &.active ~ div {
          background: $color-muted;
        }
      }
    }
  }
}

.table-actions {
  padding: 1.5rem 0;

  & > p {
    font-size: 14px;
  }
}
