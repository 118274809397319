@import '~common/styles/variables';

.notifications-modal {
  height: 100%;
  width: 45vw;

  & > div {
    height: 100%;
  }
}

.notifications {
  padding: 1rem 20px;
  min-width: 556px;
  height: 100%;
  background: $bg-color-default;

  & > .page {
    max-width: 556px;
    height: 100%;
    overflow: hidden;
  }

  & .empty-list {
    text-align: center;
    padding: 3rem;
  }
}

.notifications-content {
  padding: 28px;
  height: 95%;
  overflow: hidden;

  .ps__thumb-y {
    width: 4px;
    background-color: $color-font-main;
  }

  & > .caption {
    margin: 0 0 1rem;
  }

  & > .label {
    display: block;
    font-size: 14px;
    color: #bdc9df;
    margin-bottom: 11px;
  }

  & > .modal-btn {
    padding: 0;
    font-size: 1rem;
    line-height: 1.25;
    letter-spacing: .9px;
    margin-bottom: 24px;
  }
}
