@import '~common/styles/variables';

.competencies-section-container {
  padding: 5px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  .competencies-item {
    padding: 6px 1rem;
    border-radius: 4px;
    font-weight: bold;
    margin-right: 8px;
    margin-bottom: 10px;
    color: $color-font-dark;
    background: $bg-color-default;
  }

  .competencies-item-points {
    margin-left: 8px;
    color: $color-primary;
  }
}

.empty-section {
  padding: 5px;

  .empty-section-title {
    margin: 0;
    font-size: 1rem;
  }

  .empty-section-description {
    color: #7A8CAB;
    font-size: 12px;
    letter-spacing: 0.67px;
    margin-bottom: 24px;
  }
}
