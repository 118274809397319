@import '~common/styles/variables';
@import '~rc-collapse/assets/index.css';

.candidate-balance-board-form {
  .rc-collapse {
    background: transparent;
    border: 1px solid transparent;

    .rc-collapse-item,
    .rc-collapse-item-active {
      &:focus,
      &:active {
        outline: none;
      }
    }

    .rc-collapse-header {
      cursor: pointer;
      margin: 50px 0 25px;
      color: $color-font-dark;
      text-transform: uppercase;
      font: bold 16px/1.5 'Lato', sans-serif;

      &:focus,
      &:active {
        outline: none;
      }
    }
  }
}
