@import '~common/styles/variables';

.modal-balance-board-back {
  display: none;
}

@media screen and (max-width: $media-xl) {
  .mobile-create-balance-board-modal {
    width: 70vw;
  }
}

@media screen and (max-width: $media-lg) {
  .mobile-create-balance-board-modal {
    width: 100vw;

    .card {
      max-width: 100%;
      min-width: auto;
      width: 100%;
    }

    .content {
      max-width: 100%;
      min-width: auto;
      width: 100%;
      margin: 0;
    }
  }
}

@media screen and (max-width: $media-md) {
  .mobile-create-balance-board-modal {
    padding: 0;
  }
}

@media screen and (max-width: $media-sm) {
  .mobile-create-balance-board-modal {
    .modal-balance-board-back {
      display: block;
      font-size: 24px;
      line-height: 14px;
      color: $color-font-main;
    }

    .balance-board-back-link {
      position: absolute;
      top: 20px;
      right: 20px;
      margin: 0;
      height: 14px;
      width: 14px;
      padding: 0;
      font-size: 0;
      line-height: 0;
    }

    .tabs-container {
      & .primary-tabs {
        .tabs-list {
          height: auto;
          border: none;
          padding: 10px 0 0;

          li{
            display: none;
            height: auto;
            line-height: 1.5;
            letter-spacing: .13px;
            padding-top: 5px;
            margin: 0;
          }

          li.active:not(:only-child) {
            display: block;
          }
        }
      }
    }

    .content {
      height: 100%;

      .title {
        line-height: 1.17;
        letter-spacing: .2px;
        font-size: 24px;
        font-weight: normal;
        color: $color-text-dark;
        margin: 15px 0;
      }

      .sub-title {
        line-height: 1.5;
        letter-spacing: .13px;
        color: $color-font-main;
        margin: 0 0 35px;
      }
    }
  }
}
