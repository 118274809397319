@import '~common/styles/variables';

$text-on-dark: #fff;
$button-shadow-color: rgba(0, 0, 0, .1);
$account-setting-page-shadow: rgba(66, 101, 159, .2);
$background-white: #fff;
$muted-input-color: #bdc9df;
$avatar-border-color: #f4f5f7;
$tabs-border-color: $color-muted;

.setting-container {
  max-width: 830px;
  border-radius: 4px;
  background-color: $text-on-dark;
  padding: 24px 40px;
  box-shadow: 0 2px 3px 0 $account-setting-page-shadow;
  margin: 1rem auto;
}

.setting-caption {
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  margin: 0;
}

.setting-description {
  padding: 12px 0;
  margin: 0 0 32px;
  font-weight: normal;
}

.linear-tabs {
  &::before,
  &::after {
    content: '';
    display: block;
    height: 1px;
    position: relative;
    background: $tabs-border-color;
  }

  & .tabs-list {
    border: 0;
    padding: 0;
    height: 50px;
    display: flex;
    align-items: center;

    & > li {
      transition: color .3s;

      &:hover {
        cursor: pointer;
        color: $color-accent;
      }

      &.active {
        &:not(:only-child) {
          color: $color-accent;

          &::after {
            height: 0;
          }
        }
      }
    }
  }
}
