@import '~common/styles/variables';

.create-post-modal {
  display: flex;
  padding: 18px 2rem;
  height: calc(var(--vh, 1vh) * 100);
  background: $bg-color-default;
  width: 60vw;
  box-shadow: 0 5px 21px 0 rgba(66, 101, 159, .2);
  overflow: hidden;
  cursor: auto;

  .modal-header {
    display: flex;
    padding: 20px;
    height: 23%;
    margin-bottom: 1%;
    align-items: center;
    justify-content: space-between;

    & ~ .card {
      height: auto;
      min-height: 77%;
    }

    .left-side > a {
      padding: 0;
    }

    .right-action {
      .btn {
        display: block;
      }

      .btn:first-of-type {
        margin-bottom: 10px;
      }
    }
  }

  .text-content {
    padding: 20px;
    max-width: 90%;

    .gallery img {
      width: 100%;
      max-width: 100%;
      object-fit: cover;
      border-radius: 5px;
      opacity: .4;

      &.primary {
        opacity: 1;
      }
    }

    .gallery {
      display: flex;
      max-height: 800px;
      justify-content: space-between;

      .main-picture {
        width: 100%;

        & > img {
          height: 100%;
        }
      }

      .aside-photos {
        width: 40%;
        display: flex;
        margin-left: 1rem;
        align-items: center;
        flex-direction: column;

        & > img:not(.primary):hover {
          cursor: pointer;
        }

        & > img:not(:last-child) {
          margin-bottom: 5px;
        }
      }
    }

    & > p {
      font-size: 18px;
      white-space: pre-line;
      color: $color-font-dark;
      letter-spacing: 0.67px;
      margin-bottom: 24px;
    }
  }
}
