@import '~common/styles/variables';

$item-color: #bdc9df;
$point-background: #EFF9DF;

.empty-placeholder {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  opacity: .7;
  user-select: none;
}

.candidate-row {
  .item-title {
    margin: 0;
    font-size: 1rem;
  }

  .item-description {
    font-size: .875rem;
    color: $item-color;
  }

  .match-points,
  .candidate-avatar {
    width: 10%;
  }

  .full-name,
  .branch {
    width: 39%;
  }

  .points {
    width: 48px;
    height: 28px;
    color: $color-primary;
    padding: 5px 10px;
    border-radius: 14px;
    background-color: $point-background;
    font: normal 14px/1.43 'Lato', sans-serif;
  }

  .candidate-avatar {
    position: relative;
  }

  .new-candidate {
    width: 10px !important;
    height: 10px;
    display: inline-flex;
    font-size: 13px;
    margin-left: 5px;
    overflow: hidden;
    border-radius: 50%;
    text-align: center;
    align-items: center;
    letter-spacing: .3px;
    justify-content: center;
    background: $color-badge;
    position: absolute;
    left: 15px;
    box-shadow: 0 0 0 3px rgba($color-badge, .12);
  }
}

.candidate {
  display: inline-block;
  width: 28px !important;

  & > img,
  .more {
    width: 44px;
    height: 44px;
    border: 1px solid $color-muted;
    border-radius: 50%;
  }

  .more {
    color: $color-accent;
    float: left;
    display: inline-flex;
    background: $color-muted;
    align-items: center;
    justify-content: center;
    font: normal 14px/1.43 'Lato', sans-serif;
  }
}
