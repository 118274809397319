.list-content {
  max-height: 365px;
}

.add-languages-modal {
  .modal-content {
    min-width: 620px;
  }

  .scrollbar-container {
    height: calc(var(--vh, 1vh) * 37);
  }
}
