@import '~common/styles/variables';

$text-on-dark: #fff;
$button-shadow-color: rgba(0, 0, 0, .1);

.btn {
  appearance: 0;
  border: 0;
  outline: 0;
  font-size: 1rem;
  color: $text-on-dark;
  border-radius: 4px;
  padding: 11px 30px;
  box-shadow: 0 2px 4px 0 $button-shadow-color;
  transition: background-color .15s;
  font-weight: bold;
  font-family: 'Lato', sans-serif;
  margin-right: 12px;

  &:not(:disabled) {
    cursor: pointer;
  }

  &[disabled] {
    pointer-events: none;
    opacity: .4;
  }

  &.full-width {
    width: 100%;
    flex-basis: 100%;
  }

  &.primary {
    background: $color-primary;

    &:hover {
      background: darken($color-primary, 16%);
    }

    &:active {
      background: lighten($color-primary, 16%);
    }

    &.outlined {
      background: transparent;
      box-shadow: none;
      color: $color-primary;
      border: 1px solid $color-primary;
    }
  }

  &.accent {
    background: $color-accent;

    &:hover {
      background: darken($color-accent, 16%);
    }

    &:active {
      background: lighten($color-accent, 16%);
    }

    &.outlined {
      color: $color-accent;
      border: 1px solid $color-accent;
    }
  }

  &.default {
    border: 1px solid transparent;
    background: $color-font-main;

    &:hover {
      background: darken($color-font-main, 16%);
    }

    &:active {
      background: lighten($color-font-main, 16%);
    }

    &.outlined {
      color: $color-font-main;
      border: 1px solid $color-font-main;
    }
  }

  &.grey {
    background: $bg-color-default;
    color: $color-font-main;
    box-shadow: none;

    &:hover {
      background: darken($bg-color-default, 6%);
    }

    &:active {
      background: darken($bg-color-default, 10%);
    }
  }

  &.outlined {
    background: $text-on-dark;
    box-shadow: none;

    &:hover {
      background: $color-muted;
    }

    &:active {
      background: $text-on-dark;
    }
  }

  .full-width {
    width: 100%;
    flex-basis: 100%;
  }

  &.icon {
    padding: 0;
    box-shadow: none;
    cursor: pointer;
    margin: 0 8px;
    background: transparent;

    & > img {
      border-radius: 50%;
      padding: 7px;
      transition: background-color .2s;

      &:hover {
        background: $close-btn-hover-bg;
      }
    }
  }

  &.inline {
    color: inherit;
    background: transparent;
    box-shadow: none;
    padding: 0 8px;
    margin: 0;
    transition: opacity .2s;

    &:hover {
      background: transparent;
      opacity: .7;
    }
  }

  &.large {
    min-width: 224px;
  }

  &.medium {
    min-width: 140px;
  }

  &.big {
    padding: 20px;
  }
}
