.guest-job-offer-container {
  padding: 10px 40px 20px;
  max-width: 820px;
  margin: 1rem auto;

  .linear-tabs.sub-tabs-layout {
    position: absolute;
    top: -52px;
    background: #fff;
    width: 100%;
  }

  & .upload-avatar-container {
    padding-top: 20px;
  }
}
