@import '~common/styles/variables';

.candidate-balance-board-info {
  .job-title {
    margin: 0;
    font-size: 1.5rem;
    font-weight: bold;
    color: $color-text-dark;
  }

  .additional-info {
    padding: 14px 0;
    display: flex;
    align-items: center;
  }

  .job-summary-info {
    margin: 0;
    padding: 10px 0;
    list-style: none;

    li {
      padding: 4px 0;
      line-height: 1.5;

      b {
        color: $color-text-dark;
        margin-right: 10px;
      }
    }
  }
}
