@import '~common/styles/variables';

.priority-match-form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  .matching-rate {
    flex-basis: 55%;
    padding: 0 0 20px;

    .btn {
      margin-top: 15px;
    }
  }

  .row {
    display: flex;
    width: 100%;
    font: normal 16px/1.25 'Lato', sans-serif;
    border-bottom: 1px solid $color-muted;
    padding: 30px 5px;
    align-items: center;

    &.header {
      padding: 15px 5px;

      .title {
        font-weight: normal;
      }
    }
  }

  .column {
    align-items: center;
    flex-basis: 30%;
    display: flex;
    padding: 0 10px;

    &.title {
      font-weight: bold;
    }

    &.priority {
      justify-content: space-between;
    }

    &.choice {
      flex-basis: 10%;
    }

    & .required-skills-success {
      color: $color-primary;
    }
  }

  .skill-badge {
    width: 87px;
    height: 28px;
    border-radius: 4px;
    background-color: $color-muted;
    display: inline-block;
    font: normal 16px/1.25 'Lato', sans-serif;
    color: $color-font-dark;
    padding: 5px 0;
    text-align: center;

    & > span {
      color: $color-accent;
    }

    &.error-text {
      margin: 0;

      & > span {
        color: $color-danger;
      }
    }

    &.success-text {
      & > span {
        color: $color-primary;
      }
    }
  }

  .select-row {
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background-color: $bg-color-default;
    position: relative;
    transition: .15s background-color;
    cursor: pointer;

    &::before,
    &::after {
      width: 6px;
      height: 6px;
      border-right: 2px solid $color-font-main;
      border-top: 2px solid $color-font-main;
      transform: rotate(-45deg);
      content: '';
      display: inline-block;
      position: absolute;
      left: 8px;
      top: 7px;
    }

    &::after {
      top: 13px;
    }

    &:hover,
    &.selected {
      background-color: $color-font-dark;

      &::before,
      &::after {
        border-color: #fff;
      }
    }

    &.spaced {
      margin: 0 1rem;
      position: relative;
      top: 6px;
    }
  }

  .draggable-area {
    width: 100%;
    margin: 4px 0;
    display: flex;
    padding: 5px;
    min-height: 200px;
    border-radius: 6px;
    flex-direction: column;
    border: 1px dashed #bdc9df;

    & > p {
      margin: 0;
      padding: 24px 0;
      font-size: 14px;
    }

    &.initial {
      min-height: auto;
    }
  }
}

.error-text {
  margin: 10px;
  font-size: 14px;
  color: $color-danger;
}

.sticky {
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: #fff;
  position: sticky;
}
