@import '~common/styles/variables';

.modal-caption {
  display: flex;
  padding: 0 8px;
  align-items: center;
  background-color: $color-muted;
  justify-content: space-between;

  & > p {
    margin: 0;
    padding: 14px;
    color: $color-font-dark;
    text-transform: uppercase;
  }

  &.transparent {
    background-color: transparent;
  }
}
