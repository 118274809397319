$progress-bg: rgba(0, 0, 0, .1);
$progress-bar-bg: #888;

.progress {
  position: relative;
  display: block;
  max-width: 100%;
  border: 0;
  margin: 5px 0 0;
  box-shadow: none;
  background: $progress-bg;
  padding: 0;
  border-radius: .28571429rem;

  & > .bar {
    display: block;
    line-height: 1;
    position: relative;
    width: 10%;
    min-width: 2em;
    background: $progress-bar-bg;
    border-radius: .28571429rem;
    transition: width .1s ease, background-color .1s ease;
  }

  &.tiny,
  &.tiny > .bar {
    height: 4px;
  }
}
