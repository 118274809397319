.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  font-size: .875rem;
  padding: 0 12px;
}

.agreements {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: inline-block;
    margin-left: 24px;

    a {
      display: block;
    }
  }
}
